import { deleteDoc, doc } from "@firebase/firestore";
import { convertISOToFirestoreFormat, isExpired } from "../../../utils/helpers";
import { multipleToEndorsements } from "./multipleToEndorsements";
import { db } from "../../../firebase";
import { selectIfDeleteOrExistingSubcollection } from "../../utils/validateIfBlacklisted";
import { confirmDeletePromptGlobal } from "../../../layout/ConfirmDeleteProvider";

export const clearList = async (
  formData,
  controlToast,
  policyID,
  list,
  tableDataInDeletionMode,
  insuredInfo,
  userInfo,
  getEndorsements,
  endorsements,
  endorsementKey,
  subcollection,
  effectiveDate,
  deletionReason,
  proofOfPowerDeletion,
  button,
  documentTypeToUse,
  renewalProcess,
  blacklistExpirationDate
) => {

  try {
    const effectiveElementsList = list.filter(item => {
        return !isExpired(item?.expirationDate)
    })
    const elemsToBeDeleted = effectiveElementsList.filter((elem, elemIndex) => {
        if (button === 'massive') return elem
        const checkboxValue = tableDataInDeletionMode?.[elemIndex]
        ?.['tableInfo']?.['actions']?.['actions']?.[0]?.['checkbox']
        if (
            typeof checkboxValue !== 'undefined'
        ) {
            return checkboxValue
        }
        return elem
    })
    
    let payloadResults = []
    let verify
    if (documentTypeToUse === "Application" && !renewalProcess) {
      verify = await confirmDeletePromptGlobal(`${subcollection} are going to be deleted, confirm the action`);
    }
    if (
      verify === "DELETE" ||
      renewalProcess ||
      documentTypeToUse === "Policy"
    ) {
      const { agencyID, insuredID } = formData
      const deletionPromises = elemsToBeDeleted.map((elem) => {
        const payloadResult = selectIfDeleteOrExistingSubcollection(
          elem,
          agencyID,
          insuredID,
          subcollection,
          policyID,
          renewalProcess,
          documentTypeToUse,
          formData,
          endorsementKey === "Exclude Drivers" ? "exclusion" : "deletion"
        )
        return payloadResult
      })
      payloadResults = await Promise.all(deletionPromises);
    }

    if (documentTypeToUse === "Policy" || renewalProcess) {
        const parsedEffectiveDate =
            convertISOToFirestoreFormat(effectiveDate)
        const endorsementAlreadyExists = await multipleToEndorsements(
          policyID,
          parsedEffectiveDate,
          endorsementKey,
          payloadResults,
          insuredInfo,
          formData,
          userInfo,
          getEndorsements,
          endorsements,
          deletionReason,
          proofOfPowerDeletion,
          blacklistExpirationDate
        );
        if (endorsementAlreadyExists) {
          controlToast(true, endorsementAlreadyExists, "success");
        } else {
          controlToast(
            true,
            "The endorsement was succesfully created",
            "success"
          );
        }
    }

    if (verify === 'DELETE' && documentTypeToUse === "Application" && !renewalProcess) {
      controlToast(true, "The deletion was completed", "success");
    }

    return verify
  } catch (error) {
    controlToast(
        true,
        "There was an error with the service",
        "error"
      );
    throw error;
  }
};
