import React, { useState } from "react";
import axios from "axios";
import { getDocs, collection } from "firebase/firestore";
import "react-datepicker/dist/react-datepicker.css";
import { auth, db } from "../../../../firebase";
import findHighestActualCashValue from "../../utils/findHighestActualCashValue";
import calculateGrandTotalOfActualCashValues from "../../utils/calculateGrandTotalOfActualCashValues";
import ProvideQuoteFigures from "../APDandMTC/ProvideQuoteFigures";
import config from "../../../../config/env";
import ExpandCollapseArrow from "../../../generals/DesignElements/ExpandCollapseArrow";
import RedButton from "../../../assets/forms/RedButton";
import BlueButton from "../../../assets/forms/BlueButton";
import clearQuoteList from "../utils/clearQuoteList";
import { useMediaQuery } from "@mui/material";
import { t } from "i18next";

function UnderwritingDetails(props) {
  const agencyID = props.userInfo.agencyID;
  const userInfo = props.userInfo;
  const insuredID = props.insuredID;
  const disabled = props.disabled;
  const {
    formData,
    setFormData,
    data,
    trailerList,
    truckList,
    policyID,
    apdAndMtc,
    insuredInfo,
    openModal,
  } = props;

  const [show, setShow] = useState(true);

  const downloadInformationSheet = async (e) => {
    e.preventDefault();
    let currentTruckList = [];
    let currentTrailerList = [];
    const truckSnapshot = await getDocs(
      collection(db, "agencies", agencyID, "insureds", insuredID, "power-units")
    );
    truckSnapshot.forEach((doc) => {
      currentTruckList.push({ ...doc.data(), id: doc.id });
    });
    const trailerSnapshot = await getDocs(
      collection(db, "agencies", agencyID, "insureds", insuredID, "trailers")
    );
    trailerSnapshot.forEach((doc) => {
      currentTrailerList.push({ ...doc.data(), id: doc.id });
    });
    let tiLimit = "";
    if (
      formData.apdDetails?.trailerInterchange ||
      formData.apdDetails?.trailerInterchange
    ) {
      if (!formData.apdDetails?.trailerInterchange)
        tiLimit = formData.apdDetails?.trailerInterchangeLimit;
      else tiLimit = formData.apdDetails?.trailerInterchangeLimit;
    }
    let tiExposure = "";
    if (formData.apdDetails?.tiExposure || formData.apdDetails?.tiExposure) {
      if (!formData.apdDetails?.tiExposure)
        tiExposure = formData.apdDetails?.tiExposure;
      else tiExposure = formData.apdDetails?.tiExposure;
    }
    let coverage = "";
    if (formData.hasAPDCoverage || formData.hasMTCCoverage) {
      if (formData.hasAPDCoverage && formData.hasMTCCoverage)
        coverage = "Auto Physical Damage and Motor Truck Cargo (APD & MTC)";
      else if (formData.hasAPDCoverage && !formData.hasMTCCoverage)
        coverage = "Auto Physical Damage (APD)";
      else if (!formData.hasAPDCoverage && formData.hasMTCCoverage)
        coverage = "Motor Truck Cargo (MTC)";
    }

    const data = {
      namedInsured: insuredInfo.company,
      submissionDate: new Date().toLocaleDateString("en-US", {
        month: "short",
        day: "2-digit",
        year: "numeric",
      }),
      dba: formData.dba,
      mailingAddress: insuredInfo.mailingAddress,
      physicalAddress: insuredInfo.physicalAddress,
      dot: insuredInfo.dot,
      effectiveDate: formData?.effectiveDate
        ?.toDate()
        .toLocaleDateString("en-US", {
          month: "short",
          day: "2-digit",
          year: "numeric",
        }),
      tiv: calculateGrandTotalOfActualCashValues(
        currentTruckList,
        currentTrailerList
      ),
      anyOneLoss: calculateGrandTotalOfActualCashValues(
        currentTruckList,
        currentTrailerList
      ),
      anyOneUnitLimit: findHighestActualCashValue(currentTruckList),
      numberOfUnits: currentTruckList.length,
      numberOfTrailers: currentTrailerList.length,
      maxUnitValue: findHighestActualCashValue(currentTruckList),
      maxTrailerValue: findHighestActualCashValue(currentTrailerList),
      commodities: formData.commodityFields || [],
      towingLimit: formData.apdDetails?.towingStorageAndCleanUpLimit || "",
      nonOwnedAutoLimit: formData.apdDetails?.nonOwnedHiredAutoLimit || "",
      tiLimit,
      tiExposure,
      coverage,
      year1APDDeductible: formData.lossHistory?.year1APDLossData?.deductible,
      year1APDLosses: formData.lossHistory?.year1APDLossData?.losses,
      year1APDClaims: formData.lossHistory?.year1APDLossData?.howManyClaims,
      year1MTCDeductible: formData.lossHistory?.year1MTCLossData?.deductible,
      year1MTCLosses: formData.lossHistory?.year1MTCLossData?.losses,
      year1MTCClaims: formData.lossHistory?.year1MTCLossData?.howManyClaims,
      year1tiv: formData.lossHistory?.year1APDLossData?.tiv,
      year1units: formData.lossHistory?.year1APDLossData?.numberOfUnits,
      year1mileage: formData.lossHistory?.year1APDLossData?.mileage,
      year1apdCarrier: formData.lossHistory?.year1APDLossData?.carrier,
      year1mtcCarrier: formData.lossHistory?.year1MTCLossData?.carrier,
      year2APDDeductible: formData.lossHistory?.year2APDLossData?.deductible,
      year2APDLosses: formData.lossHistory?.year2APDLossData?.losses,
      year2APDClaims: formData.lossHistory?.year2APDLossData?.howManyClaims,
      year2MTCDeductible: formData.lossHistory?.year2MTCLossData?.deductible,
      year2MTCLosses: formData.lossHistory?.year2MTCLossData?.losses,
      year2MTCClaims: formData.lossHistory?.year2MTCLossData?.howManyClaims,
      year2tiv: formData.lossHistory?.year2APDLossData?.tiv,
      year2units: formData.lossHistory?.year2APDLossData?.numberOfUnits,
      year2mileage: formData.lossHistory?.year2APDLossData?.mileage,
      year2apdCarrier: formData.lossHistory?.year2APDLossData?.carrier,
      year2mtcCarrier: formData.lossHistory?.year2MTCLossData?.carrier,
      year3APDDeductible: formData.lossHistory?.year3APDLossData?.deductible,
      year3APDLosses: formData.lossHistory?.year3APDLossData?.losses,
      year3APDClaims: formData.lossHistory?.year3APDLossData?.howManyClaims,
      year3MTCDeductible: formData.lossHistory?.year3MTCLossData?.deductible,
      year3MTCLosses: formData.lossHistory?.year3MTCLossData?.losses,
      year3MTCClaims: formData.lossHistory?.year3MTCLossData?.howManyClaims,
      year3tiv: formData.lossHistory?.year3APDLossData?.tiv,
      year3units: formData.lossHistory?.year3APDLossData?.numberOfUnits,
      year3mileage: formData.lossHistory?.year3APDLossData?.mileage,
      year3apdCarrier: formData.lossHistory?.year3APDLossData?.carrier,
      year3mtcCarrier: formData.lossHistory?.year3MTCLossData?.carrier,
      year4APDDeductible: formData.lossHistory?.year4APDLossData?.deductible,
      year4APDLosses: formData.lossHistory?.year4APDLossData?.losses,
      year4APDClaims: formData.lossHistory?.year4APDLossData?.howManyClaims,
      year4MTCDeductible: formData.lossHistory?.year4MTCLossData?.deductible,
      year4MTCLosses: formData.lossHistory?.year4MTCLossData?.losses,
      year4MTCClaims: formData.lossHistory?.year4MTCLossData?.howManyClaims,
      year4tiv: formData.lossHistory?.year4APDLossData?.tiv,
      year4units: formData.lossHistory?.year4APDLossData?.numberOfUnits,
      year4mileage: formData.lossHistory?.year4APDLossData?.mileage,
      year4apdCarrier: formData.lossHistory?.year4APDLossData?.carrier,
      year4mtcCarrier: formData.lossHistory?.year4MTCLossData?.carrier,
      year5APDDeductible: formData.lossHistory?.year5APDLossData?.deductible,
      year5APDLosses: formData.lossHistory?.year5APDLossData?.losses,
      year5APDClaims: formData.lossHistory?.year5APDLossData?.howManyClaims,
      year5MTCDeductible: formData.lossHistory?.year5MTCLossData?.deductible,
      year5MTCLosses: formData.lossHistory?.year5MTCLossData?.losses,
      year5MTCClaims: formData.lossHistory?.year5MTCLossData?.howManyClaims,
      year5tiv: formData.lossHistory?.year5APDLossData?.tiv,
      year5units: formData.lossHistory?.year5APDLossData?.numberOfUnits,
      year5mileage: formData.lossHistory?.year5APDLossData?.mileage,
      year5apdCarrier: formData.lossHistory?.year5APDLossData?.carrier,
      year5mtcCarrier: formData.lossHistory?.year5MTCLossData?.carrier,
      apdDeductibleA: formData.apdDetails?.targetDeductible,
      mtcDeductibleA: formData.mtcDetails?.targetDeductible,
    };
    console.log(data);
    const { REACT_APP_SOUTHERN_BACKEND } = config;
    const url = `${REACT_APP_SOUTHERN_BACKEND}/create-information-sheet`;
    const email = auth.currentUser.email;
    axios
      .post(url, data)
      .then((response) => {
        console.log(response.data);
        window.open(response.data);
      })
      .then((results) => console.log(results))
      .catch((e) => {
        console.log(e);
      });
  };

  const userIsUnderwriter =
    userInfo.role === "Underwriter" || userInfo.role === "Admin";

  const isMobileScreen = useMediaQuery("(max-width: 600px)");
  const isQuoteRequest = formData.documentType === "Quote Request";

  return (
    <>
      {userIsUnderwriter && (
        <>
          <div className="card mt-4">
            <div className="card-header bg-[#072a48] text-white">
              <button onClick={(e) => setShow(!show)}>
                Underwriting Details
              </button>
              <ExpandCollapseArrow show={show} setShow={setShow} />
            </div>
            {show && (
              <div className="card-body">
                <>
                  {(userInfo.role === "Admin" ||
                    userInfo.role === "Underwriter") &&
                    (formData.hasMTCCoverage || formData.hasAPDCoverage) && (
                      <div className="w-full text-center mt-4 mb-4">
                        <button
                          className="bg-green-900 hover:bg-green-700 rounded w-full p-2 mx-auto text-white text-medium"
                          onClick={(e) => downloadInformationSheet(e)}
                        >
                          Download KP Brokers Information Sheet
                        </button>
                      </div>
                    )}
                  <form onSubmit={e => {e.preventDefault(); openModal();}}>
                    <ProvideQuoteFigures
                      disabled={disabled}
                      formData={formData}
                      policyID={policyID}
                      setFormData={setFormData}
                      userInfo={userInfo}
                    />
                    {isQuoteRequest && userIsUnderwriter && (
                      <article>
                        <div className="flex items-center mt-2">
                          <BlueButton
                            text={t("New Quote")}
                            disabled={false}
                            hasContentToBottom={true}
                            hasContentToRight={!isMobileScreen}
                            className={isMobileScreen ? "w-[100%]" : ""}
                            handleAsSubmit={true}
                          />
                          <RedButton
                            text={t("Clear List")}
                            onClickFunction={(e) => {
                              clearQuoteList(e);
                            }}
                            hasContentToBottom={true}
                            hasContentToRight={!isMobileScreen}
                            className={isMobileScreen ? "w-[100%]" : ""}
                          />
                        </div>
                      </article>
                    )}
                  </form>
                </>
              </div>
            )}
          </div>
        </>
      )}
    </>
  );
}

export default UnderwritingDetails;
