import { useState, useEffect, useCallback } from "react";
import { db } from "../../firebase";
import {
  collectionGroup,
  limit,
  onSnapshot,
  query,
  where,
} from "firebase/firestore";
import {
  activeFilters,
  manageEndorsementOptions,
} from "../../utils/searchFilters";

const useEndorsements = (props) => {
  const policyID = props.policyID;
  const insuredInfo = props.insuredInfo;
  const userInfo = props.userInfo;
  const endoEffectiveDate = props.endoEffectiveDate;
  const formData = props.formData;
  const truckData = props.truckData;
  const agencyInfo = props.agencyInfo;

  let subtitle;
  const [modalIsOpen, setIsOpen] = useState(false);

  const [currentEndoFilters, setCurrentEndoFilters] = useState({
    submitted: true,
  });

  function openModal() {
    setIsOpen(true);
  }

  function afterOpenModal() {
    subtitle.style.color = "#f00";
  }

  function closeModal() {
    setIsOpen(false);
  }

  const [endorsements, setEndorsements] = useState([]);
  const [skeletonTableLoader, setSkeletonTableLoader] = useState(false)
  const filters = activeFilters(manageEndorsementOptions, currentEndoFilters);

  useEffect(() => {
    setSkeletonTableLoader(true)
    const otherFilters = filters.filter(filter => filter !== 'Exception Request');
    const buildQuery1 = () => {
      const endorsementsRef = query(collectionGroup(db, "endorsements"));
      let q = endorsementsRef;
      q = query(q, where("documentType", "==", "Exception Request"));
      return q;
    };
    const buildQuery2 = () => {
      const endorsementsRef = query(collectionGroup(db, "endorsements"));
      let q = endorsementsRef;
      const otherFilters = filters?.filter(filter => filter !== 'Exception Request');
      if (otherFilters.length > 0) {
        q = query(q, where("status", "in", otherFilters));
      }
      return q;
    };
    let allResults = [];
    const endoQuery1 = buildQuery1();
    const unsubscribe1 = onSnapshot(endoQuery1, (querySnapshot) => {
      let values = [];
      querySnapshot.forEach((doc) => {
        values.push({ ...doc.data(), id: doc.id });
      });
      if (filters.includes('Exception Request') && filters?.length > 0) {
        allResults = [
          ...allResults,
          ...values
        ]
        setEndorsements(allResults);
      }
      setSkeletonTableLoader(false)
    });
    const endoQuery2 = buildQuery2();
    const unsubscribe2 = onSnapshot(endoQuery2, (querySnapshot) => {
      let values = [];
      querySnapshot.forEach((doc) => {
        values.push({ ...doc.data(), id: doc.id });
      });
      if (otherFilters.length > 0 && filters?.length > 0) {
        const allResultsToFilterUniqueValues = [
          ...allResults,
          ...values
        ]
        allResults = Array.from(new Set(allResultsToFilterUniqueValues.map(a => a.id)))
          .map(id => {
            return allResultsToFilterUniqueValues.find(a => a.id === id);
          });
        setEndorsements(allResults);
      }
      setSkeletonTableLoader(false)
    });
    return () => {
      unsubscribe1();
      unsubscribe2();
    }
  }, [currentEndoFilters]);

  return {
    endorsements,
    openModal,
    modalIsOpen,
    afterOpenModal,
    closeModal,
    policyID,
    insuredInfo,
    agencyInfo,
    userInfo,
    endoEffectiveDate,
    formData,
    truckData,
    currentEndoFilters,
    setCurrentEndoFilters,
    skeletonTableLoader
  };
};

export default useEndorsements;
