import React, { useEffect, useState } from "react";
import { Table, Thead, Tbody, Tr, Td } from "react-super-responsive-table";
import IconButton from "@mui/material/IconButton";
import {
  Delete as DeleteIcon,
  ModeEdit as PencilIcon,
} from "@mui/icons-material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import classNames from "classnames";
import {
  addDoc,
  collection,
  deleteDoc,
  doc,
  onSnapshot,
  query,
  setDoc,
  where,
} from "firebase/firestore";
import { db } from "../../../../firebase";
import FormPopup from "../../../generals/Popup/FormPopup";
import BlueButton from "../../../assets/forms/BlueButton";
import { useMediaQuery } from "@mui/material";
import ImportDriversTrainings from "./ImportDriversTrainings";
import TableSkeleton from "../../../generals/TableSkeleton";
import useTranslations from "../../../../Context/actions/useTranslations";
import { confirmDeletePromptGlobal } from "../../../../layout/ConfirmDeleteProvider";

export default function DriverTrainingPanel(driversLicenseNumber) {
  const [trainingHistory, setTrainingHistory] = useState([]);
  const [loading, setLoading] = useState(true);

  const { t } = useTranslations()
  
  useEffect(() => {
    const fetchTrainingHistory = () => {
      setLoading(true);
      const trainingsCollectionRef = collection(db, "driver-trainings");
      const q = query(
        trainingsCollectionRef,
        where("driversLicenseNumber", "==", driversLicenseNumber)
      );
      const unsubscribe = onSnapshot(q, (querySnapshot) => {
        const history = [];
        querySnapshot.forEach((doc) => {
          history.push({ ...doc.data(), id: doc.id });
        });
        setLoading(false)
        setTrainingHistory(history);
      });
      return unsubscribe;
    };

    const unsubscribe = fetchTrainingHistory();

    return () => unsubscribe();
  }, []);

  const handleDeleteTraining = async (id) => {
    const confirmation = await confirmDeletePromptGlobal("To confirm deletion, confirm the action");
    if (confirmation === "DELETE") {
      const trainingRef = doc(db, "driver-trainings", id);
      await deleteDoc(trainingRef);
    }
  };

  const [editData, setEditData] = useState(null);

  const handleEditTraining = async (id) => {
    setEditData(trainingHistory.find((training) => training.id === id));
    setOpenModalDriverTrainingModal(true);
  };

  const [openModalDriverTrainingModal, setOpenModalDriverTrainingModal] =
    useState(false);

  const handleAddTraining = () => {
    setEditData(null);
    setOpenModalDriverTrainingModal(true);
  };

  const handleCloseDriverTrainingModal = () => {
    setOpenModalDriverTrainingModal(false);
  };

  

  const addDriverModal = [
    {
      label: t('plm.drivers.details.training-name'),
      type: "text",
      name: "trainingName",
      required: true,
      defaultValue: editData?.trainingName || "",
    },
    {
      label: t('plm.drivers.details.notes'),
      type: "text",
      name: "notes",
      required: true,
      defaultValue: editData?.notes || "",
    },
    {
      label: t('plm.drivers.details.institution'),
      type: "text",
      name: "institution",
      required: true,
      disabled: true,
      defaultValue: "JJ Keller",
    },
    {
      label: t('plm.drivers.details.completion-date'),
      type: "date",
      name: "completionDate",
      required: true,
      defaultValue: editData?.completionDate ? editData?.completionDate.toDate() : null,
    },
    {
      label: `${t('plm.upload')} ${t('plm.drivers.details.certificate-file')}`,
      type: "file",
      storageFile: `files/drivers/${driversLicenseNumber}`,
      name: "certificateFileURL",
      required: true,
      multiple: false,
      dissapearWhenImgExists: true,
      showPastingOption: true,
      defaultValue:
        editData?.certificateFileURL?.length > 0 ?
        editData?.certificateFileURL : [],
    },
  ];

  const handleSaveTraining = async (e, value) => {
    e.preventDefault();
    const newPayload = {
      ...value,
      driversLicenseNumber,
    };
    if (!editData) {
      const trainingsCollectionRef = collection(db, "driver-trainings");
      await addDoc(trainingsCollectionRef, newPayload);
    } else {
      const trainingsCollectionRef = doc(db, "driver-trainings", editData.id)
      await setDoc(
        trainingsCollectionRef,
        newPayload,
        { merge: true }
      );
    }
    handleCloseDriverTrainingModal();
  };

  const formSettingsDriverTraining = {
    onSubmit: handleSaveTraining,
    onDecline: handleCloseDriverTrainingModal,
    inputs: addDriverModal,
    buttonLabel: t('plm.drivers.submit'),
    buttonLabelDecline: t('plm.cancel'),
  };

  const isMobileScreen = useMediaQuery("(max-width: 600px)");

  const [importDriversTrainingSection, setImportDriversTrainingSection] = useState(false)

  return (
    <div>
      {loading ?
        <div className="mb-3">
          <TableSkeleton rows={2} columns={6} />
        </div> :
        <>
          {importDriversTrainingSection ?
            <>
              <ImportDriversTrainings
                setShowImport={setImportDriversTrainingSection}
                driversLicenseNumber={driversLicenseNumber}
              />
            </> :
            <>
              <BlueButton
                text={t('plm.drivers.details.add-training')}
                onClickFunction={handleAddTraining}
                hasContentToBottom={true}
                hasContentToRight={!isMobileScreen}
                className={isMobileScreen ? "w-[100%]" : ""}
              />
              
              <BlueButton
                text={t('plm.drivers.details.import-training')}
                onClickFunction={() => setImportDriversTrainingSection(true)}
                hasContentToBottom={true}
                hasContentToRight={!isMobileScreen}
                className={isMobileScreen ? "w-[100%]" : ""}
              />
              <Table>
                <Thead>
                  <Tr>
                    <Td
                      className={classNames(
                        "bg-[#072a48] min-w-[80px] text-white rounded-tl-md cursor-pointer"
                      )}
                    >
                      {t('plm.drivers.details.actions')}
                    </Td>
                    <Td
                      className={classNames(
                        "bg-[#072a48] text-white cursor-pointer max-w-[275px] min-w-[150px]"
                      )}
                    >
                      {t('plm.drivers.details.training-name')}
                    </Td>
                    <Td
                      className={classNames("bg-[#072a48] text-white cursor-pointer")}
                    >
                      {t('plm.drivers.details.completion-date')}
                    </Td>
                    <Td
                      className={classNames("bg-[#072a48] text-white cursor-pointer")}
                    >
                      {t('plm.drivers.details.notes')}
                    </Td>
                    <Td
                      className={classNames("bg-[#072a48] text-white cursor-pointer")}
                    >
                      {t('plm.drivers.details.institution')}
                    </Td>
                    <Td
                      className={classNames(
                        "bg-[#072a48] text-white rounded-tr-md cursor-pointer"
                      )}
                    >
                      {t('plm.drivers.details.view-certificate')}
                    </Td>
                  </Tr>
                </Thead>
                <Tbody>
                  {trainingHistory.map((training, index) => (
                    <Tr key={index}>
                      <Td>
                        <div className="flex">
                          <IconButton className="mr-1" onClick={() => handleDeleteTraining(training.id)}>
                            <DeleteIcon sx={{ color: 'red' }} />
                          </IconButton>
                          <IconButton onClick={() => handleEditTraining(training.id)}>
                            <PencilIcon sx={{ color: 'red' }} />
                          </IconButton>
                        </div>
                      </Td>
                      <Td>{training.trainingName}</Td>
                      <Td>{
                        training?.completionDate ?
                        training.completionDate.toDate().toString().slice(4, 15) : ''
                      }</Td>
                      <Td>{training.notes}</Td>
                      <Td>{training.institution}</Td>
                      <Td>
                        {training.certificateFileURL?.length > 0 ? (
                          <a
                            href={training.certificateFileURL[0]}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <IconButton>
                              <VisibilityIcon sx={{ color: '#072a48' }} />
                            </IconButton>
                          </a>
                        ) : (
                          "N/A"
                        )}
                      </Td>
                    </Tr>
                  ))}
                </Tbody>
              </Table>
              <FormPopup
                isOpen={openModalDriverTrainingModal}
                formSettings={formSettingsDriverTraining}
                onRequestClose={handleCloseDriverTrainingModal}
                title={t('plm.drivers.details.add-new-training')}
              />
            </>
          }
        </>
    }
    </div>
  );
}
