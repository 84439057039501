import React, { useEffect, useState } from "react";
import { db } from "../../../firebase";
import { collection, doc, getDoc, onSnapshot, query } from "firebase/firestore";
import DynamicTable from "../../assets/lists/DynamicTable";
import Breadcrumbs from "../../assets/Breadcrumbs";

export default function AgenciesList() {
  const [agencies, setAgencies] = useState([]);
  const [skeletonTableLoader, setSkeletonTableLoader] = useState(false);
  useEffect(() => {
    setSkeletonTableLoader(true);

    // Step 1: Set up a real-time listener for the 'agencies' collection
    const q = query(collection(db, "agencies"));
    const unsubscribe = onSnapshot(q, async (querySnapshot) => {
      const agenciesWithStatus = await Promise.all(
        querySnapshot.docs.map(async (document) => {
          const agencyData = { ...document.data(), id: document.id };
          const ownerID = agencyData.ownerID;
          let phoneNumber = agencyData.phoneNumber;
          // Format phone number
          if (phoneNumber) {
            phoneNumber = phoneNumber.replace(/[^0-9]/g, "");
            if (phoneNumber.length === 10) {
              phoneNumber = `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(
                3,
                6
              )}-${phoneNumber.slice(6)}`;
            }
          }
          agencyData.phoneNumber = phoneNumber;
          // Step 2: Fetch the corresponding document from 'appointment-applications' by ownerID
          let status = "Not Started"; // Default to null if no status is found
          if (ownerID) {
            try {
              const applicationDocRef = doc(
                db,
                "appointment-applications",
                ownerID
              );
              const applicationDocSnap = await getDoc(applicationDocRef);

              if (applicationDocSnap.exists()) {
                const applicationData = applicationDocSnap.data();
                status = applicationData.status || "Not Started"; // Assuming 'status' field exists in the document
              }
            } catch (error) {
              console.error(
                `Error fetching application status for ownerID ${ownerID}:`,
                error
              );
            }
          }

          // Step 3: Add 'status' to the agency object
          return { ...agencyData, status };
        })
      );

      // Step 4: Update state with the modified agencies data
      setAgencies(agenciesWithStatus);
      setSkeletonTableLoader(false);
    });

    return () => unsubscribe(); // Clean up the listener on unmount
  }, []);

  const columns = [
    {
      name: "name",
      label: "Name",
    },
    {
      name: "status",
      label: "Status",
    },
    {
      name: "state",
      label: "State",
    },
    {
      name: "primaryContact",
      label: "Primary Contact",
    },
    {
      name: "phoneNumber",
      label: "Phone Number",
    },
    {
      name: "primaryContactEmail",
      label: "Primary Contact Email",
    },
  ];

  const filterKeys = [
    "name",
    "status",
    "state",
    "primaryContact",
    "phoneNumber",
    "primaryContactEmail",
  ];

  const [tableData, setTableData] = useState([]);

  const parsePhoneNumber = (phoneNumber) => {
    const cleaned = phoneNumber.replace(/[^+\d]/g, '');
    return cleaned.startsWith('+') ? `tel:${cleaned}` : `tel:${cleaned}`;
  }

  const formingTableData = () => {
    const tableData = agencies.map((data, dataIndex) => {
      const { name, phoneNumber, primaryContact, primaryContactEmail, state } =
        data || {};
      return {
        ...data,
        tableInfo: {
          name: {
            text: name,
            isButton: true,
          },
          status: {
            text: data.status,
          },
          state: {
            text: state,
          },
          primaryContact: {
            text: primaryContact,
          },
          phoneNumber: {
            text: phoneNumber,
            href: parsePhoneNumber(phoneNumber),
          },
          primaryContactEmail: {
            text: primaryContactEmail,
            href: `mailto:${primaryContactEmail}`,
          },
        },
        dataIndex,
        iconLeft: [],
        iconRight: [],
      };
    });
    return tableData;
  };

  useEffect(() => {
    if (typeof agencies.length !== "undefined") {
      const tableData = formingTableData();
      setTableData(tableData);
    }
  }, [agencies]);

  const breadcrumbs = [{ label: "Agencies" }, { label: "Agencies List" }];

  return (
    <div>
      <div className="mt-2">
        <Breadcrumbs items={breadcrumbs} />
        <h1 className="text-3xl mt-2 font-semibold mb-3">Agencies List</h1>
        <p className="mb-3">
          Click on an agency name to view more information.
        </p>
      </div>
      <DynamicTable
        skeletonLoader={skeletonTableLoader}
        columns={columns}
        tableData={tableData}
        filterKeys={filterKeys}
        onClickItem={(e, id) => {
          const agency = tableData.find((agency) => agency.id === id);
          window.open(`/agent-profile/${agency.ownerID}/${id}`, "_blank");
        }}
      />
    </div>
  );
}
