import rateTruck from "./RateTruck";
import {
  collection,
  doc,
  getDoc,
  getDocs,
  updateDoc,
  writeBatch,
} from "firebase/firestore";
import { db } from "../../firebase";
import getFleetCredit from "./getFleetCredit";
import getPolicyData from "./premiumCalculation/getPolicyData";
import miniValidation from "./premiumCalculation/miniValidation";
import { individualToEndorsement } from "../PolicyLifecycleManager/Drivers/individualToEndorsement.";
import getTaxSchedule from "./getTaxSchedule";
import getStampFee from "./premiumCalculation/getStampFee";
import getSurplusLinesRate from "./premiumCalculation/getSurplusLinesRate";
import getCurrentPolicyTotals from "./getCurrentPolicyTotals";

const updateTrucks = async (truckList, formData) => {
  try {
    const promises = truckList.map(async (truck) => {
      const { truckRadius, id: truckId } = truck;
      const batch = writeBatch(db);
      const unitRef = doc(
        db,
        "agencies",
        formData.agencyID,
        "insureds",
        formData.insuredID,
        "power-units",
        truckId
      );
      batch.update(unitRef, {
        truckRadius,
      });
      return batch.commit();
    });
    return Promise.all(promises);
  } catch (error) {
    console.log("Error updating trucks", error);
    throw error;
  }
};

export const ratePowerUnitGeneral = async (
  e,
  truckList,
  policyID,
  singleUnit,
  endoID,
  setSuccess,
  insuredInfo,
  userInfo
) => {
  e.preventDefault();
  const policyRef = doc(db, "policy-applications", policyID);
  const policySnap = await getDoc(policyRef);
  const formData = policySnap.data();

  // Clean the truck list by removing undefined values from the object before rating
  const cleanTruckListFunction = (truckList) => {
    let cleanTruckList;
    if (Array.isArray(truckList)) {
      cleanTruckList = truckList.map((truck) => {
        const cleanedEntries = Object.entries(truck).filter(
          ([key, value]) => value !== undefined
        );
        return Object.fromEntries(cleanedEntries);
      });
    } else {
      cleanTruckList = truckList;
    }
    return cleanTruckList;
  };
  const trucks = cleanTruckListFunction(truckList);

  const isEndorsement = Boolean(
    typeof formData === "undefined" || formData?.documentType === "Policy"
  );
  const isApplication = formData?.documentType === "Application";

  let updatedTruckList = [];
  try {
    if (isApplication) {
      await updateTrucks(trucks, formData);
    } else if (isEndorsement) {
      updatedTruckList = await rateAllUnits(
        e,
        policyID,
        formData,
        trucks,
        formData?.alPolicy?.adjustmentPercent || 0,
        isEndorsement,
        true,
        endoID
      );
    }
    if (
      typeof formData === "undefined" ||
      formData?.documentType === "Policy"
    ) {
      if (endoID) {
        const finalTruckList = cleanTruckListFunction(updatedTruckList[1]);

        // this can be deleted
        // const agencyID = formData.agencyID;
        // const insuredID = formData.insuredID;
        // getCurrentPolicyTotals(agencyID, insuredID, policyID);

        if (!singleUnit) {
          updateDoc(
            doc(db, "policy-applications", policyID, "endorsements", endoID),
            {
              data: finalTruckList,
            }
          );
        }
        if (singleUnit) {
          updateDoc(
            doc(db, "policy-applications", policyID, "endorsements", endoID),
            {
              data: updatedTruckList[1][0],
            }
          );
        }
      } else {
        await individualToEndorsement(
          policyID,
          trucks?.[0]?.effectiveDate,
          "Adjust Radius",
          trucks[0],
          insuredInfo,
          formData,
          userInfo,
          null,
          null,
          null
        );
      }
    }
    if (setSuccess) setSuccess(true);
  } catch (error) {
    console.log("error", error);
    throw error;
  }
};

const cleanTruckList = (truckList) => {
  if (!Array.isArray(truckList)) return truckList;
  return truckList.map((truck) => {
    const cleanedTruck = {};
    for (const key in truck) {
      if (
        Object.prototype.hasOwnProperty.call(truck, key) &&
        truck[key] !== undefined
      ) {
        cleanedTruck[key] = truck[key];
      }
    }
    return cleanedTruck;
  });
};

const getEndorsementData = async (policyID, endoID) => {
  if (!endoID) return [null, null, null];
  const endoRef = doc(
    db,
    "policy-applications",
    policyID,
    "endorsements",
    endoID
  );
  const endoSnap = await getDoc(endoRef);
  return [endoRef, endoSnap, endoSnap.data()];
};

const calculateTotal = (
  rating,
  hasNonOwned,
  hasMCP65,
  adjustmentPercent,
  fleetCredit,
  isEndorsement,
  endoData,
  formData
) => {
  let nonOwnedAdjustment = hasNonOwned ? rating * 0.05 : 0;
  // TODO: MCP 65 adjustment was commented out for now, revisit later.
  let mcp65Adjustment = 0; // hasMCP65 ? rating * 0.05 : 0; commented out for now
  let originalPremium = rating + nonOwnedAdjustment + mcp65Adjustment;
  let underwriterAdjustments = originalPremium * adjustmentPercent;
  let adjustedPremium = originalPremium + underwriterAdjustments;
  let fleetCreditAmount = adjustedPremium * fleetCredit;
  let totalPremiumAfterFleetCredit = adjustedPremium - fleetCreditAmount;

  // log all the values
  console.log('rating: ', rating);
  console.log('adjustment percent: ', adjustmentPercent);
  console.log('fleet credit: ', fleetCredit);
  console.log('nonOwnedAdjustment: ', nonOwnedAdjustment);
  console.log('mcp65Adjustment: ', mcp65Adjustment);
  console.log('originalPremium: ', originalPremium);
  console.log('underwriterAdjustments: ', underwriterAdjustments);
  console.log('adjustedPremium: ', adjustedPremium);
  console.log('fleetCreditAmount: ', fleetCreditAmount);
  console.log('totalPremiumAfterFleetCredit: ', totalPremiumAfterFleetCredit);

  if (isEndorsement && endoData) {
    const effectiveDate = formData.effectiveDate.toDate();
    const endoEffectiveDate = endoData.endoEffectiveDate.toDate();

    const daysRemainingInPolicy = Math.floor(
      (endoEffectiveDate - effectiveDate) / (1000 * 60 * 60 * 24)
    );

    const isLeapYear = (year) => {
      return (year % 4 === 0 && year % 100 !== 0) || year % 400 === 0;
    };

    // Calculate the number of leap years between the effective date and expiration date
    let leapDays = 0;
    for (
      let year = effectiveDate.getFullYear();
      year <= endoEffectiveDate.getFullYear();
      year++
    ) {
      if (isLeapYear(year)) {
        const leapDay = new Date(year, 1, 29); // February 29
        if (leapDay >= effectiveDate && leapDay <= endoEffectiveDate) {
          leapDays++;
        }
      }
    }

    // Adjust the total premium considering the number of leap days
    // subtract a day because that's what inspas did
    const totalDays = daysRemainingInPolicy + leapDays - 1;
    const dailyPremium = totalPremiumAfterFleetCredit / (365 + leapDays);
    totalPremiumAfterFleetCredit -= dailyPremium * totalDays;
  }

  return totalPremiumAfterFleetCredit;
};

const updateValuesArray = (values, unit, ratingData, policyID) => {
  const newValues = values.map((val) => {
    const policiesDetails = val.policiesDetails.map((detail) => {
      if (detail.id === policyID) {
        return {
          ...detail,
          ...ratingData,
        };
      }
      return detail;
    });
    if (val.id === unit.id) {
      return {
        ...val,
        policiesDetails,
      };
    }
    return val;
  });
  return newValues;
};

const updateBatch = (batch, formData, unit) => {
  const unitRef = doc(
    db,
    "agencies",
    formData.agencyID,
    "insureds",
    formData.insuredID,
    "power-units",
    unit.id
  );

  batch.update(unitRef, unit);
};

const rateAllUnits = async (
  e,
  policyID,
  formData,
  truckList,
  adjustmentPercentage,
  isEndorsement,
  doNotRerate,
  endoID,
  isEndo
) => {
  // log all the values
  console.log('policyID: ', policyID);
  console.log('formData: ', formData);
  console.log('truckList: ', truckList);
  console.log('adjustmentPercentage: ', adjustmentPercentage);
  console.log('isEndorsement: ', isEndorsement);
  console.log('doNotRerate: ', doNotRerate);
  console.log('endoID: ', endoID);
  console.log('isEndo: ', isEndo);
  try {
    let trucks = cleanTruckList(truckList);
    if (trucks.length === 0) return [0, trucks, 0];

    const [policyData, adjustmentPercent] = await getPolicyData(
      policyID,
      formData,
      isEndorsement,
      adjustmentPercentage
    );

    const [endoRef, endoSnap, endoData] = await getEndorsementData(
      policyID,
      endoID
    );

    if (!miniValidation(trucks, policyData)) return;

    const hasMCP65 = formData?.hasMCP65 || false;
    const hasNonOwned = policyData?.hasNonOwned || false;

    const batch = writeBatch(db);
    let values = [...trucks];
    let totalRating = 0;
    let TIV = 0;
    const unitCountForCalculations = isEndorsement
      ? policyData?.bindData?.powerUnitCountAtBind ||
        policyData?.powerUnitCountAtBind
      : trucks.length;
    const fleetCredit = await getFleetCredit(unitCountForCalculations);

    for (const unit of trucks) {
      const policyDetailToShow = unit?.policiesDetails.find(
        (detail) => detail.id === policyID
      );

      const truckClass = policyDetailToShow?.truckClass || unit.truckClass;
      const truckRadius = policyDetailToShow?.truckRadius || unit.truckRadius;
      const stateOfEntry =
        policyDetailToShow?.stateOfEntry || formData.stateOfEntry;
      const coverageSingleLimit =
        policyDetailToShow?.coverageSingleLimit || formData.coverageSingleLimit;
      const program = policyDetailToShow?.program || policyData.program;

      const rating = rateTruck(
        policyData.product,
        coverageSingleLimit,
        false,
        stateOfEntry,
        truckClass,
        truckRadius,
        program
      );
      console.log('rating: ', rating);
      const totalPremiumAfterFleetCredit = calculateTotal(
        rating,
        hasNonOwned,
        hasMCP65,
        adjustmentPercent,
        fleetCredit,
        isEndorsement,
        endoData,
        formData
      );
      console.log('totalPremiumAfterFleetCredit: ', totalPremiumAfterFleetCredit);

      totalRating += totalPremiumAfterFleetCredit;
      TIV += parseInt(unit.truckACV);

      const taxSchedule = await getTaxSchedule();
      const taxesAndFees = { ...taxSchedule };
      const stampFee = getStampFee(formData.stateOfEntry, taxesAndFees);
      const surplusLinesTax = getSurplusLinesRate(
        formData.stateOfEntry,
        taxesAndFees
      );
      const otherFees = isEndo ? 0 : taxesAndFees.otherFees;
      const processingFee = taxesAndFees.processingFee;
      const taxes = {
        surplusLinesTax: surplusLinesTax * totalPremiumAfterFleetCredit,
        stampFee: stampFee * totalPremiumAfterFleetCredit,
        processingFee: processingFee * totalPremiumAfterFleetCredit,
        otherFees: isEndo ? 0 : otherFees,
      };

      const ratingData = {
        rating,
        fleetCredit,
        nonOwnedAdjustment: hasNonOwned ? rating * 0.05 : 0,
        mcp65Adjustment: hasMCP65 ? rating * 0.05 : 0,
        adjustedRate: totalPremiumAfterFleetCredit,
        taxes,
        totalPremiumAfterTaxes:
          totalPremiumAfterFleetCredit +
          stampFee +
          otherFees +
          surplusLinesTax +
          processingFee,
      };

      values = updateValuesArray(values, unit, ratingData, policyID);

      const policiesDetails = unit.policiesDetails.map((detail) => {
        if (detail.id === policyID) {
          return {
            ...detail,
            ...ratingData,
          };
        }
        return detail;
      });

      const newUnit = {
        ...unit,
        policiesDetails,
      };

      if (!doNotRerate && !isEndorsement) {
        updateBatch(batch, formData, newUnit);
      }
    }

    if (!isEndorsement && !doNotRerate) await batch.commit();
    trucks = values;
    const agencyID = formData.agencyID;
    const insuredID = formData.insuredID;
    const newTotalRating = !isEndorsement
      ? await getCurrentPolicyTotals(agencyID, insuredID, policyID)
      : await getCurrentPolicyTotals(agencyID, insuredID, policyID, endoID);
    return [newTotalRating, trucks, TIV];
  } catch (error) {
    console.log("error rate all units", error);
    throw error;
  }
};

export default rateAllUnits;
