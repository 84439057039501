import React, { useEffect, useState } from "react";
import { Navigate, useNavigate, useParams } from "react-router-dom";
import { db } from "../../firebase";
import { collection, doc, getDoc, onSnapshot, query } from "firebase/firestore";
import AddPowerUnit from "../../Components/underwriter/endorsements/AddPowerUnit";
import AddTrailers from "../../Components/underwriter/endorsements/AddTrailers";
import AddTrailer from "../../Components/underwriter/endorsements/AddTrailer";
import AddPowerUnits from "../../Components/underwriter/endorsements/AddPowerUnits";
import AddDrivers from "../../Components/underwriter/endorsements/AddDrivers";
import AddDriver from "../../Components/underwriter/endorsements/AddDriver";
import { POLICY_ROUTE } from "../../utils/routes";
import BigAlert from "../../Components/generals/Toast/BigAlert";
import RemoveOne from "../../Components/underwriter/endorsements/RemoveOne";
import {
  filterDriverKeysToSearch,
  multipleDriverTableColumn,
  removeMultipleDriversTable,
  removeDriverEndorsementDetails,
  removePowerUnitEndorsementDetails,
  removeTrailerEndorsementDetails,
  removeMultiplePowerUnitsTable,
  multiplePowerUnitsTableColumn,
  filterPowerUnitsKeysToSearch,
  removeMultipleTrailersTable,
  multipleTrailersTableColumn,
  filterTrailerKeysToSearch,
  addWaiverOfSubrogationEndorsementDetails,
} from "../../utils/endorsementDetails";
import RemoveMultiple from "../../Components/underwriter/endorsements/RemoveMultiple";
import { endsWithLowerCaseS } from "../../utils/helpers";
import AddOne from "../../Components/underwriter/endorsements/AddOne";
import SwapPowerUnits from "../../Components/underwriter/endorsements/SwapPowerUnits";
import AdditionalInsured from "../../Components/underwriter/endorsements/AdditionalInsured";
import GoBack from "../../Components/generals/GoBack";
import RequestExceptions from "../../Components/generals/Toast/RequestExceptions";
import { UserAuth } from "../../Context/AuthContent";
import useGeneralState from "../../Context/actions/useGeneralState";
import { endorsementPdfPayloads } from "../../utils/endorsementsPdfPayloads";
import {
  Download as DownloadIcon,
} from "@mui/icons-material";
import BlueButton from "../../Components/assets/forms/BlueButton";

export const Endorsement = () => {
  const navigate = useNavigate();
  const { paramRefID, paramPolicyID } = useParams();
  const [endorsement, setEndorsement] = useState({});
  const [gotEndo, setGotEndo] = useState(false);
  const [agencyName, setAgencyName] = useState("");
  const [agencyInfo, setAgencyInfo] = useState("");
  const [gotAgencyName, setGotAgencyName] = useState(false);
  const { user: auth } = UserAuth();
  const [loading, setLoading] = useState(false);
  const { controlToast } = useGeneralState();

  const getAgencyName = async (agencyID) => {
    try {
      const agencyDocRef = doc(db, "agencies", agencyID);
      const agencyDocSnap = await getDoc(agencyDocRef);
      if (agencyDocSnap.exists()) {
        setAgencyInfo(agencyDocSnap?.data());
        setAgencyName(agencyDocSnap?.data().name);
        setGotAgencyName(true);
      } else {
        console.log("No such agency document with ID:", agencyID);
        setAgencyName("Not Found");
        setGotAgencyName(true);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const endorsementRef = doc(
    db,
    "policy-applications",
    paramPolicyID,
    "endorsements",
    paramRefID
  );

  const [statusText, setStatusText] = useState("Underwrite");
  const [statusColorClass, setStatusColorClass] = useState("text-yellow-900");
  const [success, setSuccess] = useState(false);
  useEffect(() => {
    gotEndo && !gotAgencyName && getAgencyName(endorsement?.agencyID);
    const unsubscribe = onSnapshot(endorsementRef, (doc) => {
      if (doc.exists()) {
        const updatedEndorsementData = doc.data();
        setEndorsement(updatedEndorsementData);
        setGotEndo(true);
        if (updatedEndorsementData.documentType === "Exception Request") {
          setStatusText("Exception Request");
          setStatusColorClass("text-orange-500");
        } else if (updatedEndorsementData.status === "Submitted") {
          setStatusText("Submitted");
          setStatusColorClass("text-yellow-500");
        } else if (
          updatedEndorsementData.status === "Approved" ||
          updatedEndorsementData.status === "Issued"
        ) {
          setStatusText("Approved");
          setStatusColorClass("text-green-900");
        } else if (updatedEndorsementData.status === "Declined") {
          setStatusText("Declined");
          setStatusColorClass("text-[#8B0000]");
        }
      } else {
        setEndorsement(null);
      }
    });
    return () => unsubscribe();
  }, [gotEndo, endorsement?.status, gotAgencyName, endorsement?.agencyID]);

  const requestExceptionQ = collection(
    db,
    "request-exception-types",
  );

  const [requestExceptionInputs, setRequestExceptionInputs] = useState([])

  useEffect(() => {
    if (endorsement?.status !== 'Declined') {
      return () => {}
    }
    const q = query(requestExceptionQ);
    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      const values = [];
      querySnapshot.forEach((doc) => {
        values.push({
          ...doc.data(),
          id: doc.id,
        });
      });
      setRequestExceptionInputs(values)
    });
    return () => {
      unsubscribe();
    };
  }, [
    endorsement?.status,
    endorsement?.agencyID,
    endorsement?.documentType,
    endorsement?.wasARequestException
  ]);

  const handleGoBack = () => {
    navigate(`${POLICY_ROUTE}/${paramPolicyID}`);
  }

  const handleEndorsementCoverageTypeText = () => {
    if (
      endorsement?.type === 'Add Waiver Of Subrogation' &&
      Boolean(endorsement?.data?.type)
    ) {
      if (endorsement?.data?.type === 'AL') {
        return 'Auto Liability'
      }
      if (endorsement?.data?.type === 'GL') {
        return 'General Liability'
      }
    }
    return ''
  }

  const handleDownload = async (e) => {
    e.preventDefault();
    try {
      setLoading(true);
      const { agencyID, insuredID } = endorsement
      const insuredDocRef = doc(db, "agencies", agencyID, 'insureds', insuredID);
      const insuredDocSnap = await getDoc(insuredDocRef);
      const agencyDocRef = doc(db, "agencies", agencyID);
      const agencyDocSnap = await getDoc(agencyDocRef);
      let agencyInfo = null
      if (agencyDocSnap?.exists()) {
        agencyInfo = agencyDocSnap?.data();
      }
      if (insuredDocSnap.exists()) {
        const insuredInfo = insuredDocSnap.data();
        await endorsementPdfPayloads(
          {
            ...endorsement,
            id: paramRefID
          },
          auth,
          insuredInfo,
          agencyInfo
        );
        setLoading(false);
        controlToast(true, "Endorsement printing was succesful", "success");
        return
      }
      setLoading(false);
      controlToast(
        true,
        "There was an error finding insured info to print the endorsement",
        "error"
      );
      return
    } catch (error) {
      setLoading(false);
      controlToast(
        true,
        "There was an error printing the endorsement",
        "error"
      );
    }
  };

  if (endorsement === null) {
    return (
      <Navigate to="*" />
    );
  }

  if (!gotEndo) return null;

  return (
    <div
      className={`
      ${!Boolean(endsWithLowerCaseS(endorsement?.type)) ? "max-w-xl" : ""}
      mx-auto p-6 bg-white shadow-lg rounded-lg mt-3`}
    >
      <GoBack throughHistory={true} />
      <h1 className={`text-3xl font-semibold mb-2 ${statusColorClass}`}>
        {statusText} Endorsement
      </h1>
      {endorsement?.status === 'Approved' ||
        endorsement?.status === 'Issued' ?
        <BlueButton
          text={loading ? 'Loading...' : 'Print Endorsement'}
          onClickFunction={handleDownload}
          icon={<DownloadIcon />}
          disabled={loading}
          hasContentToTop={true}
          hasContentToBottom={true}
        /> : null
      }
      <div>
        <p className="text-gray-700 mb-2">
          <span className="font-semibold">Named Insured:</span>{" "}
          {endorsement.namedInsured}
        </p>
        {endorsement?.alPolicyNo ?
          <p className="text-gray-700 mb-2">
            <span className="font-semibold">AL Policy No:</span>{" "}
            {endorsement.alPolicyNo}
          </p> : null
        }
        <p className="text-gray-700 mb-2">
          <span className="font-semibold">Endorsement Type:</span>{" "}
          {endorsement.type}
        </p>
        {handleEndorsementCoverageTypeText(endorsement) ?
          <p className="text-gray-700 mb-2">
            <span className="font-semibold">Coverage Type:</span>{" "}
            {handleEndorsementCoverageTypeText(endorsement)}
          </p> : null
        }
        <p className="text-gray-700 mb-2">
          <span className="font-semibold">Endorsement Number:</span>{" "}
          {endorsement.endorsementNumber}
        </p>
        <p className="text-gray-700 mb-2">
          <span className="font-semibold">Status:</span> {endorsement.status}
        </p>
        <p className="text-gray-700 mb-2">
          <span className="font-semibold">Endo Effective Date:</span>{" "}
          {endorsement?.endoEffectiveDate?.toDate().toLocaleDateString()}
        </p>
        {endorsement?.deletionReason ?
          <p className="text-gray-700 mb-2">
            <span className="font-semibold">Deletion Reason:</span>{" "}
            {endorsement?.deletionReason}
          </p> : null
        }
      </div>
      <div className="mb-4">
        <p className="text-gray-700 mb-2">
          <span className="font-semibold">Author:</span> {endorsement.author}
        </p>
        <p className="text-gray-700 mb-2">
          <span className="font-semibold">Agency:</span> {agencyName}
        </p>
        <p className="text-gray-700">
          <span className="font-semibold">Submitted on:</span>{" "}
          {endorsement?.dateCreated?.toDate().toLocaleDateString()}
        </p>
        {endorsement?.rejectionReasons &&
        Boolean(endorsement?.rejectionReasons.length) ?
          <p className="text-red-700 mt-2">
            <span className="font-semibold">Rejection Reasons:</span>{" "}
            {endorsement?.rejectionReasons?.join(', ')}
          </p> : null
        }
      </div>
      <RequestExceptions endorsement={endorsement} mustBeApproved={true} />
      <div className="mt-6">
        <p className="text-lg font-semibold mb-2">Details</p>
        {endorsement.type === "Swap Power Units" && (
          <SwapPowerUnits
            setSuccess={setSuccess}
            endorsement={endorsement}
            paramRefID={paramRefID}
            paramPolicyID={paramPolicyID}
            requestExceptionInputs={requestExceptionInputs}
          />
        )}
        {(endorsement.type === "Add Power Units") && (
          <AddPowerUnits
            setSuccess={setSuccess}
            endorsement={endorsement}
            paramRefID={paramRefID}
            paramPolicyID={paramPolicyID}
            requestExceptionInputs={requestExceptionInputs}
          />
        )}
        {endorsement.type === "Add Power Unit" ||
        endorsement.type === "Adjust Radius" ? (
          <AddPowerUnit
            setSuccess={setSuccess}
            endorsement={endorsement}
            paramRefID={paramRefID}
            paramPolicyID={paramPolicyID}
            requestExceptionInputs={requestExceptionInputs}
          />
        ) : null}
        {endorsement.type === "Add Trailers" && (
          <AddTrailers
            setSuccess={setSuccess}
            endorsement={endorsement}
            paramRefID={paramRefID}
            paramPolicyID={paramPolicyID}
            requestExceptionInputs={requestExceptionInputs}
          />
        )}
        {endorsement.type === "Add Trailer" && (
          <AddTrailer
            setSuccess={setSuccess}
            endorsement={endorsement}
            paramRefID={paramRefID}
            paramPolicyID={paramPolicyID}
            requestExceptionInputs={requestExceptionInputs}
          />
        )}
        {endorsement.type === "Add Drivers" && (
          <AddDrivers
            agencyID={endorsement?.agencyID}
            setSuccess={setSuccess}
            endorsement={endorsement}
            paramRefID={paramRefID}
            paramPolicyID={paramPolicyID}
            requestExceptionInputs={requestExceptionInputs}
          />
        )}
        {endorsement.type === "Add Driver" && (
          <AddDriver
            setSuccess={setSuccess}
            endorsement={endorsement}
            paramRefID={paramRefID}
            paramPolicyID={paramPolicyID}
            requestExceptionInputs={requestExceptionInputs}
          />
        )}
        {endorsement.type === "Add Waiver Of Subrogation" && (
          <AddOne
            setSuccess={setSuccess}
            endorsement={endorsement}
            paramRefID={paramRefID}
            paramPolicyID={paramPolicyID}
            subcollection="waivers-of-subrogation"
            details={addWaiverOfSubrogationEndorsementDetails(endorsement)}
            requestExceptionInputs={requestExceptionInputs}
          />
        )}
        {endorsement.type === "Remove Driver" ||
        endorsement.type === "Exclude Driver" ? (
          <RemoveOne
            setSuccess={setSuccess}
            endorsement={endorsement}
            paramRefID={paramRefID}
            paramPolicyID={paramPolicyID}
            subcollection="drivers"
            details={removeDriverEndorsementDetails(endorsement)}
            requestExceptionInputs={requestExceptionInputs}
          />
        ) : null}
        {endorsement.type === "Remove Power Unit" && (
          <RemoveOne
            setSuccess={setSuccess}
            endorsement={endorsement}
            paramRefID={paramRefID}
            paramPolicyID={paramPolicyID}
            subcollection="power-units"
            details={removePowerUnitEndorsementDetails(endorsement)}
            requestExceptionInputs={requestExceptionInputs}
          />
        )}
        {endorsement.type === "Remove Trailer" && (
          <RemoveOne
            setSuccess={setSuccess}
            endorsement={endorsement}
            paramRefID={paramRefID}
            paramPolicyID={paramPolicyID}
            subcollection="trailers"
            details={removeTrailerEndorsementDetails(endorsement)}
            requestExceptionInputs={requestExceptionInputs}
          />
        )}
        {endorsement.type === "Remove Drivers" ||
        endorsement.type === "Exclude Drivers" ? (
          <RemoveMultiple
            setSuccess={setSuccess}
            endorsement={endorsement}
            paramRefID={paramRefID}
            paramPolicyID={paramPolicyID}
            subcollection="drivers"
            tableDataRequest={() =>
              removeMultipleDriversTable(endorsement, "drivers", paramPolicyID)
            }
            columns={multipleDriverTableColumn}
            filterKeysToSearch={filterDriverKeysToSearch}
            requestExceptionInputs={requestExceptionInputs}
          />
        ) : null}
        {endorsement.type === "Remove Power Units" && (
          <RemoveMultiple
            setSuccess={setSuccess}
            endorsement={endorsement}
            paramRefID={paramRefID}
            paramPolicyID={paramPolicyID}
            subcollection="power-units"
            tableDataRequest={() =>
              removeMultiplePowerUnitsTable(
                endorsement,
                "power-units",
                paramPolicyID
              )
            }
            columns={multiplePowerUnitsTableColumn}
            filterKeysToSearch={filterPowerUnitsKeysToSearch}
            requestExceptionInputs={requestExceptionInputs}
          />
        )}
        {endorsement.type === "Remove Trailers" && (
          <RemoveMultiple
            setSuccess={setSuccess}
            endorsement={endorsement}
            paramRefID={paramRefID}
            paramPolicyID={paramPolicyID}
            subcollection="trailers"
            tableDataRequest={() =>
              removeMultipleTrailersTable(
                endorsement,
                "trailers",
                paramPolicyID
              )
            }
            columns={multipleTrailersTableColumn}
            filterKeysToSearch={filterTrailerKeysToSearch}
            requestExceptionInputs={requestExceptionInputs}
          />
        )}
        {endorsement.type === "Add Additional Insureds" && (
          <AdditionalInsured
            setSuccess={setSuccess}
            endorsement={endorsement}
            paramRefID={paramRefID}
            paramPolicyID={paramPolicyID}
            requestExceptionInputs={requestExceptionInputs}
          />
        )}
      </div>
      {success && (
        <>
          {(endorsement.status === "Approved" ||
          endorsement.status === "Issued") ? (
            <BigAlert
              title="This endorsement has been approved."
              subtitle="Click below to view other pending endorsements."
              okButton="OK"
              className="mt-2"
              status="successful"
              fn={handleGoBack}
            />
          ) : null}
          {endorsement.status === "Declined" &&
          endorsement.documentType !== 'Exception Request' ? (
            <BigAlert
              title="This endorsement has been declined."
              subtitle="Click below to view other pending endorsements."
              okButton="OK"
              className="mt-2"
              status="error"
              list={endorsement.rejectionReasons}
              fn={handleGoBack}
            />
          ) : null}
        </>
      )}
    </div>
  );
};
