import React, { useMemo, useState } from "react";
import {
  Box,
  useMediaQuery,
} from "@mui/material";
import PrintOptions from "./PrintOptions";
import ApprovalButtons from "../../assets/forms/ApprovalButtons";
import { isPastSomeMinutes } from "../../../utils/helpers";
import PdfGenerator from "../../generals/PdfGenerator";
import Popup from "../../generals/Popup";
import ShareModal from "../../ShareModal";
import { useLocation } from "react-router-dom";
import ApiService from "../../../services/southern-api/apiService";

const PrintOptionsModal = ({
  open,
  onClose,
  printPolicy,
  policiesPdf,
  percentage,
  insuredInfo,
  formData,
  userInfo,
  emailValue,
  setDidSuccess,
}) => {

  const {
    loading,
    cancelRequest,
    loadingCancel
  } = policiesPdf || {
    loading: false,
    url: '',
    error: false,
    cancelRequest: false,
    loadingCancel: false,
    dateWasCancel: null
  };

  const handleCancel = async () => {
    if (cancelRequest) {
      await cancelRequest();
      if (onClose) onClose();
    }
  };

  const isMobileScreen = useMediaQuery("(max-width: 600px)");

  const [printOptions, setPrintOptions] = useState({
    printDecPageOnly: false,
    printEntirePolicy: false,
    printMCS09: false,
  });

  const disabledAccept = useMemo(() => {
    return !Object.values(printOptions).some(option => option);
  }, [printOptions]);

  const getTrueOptionFromObject = (options) => {
    for (const option in options) {
      if (options[option] === true) {
        return option;
      }
    }
    return null;
  };

  const [openShareModal, setOpenShareModal] = useState(false)

  const location = useLocation()
  const isTemporalUrl = location?.pathname?.includes('temporarily')

  const apiService = new ApiService()
  const handleFinishOperation = async () => {
    try {
      await apiService.renewalProcessModuleUpdateConfirmation({
        policyData: formData,
        module: 'print-policy',
        emails: [emailValue],
      })
      setDidSuccess(true)
    } catch (error) {
      console.error(error)
    }
  }

  const handleSubmit = async (e) => {
    try {
      const option = getTrueOptionFromObject(printOptions);
      await printPolicy(e, option);
      await handleFinishOperation()
      if (onClose) onClose();
    } catch (error) {
      console.error(error);
    }
  };

  const Content = () => {
    return (
      <Box
        sx={{
          maxHeight: "100vh",
          overflowY: "auto",
          width: "100%",
          borderRadius: isMobileScreen ? 0 : 4,
        }}
      >
        <div className="mb-4">
          <PrintOptions
            printOptions={printOptions}
            setPrintOptions={setPrintOptions}
            userInfo={userInfo}
            setOpenShareModal={setOpenShareModal}
            handleFinishOperation={handleFinishOperation}
            handleCancel={handleCancel}
          />
        </div>

        {isPastSomeMinutes(policiesPdf?.dateWasCancel) && (
          <PdfGenerator percentage={percentage} policiesPdf={policiesPdf} />
        )}

        <ApprovalButtons
          onApprove={handleSubmit}
          onDecline={
            !loading ? onClose : handleCancel
          }
          titleAccept={"Print"}
          titleDecline={"Cancel"}
          disabledAccept={disabledAccept}
          manuallyTotalDeclineDisabled={loadingCancel}
          handleAsSubmit={false}
          disabledDecline={isTemporalUrl}
        />
      </Box>
    )
  }

  return (
    <>
      <ShareModal
        insuredInfo={insuredInfo}
        formData={formData}
        openShareModal={openShareModal}
        setOpenShareModal={setOpenShareModal}
        module={'print-policy'}
      />
      {!isTemporalUrl ?
        <Popup
          isOpen={open}
          onRequestClose={onClose}
          maxWidth={isMobileScreen ? "100%" : "400px"}
          contentLabel="Print Options"
          zIndex={999}
        >
          <Content />
        </Popup> : <Content />
      }
    </>
  );
};

export default PrintOptionsModal;
