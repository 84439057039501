import React, { useState } from "react";
import "../../styling/styles.css";
import AddInsured from "./SelectInsured";

export default function NewApplication({
  agencyID,
  setShowCreateApplicationPanel,
  setShowCreatePolicyPanel,
}) {
  const setShowCreatePanel = setShowCreatePolicyPanel
    ? setShowCreatePolicyPanel
    : setShowCreateApplicationPanel;

  const [showVerification, setShowVerification] = useState(false);
  const [showExistingPolicyModal, setShowExistingPolicyModal] = useState(false);

  return (
    <main className="d-flex justify-content-center">
      <div className="card mt-3 max-w-[500px]">
        <div className="card-body">
          <AddInsured
            // customText
            // customFunction
            setShowCreatePolicyPanel={setShowCreatePanel}
            agencyID={agencyID}
            showVerification={showVerification}
            setShowVerification={setShowVerification}
            showExistingPolicyModal={showExistingPolicyModal}
            setShowExistingPolicyModal={setShowExistingPolicyModal}
          />
        </div>
      </div>
    </main>
  );
}
