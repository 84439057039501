import axios from 'axios';
import getAgentRecipientList from './utils/getAgentRecipientList';
import getUnderwriterRecipientList from './utils/getUnderwriterRecipientList';
import config from '../../../config/env';

const policyStatusChangeNotification = async (
  documentType,
  policyNumber,
  newStatus,
  policyID,
  agencyID,
  formData,
  userAuth,
  userState,
  insuredInfo,
  action,
  email
) => {

    const { REACT_APP_SOUTHERN_BACKEND } = config
    let agentRecipientList = await getAgentRecipientList(
      agencyID,
      "policyNotificationRecipients"
    );
  
    let underwriterRecipientList = await getUnderwriterRecipientList(
      "policyNotificationRecipients"
    );
    const policyURL = window?.location?.href

    if (action === "sendQuote" && formData?.agentEmail) {
      agentRecipientList.push(formData?.agentEmail)
    }

    if (action === "sendQuote" && email) {
      agentRecipientList.push(email)
    }

    const request = {
        to: agentRecipientList,
        cc: underwriterRecipientList,
        status: newStatus,
        policyNumber,
        documentType,
        url: policyURL
    }

    if (formData && userAuth && userState && insuredInfo) {
        request.user = userAuth.displayName ? userAuth.displayName : `${userState.first}${userState.last ? ` ${userState.last}` : ''}`;
        request.namedInsured = insuredInfo.company;
        request.effectiveDate = formData.effectiveDate ? formData.effectiveDate.toDate().toLocaleDateString("en-US", { month: "short", day: "2-digit", year: "numeric" }) : '';
    }

    try {
        await axios.post(
          `${REACT_APP_SOUTHERN_BACKEND}/notify/policy-status-change`,
          request
        );
    } catch (error) {
        console.error('Error:', error);
    }
};

export default policyStatusChangeNotification;
