import { useState } from "react";
import ApiService from "../../services/southern-api/apiService";
import ApprovalButtons from "../assets/forms/ApprovalButtons";
import SingleCheckbox from "../assets/forms/SingleCheckbox";
import Popup from "../generals/Popup";
import useGeneralState from "../../Context/actions/useGeneralState";

const ShareModal = ({
    insuredInfo,
    formData,
    openShareModal,
    setOpenShareModal,
    module
}) => {
  const apiService = new ApiService();
  const { controlToast } = useGeneralState();
  const [shareOptions, setShareOptions] = useState({
    email: false,
    contactEmail: false,
    other: false,
    customEmail: "",
  });

  const handleShareChange = (name, value) => {
    const updatedOptions = {
      email: false,
      contactEmail: false,
      other: false,
      customEmail: "",
    };
    if (typeof value === "boolean") {
      Object.keys(shareOptions).forEach((key) => {
        if (key === "customEmail") {
          updatedOptions[key] = "";
        } else {
          updatedOptions[key] = false;
        }
      });
      updatedOptions[name] = value;
    } else {
      Object.keys(shareOptions).forEach((key) => {
        if (key === "customEmail") {
          updatedOptions[key] = value;
          updatedOptions["other"] = true;
        } else {
          updatedOptions[key] = false;
        }
      });
    }
    setShareOptions(updatedOptions);
  };

  const handleInsuredEditDrivers = async () => {
    try {
      const emails = [];

      if (
        !shareOptions["email"] &&
        !shareOptions["contactEmail"] &&
        !shareOptions["other"]
      ) {
        alert("Please select at least one option to continue.");
        return;
      }

      if (shareOptions["email"] && insuredInfo?.email) {
        emails.push(insuredInfo?.email);
      } else if (shareOptions["email"] && !insuredInfo?.email) {
        alert(
          "The insured does not have any company email address. Please provide one in the Edit Insured's Details to continue."
        );
        return;
      }

      if (shareOptions["contactEmail"] && insuredInfo?.contactEmail) {
        emails.push(insuredInfo?.contactEmail);
      } else if (shareOptions["contactEmail"] && !insuredInfo?.contactEmail) {
        alert(
          "The insured does not have any primary contact email. Please provide one in the Edit Insured's Details to continue."
        );
        return;
      }

      if (shareOptions["other"] && shareOptions["customEmail"]) {
        emails.push(shareOptions["customEmail"]);
      } else if (shareOptions["other"] && !shareOptions["customEmail"]) {
        alert("Please provide a custom email address to continue.");
        return;
      }

      const linkToRedirect = window?.location?.href
        ?.replace("policy", "temporarily")
        ?.replace("application", "temporarily");

      await apiService.renewalProcessModuleUpdate({
        link: linkToRedirect,
        policyData: formData,
        emails,
        module
      });
      controlToast(
        true,
        "The insured has been notified to update the drivers",
        "success"
      );
      setOpenShareModal(false);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <Popup
      maxWidth="50%"
      isOpen={openShareModal}
      onRequestClose={() => setOpenShareModal(false)}
      zIndex={10000}
    >
      <p className="text-center">Share to:</p>
      <SingleCheckbox
        name="email"
        label={`Company E-Mail Address ${
          insuredInfo?.email ? `(${insuredInfo?.email})` : "(not provided)"
        }`}
        checked={shareOptions["email"]}
        onChange={(e) => handleShareChange("email", e.target.checked)}
      />
      <SingleCheckbox
        name="contactEmail"
        label={`Primary Contact E-mail ${
          insuredInfo?.contactEmail
            ? `(${insuredInfo?.contactEmail})`
            : "(not provided)"
        }`}
        checked={shareOptions["contactEmail"]}
        onChange={(e) => handleShareChange("contactEmail", e.target.checked)}
      />
      <SingleCheckbox
        name="other"
        label="Other"
        checked={shareOptions["other"]}
        onChange={(e) => handleShareChange("other", e.target.checked)}
      />
      {shareOptions["other"] === true ? (
        <div className="mb-2">
          <label
            htmlFor="customEmail"
            className="block text-sm font-medium text-gray-600"
          >
            Custom Email Address
          </label>
          <input
            name="customEmail"
            type="email"
            value={shareOptions["customEmail"]}
            onChange={(e) => handleShareChange("customEmail", e.target.value)}
            className="mt-1 p-2 border rounded-md w-full focus:outline-none focus:ring focus:border-blue-300"
          />
        </div>
      ) : null}

      <ApprovalButtons
        onApprove={handleInsuredEditDrivers}
        onDecline={() => setOpenShareModal(false)}
        titleAccept="Send"
        titleDecline="Cancel"
      />
    </Popup>
  );
};

export default ShareModal;
