import React from "react";
import numberWithCommas from "../../utils/numberWithCommas";
import "../../styling/styles.css";
import { Container, Grid, Typography, Box, TextField } from "@mui/material";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";
import { t } from "i18next";
import { useTranslation } from "react-i18next";
import ExpandCollapseArrow from "../../generals/DesignElements/ExpandCollapseArrow";
import FileCopyIcon from "@mui/icons-material/FileCopy";
import IconButton from "@mui/material/IconButton";
import PersonIcon from "@mui/icons-material/Person";
import DirectionsBusIcon from "@mui/icons-material/DirectionsBus";
import Paper from "@mui/material/Paper";
import { styled } from "@mui/system";
import CountIcons from "./CountIcons";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import useGeneralState from "../../../Context/actions/useGeneralState";
import useFormDataState from "../../../Context/actions/useFormData";

export default function AppOverview(props) {
  const {
    value: selectedTab,
    trailerCount,
    userInfo,
    formData,
    powerUnitCount,
    driverCount,
    showOverviewAndHistory,
    setShowOverviewAndHistory,
    nextAL,
    nextGL,
  } = props;

  const { i18n } = useTranslation();
  const currentLanguage = i18n.language;

  const showPolicyNumbers =
    formData.newOrRenewal === "Renewal" ||
    (formData.alPolicyNo && formData.alPolicyNo !== "") ||
    (formData.glPolicyNo && formData.glPolicyNo !== "");

  const { formDataGL } = useFormDataState();

  const rows = [
    {
      label: "Gross Written Premium",
      value: `$${numberWithCommas(
        (
          (formData?.alPremiums?.GWP || 0) + (formData?.glPremiums?.GWP || 0)
        ).toFixed(2)
      )}`,
      condition:
        formData?.hasALCoverage &&
        (formData?.alPremiums?.GWP || 0) + (formData?.glPremiums?.GWP || 0) > 0,
      copyValue: `${
        (formData?.alPremiums?.GWP || 0) + (formData?.glPremiums?.GWP || 0)
      }`,
    },
    {
      label: "Total Premium",
      value: `$${numberWithCommas(
        (
          (formData?.alPremiums?.totalPremium || 0) +
          (formData?.glPremiums?.totalPremium || 0)
        ).toFixed(2)
      )}`,
      condition:
        formData?.hasGLCoverage &&
        (formData?.alPremiums?.totalPremium || 0) +
          (formData?.glPremiums?.totalPremium || 0) >
          0,
      copyValue: `${
        (formData?.alPremiums?.totalPremium || 0) +
        (formData?.glPremiums?.totalPremium || 0)
      }`,
    },
    {
      label:
        formData?.documentType !== "Policy" ? "Next AL" : "AL Policy Number",
      value:
        formData?.documentType !== "Policy" ? nextAL : formData?.alPolicyNo,
      condition: formData?.hasALCoverage && showPolicyNumbers,
      copyValue: formData?.alPolicyNo,
    },
    {
      label:
        formDataGL?.documentType !== "Policy" ? "Next GL" : "GL Policy Number",
      value:
        formDataGL?.documentType !== "Policy"
          ? nextGL
          : formDataGL?.policyNumber,
      condition: formDataGL?.hasGLCoverage && showPolicyNumbers,
      copyValue: formDataGL?.policyNumber,
    },
    {
      label: "Total Insured Value",
      value: "$" + numberWithCommas(formData?.TIV),
      condition:
        formData?.documentType !== "Application" &&
        formData?.TIV &&
        formData?.TIV > 0,
      copyValue: formData?.TIV,
    },
  ];

  // Filter rows based on the conditions
  const filteredRows = rows.filter((row) => row.condition);

  const { controlToast } = useGeneralState();

  return (
    <section className="card h-100 shadow-md">
      <div className="card-header text-lg text-white bg-gradient-to-r from-[#0c4a6e] to-[#096384]">
        <button
          onClick={(e) => setShowOverviewAndHistory(!showOverviewAndHistory)}
        >
          {currentLanguage === "en" && (
            <>{t(formData?.documentType)} Overview</>
          )}
          {currentLanguage === "es" && (
            <>{"Detalles de la " + t(formData?.documentType)}</>
          )}
        </button>

        <ExpandCollapseArrow
          show={showOverviewAndHistory}
          setShow={setShowOverviewAndHistory}
        />
      </div>
      {showOverviewAndHistory && (
        <div className="card-body">
          <CountIcons
            driverCount={driverCount}
            powerUnitCount={powerUnitCount}
            trailerCount={trailerCount}
          />
          <Box className="mb-2 mt-3">
            <TableContainer>
              <Table sx={{ borderCollapse: "collapse" }}>
                <TableBody>
                  {filteredRows.map(
                    (row, index) =>
                      row.condition && (
                        <TableRow
                          key={index}
                          sx={{
                            "& .MuiTableCell-root": {
                              padding: "4px 8px", // Reduced padding in rows for compactness
                            },
                          }}
                        >
                          <TableCell>
                            <Typography variant="body2">
                              {t(row.label)}
                            </Typography>
                          </TableCell>
                          <TableCell>
                            <Box sx={{ display: "flex", alignItems: "center" }}>
                              <Typography
                                variant="body2"
                                sx={{ fontWeight: 600, marginRight: "8px" }}
                              >
                                {row.value}
                              </Typography>
                              <IconButton
                                onClick={() => {
                                  navigator.clipboard.writeText(row.copyValue);
                                  controlToast(
                                    true,
                                    `${row.label} was copied to clipboard`,
                                    "success"
                                  );
                                }}
                                sx={{
                                  padding: "4px", // Reducing button padding
                                  margin: "0", // Eliminating extra margin
                                }}
                              >
                                <FileCopyIcon sx={{ fontSize: "16px" }} />
                              </IconButton>
                            </Box>
                          </TableCell>
                        </TableRow>
                      )
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        </div>
      )}
    </section>
  );
}
