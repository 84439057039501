import React, { useState } from "react";
import renewPolicy from "../../utils/renewPolicy";
import { Typography } from "@mui/material";
import ApprovalButtons from "../../assets/forms/ApprovalButtons";
import { UserAuth } from "../../../Context/AuthContent";
import useUsersState from "../../../Context/actions/useUsersState";

export default function Renew({ policyID, onClose, setRenew, insuredInfo, formData }) {

  const { user: userAuth } = UserAuth()
  const { user: userState } = useUsersState()

  const [loading, setLoading] = useState(false);

  const handleRenew = async (e) => {
    try {
      setLoading(true);
      await renewPolicy(e, policyID, onClose, formData, userAuth, userState, insuredInfo);
      onClose()
      setRenew(false);
      setLoading(false);
    } catch (error) {
      console.error(error)
    }
  }

  return (
    <>
      <Typography className="mt-2" variant="h6" gutterBottom>
        Policy Renewal
      </Typography>
      <p className="mb-2">
        Proceeding will create a duplicate application of this policy.
      </p>
      <p className="mb-3">
        Changes made on an In-Renewal Application will reflect on the policy
        (and visa versa) and as such may require the approval of an underwriter.
      </p>

      <ApprovalButtons
        onApprove={handleRenew}
        onDecline={e => {setRenew(false); onClose(e);}}
        titleAccept={"Continue"}
        titleDecline={"Cancel"}
        disabledDecline={false}
        handleAsSubmit={false}
        forceLoadingAccept={loading}
      />
    </>
  );
}
