import React, { useState } from "react";
import {
  Typography,
  Box,
  useMediaQuery,
} from "@mui/material";
import ApprovalButtons from "../../assets/forms/ApprovalButtons";
import Breadcrumbs from "../../assets/Breadcrumbs";
import { UserAuth } from "../../../Context/AuthContent";
import useUsersState from "../../../Context/actions/useUsersState";
import sendQuote from "../../utils/sendQuote";
import Popup from "../../generals/Popup";

const SendQuoteModal = ({ open, onClose, policyID, formData, insuredInfo }) => {
  const { user: userAuth } = UserAuth();
  const { user: userState } = useUsersState();

  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);

  const handleSubmit = async (e) => {
    try {
      setLoading(true);
      await sendQuote(e, formData, policyID, userAuth, userState, insuredInfo, email);
      setLoading(false);
      onClose();
    } catch (error) {
      setLoading(false);
    }
  };

  const isMobileScreen = useMediaQuery("(max-width: 600px)");

  const breadcrumbs = [
    { label: "Policy" },
    { label: "Quote Request" },
    { label: "Send Quote" },
  ];

  return (
    <Popup
      isOpen={open}
      onRequestClose={onClose}
      maxWidth={isMobileScreen ? "100%" : "400px"}
      zIndex={1000}
      contentLabel="Send Quote"
    >
      <form onSubmit={handleSubmit}>
        <Box
          sx={{
            maxHeight: "100vh",
            overflowY: "auto",
            width: "100%",
            borderRadius: isMobileScreen ? 0 : 4,
          }}
        >
          <Breadcrumbs items={breadcrumbs} />
          <Typography className="mt-2" variant="h6" gutterBottom>
            Send Quote
          </Typography>
          <p className="my-3">Confirm the fulfillment of your quote.</p>
          <div className="mb-2">
            <label
              htmlFor="extraEmailToSend"
              className="block text-sm font-medium text-gray-600"
            >
              If you need it, send the quote to:
            </label>
            <input
              name="extraEmailToSend"
              type="text"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              className="mt-1 p-2 border rounded-md w-full focus:outline-none"
            />
          </div>
          <ApprovalButtons
            onApprove={null}
            onDecline={onClose}
            forceLoadingAccept={loading}
            titleAccept={"Submit"}
            titleDecline={"Cancel"}
            disabledAccept={false}
            disabledDecline={false}
            handleAsSubmit={true}
          />
        </Box>
      </form>
    </Popup>
  );
};

export default SendQuoteModal;
