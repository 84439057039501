// TODO: Currently you cannot change the commodities when the doucment type = "Policy". We need to change this and build an endorsement workflow for the adjusting of commodities.
import React, { useEffect, useState } from "react";
import LoadingScreen from "react-loading-screen";
import updatePolicyField from "../../utils/updatePolicyField";
import { t } from "i18next";
import Breadcrumbs from "../../assets/Breadcrumbs";
import StandardCommodity from "./StandardCommodity";
import CustomCommodity from "./CustomCommodity";
import commodityData, { commodityImportData } from "./utils/commodityData";
import handleAddCommodity from "./utils/handleAddCommodity";
import DragDropImportList from "../../assets/forms/DragDropFileUpload/DragDropImportList";
import useGeneralState from "../../../Context/actions/useGeneralState";
import Popup from "../../generals/Popup";
import BlueButton from "../../assets/forms/BlueButton";
import { Tooltip, useMediaQuery } from "@mui/material";
import { IconButton, MenuItem, Select } from "@mui/material";
import {
  ViewComfy as ViewComfyIcon,
  IosShare as IosShareIcon,
} from "@mui/icons-material";
import "../../styling/styles.css";
import ApiService from "../../../services/southern-api/apiService";
import ShareModal from "../../ShareModal";
import { useLocation } from "react-router-dom";

export default function Commodities({
  formData: generalFormData,
  policyID,
  userInfo,
  setDidSuccess,
  emailValue,
  insuredInfo,
}) {
  const getModuleValueFromCurrentUrl = () => {
    const url = window.location.href;
    const urlObject = new URL(url);
    return urlObject.searchParams.get("module");
  };
  const moduleValue = getModuleValueFromCurrentUrl();

  const [file, setFile] = useState(null);
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [columns, setColumns] = useState(3);
  const [formData, setFormData] = useState(generalFormData);

  const data = Boolean(moduleValue) ? formData : generalFormData;

  const isMobileScreen = useMediaQuery("(max-width: 600px)");

  const handleColumnChange = (event) => {
    setColumns(event.target.value);
  };

  const [didFormData, setDidFormData] = useState(false);

  useEffect(() => {
    if (generalFormData && !didFormData) {
      setFormData(generalFormData);
      setDidFormData(true);
    }
  }, [data]);

  useEffect(() => {
    if (!data.commodityFields || data.commodityFields.length === 0) {
      updatePolicyField(
        policyID,
        "commodityFields",
        [
          {
            id: 1,
            commodityDescription: "",
            commodityLoad: "0",
          },
        ],
        data,
        setFormData
      );
    }
  }, [data.commodityFields]);

  const breadcrumbs = [{ label: data.documentType }, { label: "Commodities" }];

  const { controlToast } = useGeneralState();
  const [openShareModal, setOpenShareModal] = useState(false);
  const location = useLocation();
  const isTemporalUrl = location?.pathname?.includes("temporarily");
  const isTemporalUrlClient = location?.pathname?.includes("temporarily-client");
  const apiService = new ApiService();
  const handleFinishOperation = async () => {
    try {
      await apiService.renewalProcessModuleUpdateConfirmation({
        policyData: data,
        module: "commodities",
        emails: [emailValue],
      });
      setDidSuccess(true);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div>
      <ShareModal
        insuredInfo={insuredInfo}
        formData={data}
        openShareModal={openShareModal}
        setOpenShareModal={setOpenShareModal}
        module={"commodities"}
      />
      <Popup
        isOpen={open}
        onRequestClose={() => {
          if (!loading) {
            setOpen(false);
          }
        }}
        maxWidth="100%"
      >
        <div className="h-[300px] flex justify-center items-center">
          <LoadingScreen
            loading={loading}
            bgColor="#f1f1f1"
            spinnerColor="#9ee5f8"
            textColor="#676767"
            padding="0"
            logoSrc=""
            text={`Printing... Don't leave the screen`}
          >
            <p className="text-2xl mt-2 font-semibold mb-3">
              Import your xlsx with Commodities
            </p>
            <DragDropImportList
              file={file}
              setFile={setFile}
              handleUpload={async (fileObj) => {
                await commodityImportData(
                  fileObj,
                  policyID,
                  controlToast,
                  setLoading,
                  data
                );
                setOpen(false);
              }}
              fileTypes={["XLSX"]}
            />
          </LoadingScreen>
        </div>
      </Popup>
      <Breadcrumbs items={breadcrumbs} />
      <div>
        <h1 className="text-3xl mt-2 font-semibold mb-3">
          {t("Commodities")}{" "}
          {data.commodityTotal !== 100 && (
            <Tooltip
              title={
                t("Commodities must equal 100% and currently equal") +
                " " +
                data.commodityTotal +
                "%"
              }
            >
              <button className="text-[#8B0000]">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="20"
                  fill="currentColor"
                  className="bi bi-flag-fill d-inline mr-1"
                  viewBox="0 0 16 16"
                >
                  <path d="M14.778.085A.5.5 0 0 1 15 .5V8a.5.5 0 0 1-.314.464L14.5 8l.186.464-.003.001-.006.003-.023.009a12.435 12.435 0 0 1-.397.15c-.264.095-.631.223-1.047.35-.816.252-1.879.523-2.71.523-.847 0-1.548-.28-2.158-.525l-.028-.01C7.68 8.71 7.14 8.5 6.5 8.5c-.7 0-1.638.23-2.437.477A19.626 19.626 0 0 0 3 9.342V15.5a.5.5 0 0 1-1 0V.5a.5.5 0 0 1 1 0v.282c.226-.079.496-.17.79-.26C4.606.272 5.67 0 6.5 0c.84 0 1.524.277 2.121.519l.043.018C9.286.788 9.828 1 10.5 1c.7 0 1.638-.23 2.437-.477a19.587 19.587 0 0 0 1.349-.476l.019-.007.004-.002h.001" />
                </svg>
              </button>
            </Tooltip>
          )}
        </h1>
        {/* Commodities List */}
        {data?.commodityFields && (
          <div className="container mx-auto mt-1 p-8 bg-white rounded shadow-lg">
            <fieldset
              className="mb-8"
              disabled={data.documentType !== "Application"}
            >
              <p className="mb-2">
                {t(
                  "Please indicate what percentage of hauled loads consist of the following commodities."
                )}
              </p>
              {isTemporalUrl && (
                <p className="mb-2">
                  {/* TODO: Translate this line */}
                  {t(
                    `Once you have made all necessary changes, please click the
                    'Submit Changes' button below.`
                  )}
                </p>
              )}
              <div className="mb-3">
                <div className="flex flex-wrap items-center justify-between mb-4">
                  <div className="flex items-center space-x-2">
                    {isTemporalUrl && !isTemporalUrlClient ? (
                      <BlueButton
                        text="Submit Changes"
                        onClickFunction={handleFinishOperation}
                        hasContentToBottom={true}
                        hasContentToRight={!isMobileScreen}
                        className={isMobileScreen ? "w-[100%]" : ""}
                        disabled={data.commodityTotal !== 100}
                        icon="no-icon"
                      />
                    ) : null}
                    {!isTemporalUrl ? (
                      <>
                        <BlueButton
                          text="Import"
                          onClickFunction={() => setOpen(true)}
                          hasContentToBottom={true}
                          hasContentToTop={true}
                          hasContentToRight={!isMobileScreen}
                        />
                        {userInfo?.role === "Agent" ||
                        userInfo?.role === "Admin" ||
                        userInfo?.role === "Underwriter" ? (
                          <BlueButton
                            text="SHARE"
                            hasContentToBottom={true}
                            hasContentToTop={true}
                            hasContentToRight={!isMobileScreen}
                            onClickFunction={() => {
                              console.log('click in share modal')
                              setOpenShareModal(true)
                            }}
                            icon={<IosShareIcon />}
                            className={isMobileScreen ? "w-[100%]" : ""}
                          />
                        ) : null}
                      </>
                    ) : null}
                  </div>

                  <div className="flex items-center space-x-2 ml-auto">
                    <span className="text-md">Manage Columns:</span>
                    <IconButton>
                      <ViewComfyIcon fontSize="large" />
                    </IconButton>
                    <Select
                      value={columns}
                      onChange={handleColumnChange}
                      displayEmpty
                      variant="outlined"
                      size="small"
                      className="w-20"
                    >
                      <MenuItem value={1}>1 Column</MenuItem>
                      <MenuItem value={2}>2 Columns</MenuItem>
                      <MenuItem value={3}>3 Columns</MenuItem>
                    </Select>
                  </div>
                </div>
                {data.documentType === "Policy" && (
                  <>
                    {t(
                      "Policy commodities may only be changed by endorsement. To change the commodities, please visit the endorsements panel."
                    )}
                  </>
                )}
                {data.documentType !== "Application" &&
                  data.documentType !== "Policy" && (
                    <>Commodities may not be edited during the quoting phase.</>
                  )}
              </div>

              <div className="mb-8">
                <div
                  className={`grid grid-cols-1 sm:grid-cols-${columns} lg:grid-cols-${columns} gap-4`}
                >
                  {commodityData.map((commodity, index) => (
                    <div
                      key={index}
                      className="p-4 border rounded-lg shadow-md bg-white"
                    >
                      <StandardCommodity
                        label={commodity.label}
                        property={commodity.property}
                        formData={data}
                        defaultValue={commodity.defaultValue}
                        marks={commodity.marks}
                        max={commodity.max}
                        min={commodity.min}
                        value={commodity.value}
                        hasDetails={commodity.hasDetails}
                        disabledSlider={commodity.disabledSlider}
                        policyID={policyID}
                        setFormData={setFormData}
                      />
                    </div>
                  ))}
                </div>
              </div>

              <h1 className="text-2xl font-bold mb-2">
                {t("Custom Commodities")}
              </h1>

              <p className="mb-2 text-gray-600">
                {t(
                  "Here you must add any commodities hauled but not listed above."
                )}
              </p>

              {data.commodityFields.map((field, index) => (
                <CustomCommodity
                  formData={data}
                  setFormData={setFormData}
                  field={field}
                  index={index}
                />
              ))}

              {data.documentType === "Application" && (
                <button
                  className="text-blue-500 hover:text-blue-700"
                  onClick={(e) =>
                    handleAddCommodity(data, policyID, setFormData)
                  }
                >
                  {t("Add a Commodity")}
                </button>
              )}
            </fieldset>

            {data.commodityTotal !== 100 && (
              <p className="text-[#8B0000] font-bold">
                {t(
                  "The total of commodities must equal 100% and it currently equals"
                )}{" "}
                {data.commodityTotal || 0}%
              </p>
            )}
          </div>
        )}
      </div>
    </div>
  );
}
