import {
  arrayUnion,
  doc,
  getDoc,
  runTransaction,
} from "firebase/firestore";
import { auth, db } from "../../firebase";
import getUniqueIdentifier from "./getUniqueIdentifier";
import findNextPolicyNumbers from "./findNextPolicyNumbers";
import policyStatusChangeNotification from "./notifications/policyStatusChangeNotification";
import getFleetCredit from "./getFleetCredit";
import updateSystemLog from "./updateSystemLog";

const bindPolicy = async (
  formData,
  policyID,
  powerUnitCount,
  driverCount,
  trailerCount,
  userInfo,
  insuredInfo,
  nextAL,
  nextGL,
  insuredID,
  userAuth,
  userState,
  formDataGL
) => {

  const agencyID = formData.agencyID;
  const policyRef = doc(db, "policy-applications", policyID);
  const insuredRef = doc(
    db,
    "agencies",
    userInfo.agencyID,
    "insureds",
    insuredInfo.id
  );

  const thisPolicyIsDomestic = formData.program === "Domestic";
  const thisPolicyIsNAFTA = formData.program === "NAFTA";
  const hasNAFTAALUniqueIdentifier =
    insuredInfo.uniqueIdentifierNAFTAAL &&
    insuredInfo.uniqueIdentifierNAFTAAL !== null;
  const hasDomesticALUniqueIdentifier =
    insuredInfo.uniqueIdentifierDomesticAL &&
    insuredInfo.uniqueIdentifierDomesticAL !== null;
  const hasNAFTAGLUniqueIdentifier =
    insuredInfo.uniqueIdentifierNAFTAGL &&
    insuredInfo.uniqueIdentifierNAFTAGL !== null;
  const hasDomesticGLUniqueIdentifier =
    insuredInfo.uniqueIdentifierDomesticGL &&
    insuredInfo.uniqueIdentifierDomesticGL !== null;
  let useNewIdentifier = false;
  let uniqueIdentifierAL;
  let uniqueIdentifierGL;
  let newALPolicyNumber;
  let newGLPolicyNumber;

  if (thisPolicyIsNAFTA) {
    if (!hasNAFTAALUniqueIdentifier) {
      useNewIdentifier = true;
      uniqueIdentifierAL = await getUniqueIdentifier(
        formData.program,
        insuredID,
        "AL",
        agencyID
      );
    } else {
      uniqueIdentifierAL = insuredInfo.uniqueIdentifierNAFTAAL;
    }

    if (formData.hasGLCoverage && !hasNAFTAGLUniqueIdentifier) {
      useNewIdentifier = true;
      uniqueIdentifierGL = await getUniqueIdentifier(
        formData.program,
        insuredID,
        "GL",
        agencyID
      );
    } else if (formData.hasGLCoverage && hasNAFTAGLUniqueIdentifier) {
      uniqueIdentifierGL = insuredInfo.uniqueIdentifierNAFTAGL;
    }
  } else if (thisPolicyIsDomestic) {
    if (!hasDomesticALUniqueIdentifier) {
      useNewIdentifier = true;
      uniqueIdentifierAL = await getUniqueIdentifier(
        formData.program,
        insuredID,
        "AL",
        agencyID
      );
    } else {
      uniqueIdentifierAL = insuredInfo.uniqueIdentifierDomesticAL;
    }
    if (formDataGL.hasGLCoverage && !hasDomesticGLUniqueIdentifier) {
      useNewIdentifier = true;
      uniqueIdentifierGL = await getUniqueIdentifier(
        formDataGL.program,
        insuredID,
        "GL",
        agencyID
      );
    } else if (formData.hasGLCoverage && hasDomesticGLUniqueIdentifier) {
      uniqueIdentifierGL = insuredInfo.uniqueIdentifierDomesticGL;
    }
  }

  // Use the newly acquired Unique Identifiers to produce entire policy numbers
  const policyNumberResults = await findNextPolicyNumbers(
    formData,
    insuredInfo,
    uniqueIdentifierAL,
    uniqueIdentifierGL,
    null,
    null
  );

  newALPolicyNumber = policyNumberResults.nextAL;
  newGLPolicyNumber = policyNumberResults.nextGL;
  const fleetCredit = await getFleetCredit(powerUnitCount);

  // If the quote has GL, add that policy along with the AL Policy.
  const bindData = {
    powerUnitCountAtBind: powerUnitCount,
    bindDate: new Date(),
    boundBy: auth.currentUser.displayName,
    boundByID: auth.currentUser.uid,
    powerUnitCount,
    driverCount,
    trailerCount,
    fleetCredit,
    boundPremiums: {
      autoLiability: formData.alPremiums || "",
      generalLiability: formData.glPremiums || "",
    },
    coverages: {
      hasALCoverage: formData.hasALCoverage || false,
      hasGLCoverage: formData.hasGLCoverage || false,
      hasNonOwned: formData.hasNonOwned || false,
    },
    additionalInfo: {
      hasMCP65: formData.hasMCP65 || false,
      hasFormE: formData.hasFormE || false,
      has91X: formData.has91X || false,
    },
  };

  const glPolicyNo = useNewIdentifier ? newGLPolicyNumber : nextGL;
  const alPolicyNo = useNewIdentifier ? newALPolicyNumber : nextAL;

  await runTransaction(db, async (transaction) => {
    const sourceDocRef = doc(db, "policy-applications", policyID);
    const sourceDocSnapshot = await getDoc(sourceDocRef);
    const documentType = "Policy";
    const status = "Bound";
    const policyNumber = sourceDocSnapshot.data().alPolicyNo || "";
    const agencyID = sourceDocSnapshot.data().agencyID;

    const policyGLRef = doc(db, "gl-policies", formDataGL.id);

    const rootLevelData = {
      status,
      documentType,
      inRenewal: false,
      bindData,
    };
    const glPolicyData = {
      effectiveDate: formDataGL.effectiveDate,
      program: formData.program,
      product: formData.product,
      policyID,
      policyNumber: glPolicyNo,
      bindData,
    };
    const alPolicyData = {
      effectiveDate: formData.effectiveDate,
      program: formData.program,
      product: formData.product,
      coverageSingleLimit: formData.coverageSingleLimit,
      policyID,
      policyNumber: alPolicyNo,
      bindData,
    };

    if (formDataGL.hasGLCoverage) {
      if (formDataGL.id) {
        transaction.update(policyGLRef, {
          ...glPolicyData,
          ...rootLevelData,
          glPolicyNo,
          alPolicyNo,
        });
      }
      transaction.update(insuredRef, {
        alPolicies: arrayUnion(alPolicyData),
        glPolicies: arrayUnion(glPolicyData),
      });
      transaction.update(policyRef, {
        ...rootLevelData,
        glPolicyNo,
        alPolicyNo,
      });
    } else {
      transaction.update(insuredRef, {
        alPolicies: arrayUnion(alPolicyData),
      });
      transaction.update(policyRef, { ...rootLevelData, alPolicyNo });
    }
    policyStatusChangeNotification(
      documentType,
      policyNumber,
      status,
      policyID,
      agencyID,
      formData,
      userAuth,
      userState,
      insuredInfo
    );
    updateSystemLog(
      auth,
      policyID,
      "Policy Bound for " + insuredInfo.company + ".",
      "Policy Bound",
      { agencyID, policyID }
    );
  });
};

export default bindPolicy;