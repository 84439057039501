import { parse } from "uuid";
import XLSX from "xlsx-js-style";

export const generateXlsxFile = async (list, sheetName, sheetFileName) => {
  const listParsed = list.map((item) => {
    if (item.status) delete item.status;
    let policies = "";
    if (item.policies) policies = item.policies.join(", ");
    let policiesDetails = "";
    if (item.policiesDetails)
      policiesDetails = JSON.stringify(item?.policiesDetails);
    return {
      ...item,
      policies,
      policiesDetails,
    };
  });
  try {
    const workbook = XLSX.utils.book_new();
    const worksheet = XLSX.utils.json_to_sheet(listParsed);
    XLSX.utils.book_append_sheet(workbook, worksheet, sheetName);
    XLSX.writeFile(workbook, sheetFileName, { type: "buffer" });
  } catch (error) {
    throw error;
  }
};

const powerUnitsData = [
  ["Power Units List"], [],
  ["Please input the data for the power units below. Our API will retrieve the rest of the critical vehicle information such as make, model and weight."],
  [], [],
  ["If you have any issues uploading the list to the Southern Star Portal, please submit a support ticket at https://portal.southernstarmga.com/new-support-ticket"],
  [], [],
  [
    "VIN",
    "Year",
    "Radius",
    "Actual Cash Value",
  ],
  [
    "4V4NC9EJ6EN154794",
    "2014",
    "0-1500",
    "32000",
  ],
];

const driversData = [
  ["Drivers List"], [],
  ["Please input the data for the drivers below."], [],
  ["If you have any issues uploading the list to the Southern Star Portal, please submit a support ticket at https://portal.southernstarmga.com/new-support-ticket"],
  [],
  [
    "First Name",
    "Last Name(s)",
    "DOB",
    "Country",
    "State (If USA)",
    "License No.",
    "NMP (If MX)",
    "License Expiration Date",
    "YOE"
  ],
  [
    "JOSE EDGARDO",
    "LOPEZ-PEREZ",
    "10/8/1974",
    "MX",
    "AZ",
    "3184108",
    "62396",
    "2/2/2024",
    "5",
  ],
];

const trailersData = [
  ["Trailers List"], [],
  ["Please input the data for the trailers below."], [],
  ["If you have any issues uploading the list to the Southern Star Portal, please submit a support ticket at https://portal.southernstarmga.com/new-support-ticket"],
  [],
  ["VIN", "Make", "Year", "Actual Cash Value"],
  ["1DW1A53205S790322", "STOUGHTON", "2005", "8000"],
];

const sheetNames = ["Power Units", "Drivers", "Trailers"];

const driverStyles = {
  rectangle: ["A1", "J3"],
  width: ["A1", "J1", 130],
  titles: ["B4", "J4"],
};

const powerUnitsStyles = {
  rectangle: ["A1", "I3"],
  width: ["A1", "I1", 130],
  titles: ["B4", "I4"],
};

const trailerStyles = {
  rectangle: ["A1", "E3"],
  width: ["A1", "E1", 130],
  titles: ["B4", "E4"],
};

const generateRectangle = (startCell, endCell) => {
  const startCol = XLSX.utils.decode_cell(startCell).c;
  const startRow = XLSX.utils.decode_cell(startCell).r;
  const endCol = XLSX.utils.decode_cell(endCell).c;
  const endRow = XLSX.utils.decode_cell(endCell).r;

  const rectangle = [];
  for (let i = startRow; i <= endRow; i++) {
    for (let j = startCol; j <= endCol; j++) {
      rectangle.push(XLSX.utils.encode_cell({ r: i, c: j }));
    }
  }
  return rectangle;
};

const setColumnWidth = (worksheet, startCell, endCell, width) => {
  const startCol = XLSX.utils.decode_cell(startCell).c;
  const endCol = XLSX.utils.decode_cell(endCell).c;

  if (!worksheet["!cols"]) {
    worksheet["!cols"] = [];
  }

  for (let col = startCol; col <= endCol; col++) {
    if (!worksheet["!cols"][col]) {
      worksheet["!cols"][col] = { wpx: width };
    } else {
      worksheet["!cols"][col].wpx = width;
    }
  }
};

const detectDataType = (value) => {
  const valueToUse = String(value)
  const cleanedValue = valueToUse.replace(/[$,]/g, '');
  const isNumber = !isNaN(parseFloat(cleanedValue)) && isFinite(cleanedValue);
  const isPrice = valueToUse.includes('$') && isNumber;
  const isDate = !isNaN(Date.parse(valueToUse)) && !isNumber;
  let type;
  let formattedValue;
  if (isDate) {
    type = 'date';
    formattedValue = new Date(valueToUse);
  } else if (isPrice) {
    type = 'price';
    formattedValue = parseFloat(cleanedValue);
  } else if (isNumber) {
    type = 'number';
    formattedValue = parseFloat(cleanedValue);
  } else if (valueToUse !== '-' && valueToUse !== '' && valueToUse !== 'undefined') {
    type = 'string';
    formattedValue = String(valueToUse);
  } else {
    type = '';
    formattedValue = valueToUse;
  }
  return {
    type,
    value: formattedValue
  };
};

export const generateMultiSheetXlsxFile = async (
  sheetData,
  sheetNames,
  sheetFileName,
  sheetStyles,
  startFromRow = 0,
  allowDetectDataType = false
) => {
  try {
    const workbook = XLSX.utils.book_new();
    sheetData.forEach((data, index) => {
      const worksheet = XLSX.utils.aoa_to_sheet(data);
      const workStyles = sheetStyles[index];

      const range = XLSX.utils.decode_range(worksheet["!ref"]);
      for (let R = range.s.r; R <= range.e.r; ++R) {
        for (let C = range.s.c; C <= range.e.c; ++C) {
          const cellAddress = { r: R, c: C };
          const cellRef = XLSX.utils.encode_cell(cellAddress);
          if (!worksheet[cellRef]) continue;
          if (allowDetectDataType) {
            const {
              type: valueType,
              value: formattedValue
            } = detectDataType(worksheet?.[cellRef]?.v)
            if (
              valueType === 'date'
            ) {
              worksheet[cellRef].v = formattedValue
              worksheet[cellRef].t = "d";
              worksheet[cellRef].z = "mm/dd/yyyy";
            } else if (
              valueType === 'price'
            ) {
              worksheet[cellRef].v = formattedValue;
              worksheet[cellRef].t = "n";
              worksheet[cellRef].z = "0.00";
            } else if (
              valueType === 'number'
            ) {
              worksheet[cellRef].v = formattedValue;
              worksheet[cellRef].t = "n";
              worksheet[cellRef].z = "0";
            } else if (
              valueType === 'string'
            ) {
              worksheet[cellRef].v = formattedValue
              worksheet[cellRef].t = "s";
              worksheet[cellRef].z = "@";
            } else {
              worksheet[cellRef].v = ''
            }
          } else {
            if (!worksheet[cellRef].t) worksheet[cellRef].t = "s";
            if (!worksheet[cellRef].z) worksheet[cellRef].z = "@";
          }
        }
      }

      if (data.length > 0 && data[0].length > 0) {
        data[0].forEach((title, colIndex) => {
          worksheet[XLSX.utils.encode_cell({ r: startFromRow, c: colIndex })] = {
            v: title,
            t: "s",
            z: "@",
          };
        });
      }

      if (workStyles?.rectangle) {
        const backgroundCols = generateRectangle(...workStyles.rectangle);
        for (const backgroundCol of backgroundCols) {
          if (worksheet[backgroundCol]) {
            worksheet[backgroundCol].s = {
              fill: { fgColor: { rgb: "FFFFFF" } },
            };
          }
        }
      }

      if (workStyles?.titles) {
        const titleCols = generateRectangle(...workStyles.titles);
        for (const titleCol of titleCols) {
          if (worksheet[titleCol]) {
            worksheet[titleCol].s = {
              fill: { fgColor: { rgb: "000000" } },
              font: { color: { rgb: "FFFFFF" } },
            };
          }
        }
      }

      if (workStyles?.width) {
        setColumnWidth(
          worksheet,
          workStyles.width[0],
          workStyles.width[1],
          workStyles.width[2]
        );
      }

      XLSX.utils.book_append_sheet(workbook, worksheet, sheetNames[index]);
    });
    XLSX.writeFile(workbook, sheetFileName, { type: "buffer" });
  } catch (error) {
    throw error;
  }
};

export const generateEmptyFile = () => {
  if (typeof window !== 'undefined') {
    const link = document.createElement('a');
    link.href = '/assets/templates/Import-Template.xlsx';
    link.download = 'Import-Template.xlsx';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }
};