import React, { useEffect, useState } from "react";
import { t } from "i18next";
import DatePicker from "react-datepicker";
import submitAddTrailerEndorsement from "./utils/submitAddTrailerEndorsement";
import Breadcrumbs from "../../assets/Breadcrumbs";
import ApprovalButtons from "../../assets/forms/ApprovalButtons";
import useGeneralState from "../../../Context/actions/useGeneralState";
import useUsersState from "../../../Context/actions/useUsersState";
import { validateIfExists } from "../../utils/validateIfBlacklisted";
import config from "../../../config/env";
import BlueButton from "../../assets/forms/BlueButton";
import { useMediaQuery } from "@mui/material";
import FormPopup from "../../generals/Popup/FormPopup";
import { collection, doc, onSnapshot, query, setDoc } from "firebase/firestore";
import { db } from "../../../firebase";
import { useLocation } from "react-router-dom";
import "react-datepicker/dist/react-datepicker.css";
import ApiService from "../../../services/southern-api/apiService";

export default function AddTrailer(props) {
  const { REACT_APP_LICONA_INSURANCE_GROUP_ID } = config;
  const formData = props.formData;
  const isEndoManager = props.isEndoManager;
  const policyID = props.policyID;
  const trailerList = props.trailerList;
  const { user } = useUsersState();
  const location = useLocation()
  const isTemporalUrl = location?.pathname?.includes('temporarily')
  const isTemporalUrlClient = location?.pathname?.includes("temporarily-client");

  const [trailerData, setTrailerData] = useState({});
  const { controlToast } = useGeneralState();

  const goBack = () => {
    props.setShowAddTrailer(false);
    props.setShowTable(true);
  };

  const breadcrumbs = [
    { label: formData.documentType },
    { label: "Trailers" },
    { label: "Add Trailer" },
  ];

  const [success, setSuccess] = useState(false);
  const [endoEffectiveDate, setEndoEffectiveDate] = useState(new Date());

  const mirrorLogic = async (e) => {
    e.preventDefault();
    const isRenewalApplication =
      formData.status === "In-Renewal" &&
      formData.documentType === "Application";
    const isRenewalPolicy =
      formData.status === "In-Renewal" && formData.documentType === "Policy";
    const notARenewalProcess = formData.status !== "In-Renewal";

    const { agencyID, insuredID } = formData;

    const verifiedInExistingData = await validateIfExists(
      [trailerData],
      agencyID,
      insuredID,
      "trailers"
    );

    try {
      if (notARenewalProcess) {
        await submitAddTrailerEndorsement(
          e,
          policyID,
          verifiedInExistingData[0],
          props.insuredInfo,
          props.userInfo,
          setSuccess,
          setTrailerData,
          endoEffectiveDate,
          formData,
          goBack,
          props.isEndoManager,
          props.getEndorsements,
          formData.documentType,
          false,
          null,
          null,
          trailerList
        );
        const isPolicy = formData.documentType === "Policy";
        const message = isPolicy
          ? "You created an Add Trailer endorsement successfully!"
          : "You created a Trailer successfully!";
        controlToast(true, message, "success");
      }
      if (isRenewalApplication) {
        const mirroredIds = await submitAddTrailerEndorsement(
          e,
          policyID,
          verifiedInExistingData[0],
          props.insuredInfo,
          props.userInfo,
          setSuccess,
          setTrailerData,
          endoEffectiveDate,
          formData,
          goBack,
          props.isEndoManager,
          props.getEndorsements,
          "Application",
          true,
          null,
          null,
          trailerList
        );
        await submitAddTrailerEndorsement(
          e,
          formData.renewalPolicyID,
          verifiedInExistingData[0],
          props.insuredInfo,
          props.userInfo,
          setSuccess,
          setTrailerData,
          endoEffectiveDate,
          formData,
          goBack,
          props.isEndoManager,
          props.getEndorsements,
          "Policy",
          true,
          mirroredIds,
          policyID,
          trailerList
        );
        const message = "You created an Add Trailer endorsement successfully!";
        controlToast(true, message, "success");
      }
      if (isRenewalPolicy) {
        const mirroredIds = await submitAddTrailerEndorsement(
          e,
          formData.renewalApplicationID,
          verifiedInExistingData[0],
          props.insuredInfo,
          props.userInfo,
          setSuccess,
          setTrailerData,
          endoEffectiveDate,
          formData,
          goBack,
          props.isEndoManager,
          props.getEndorsements,
          "Application",
          true,
          null,
          null,
          trailerList
        );
        await submitAddTrailerEndorsement(
          e,
          policyID,
          verifiedInExistingData[0],
          props.insuredInfo,
          props.userInfo,
          setSuccess,
          setTrailerData,
          endoEffectiveDate,
          formData,
          goBack,
          props.isEndoManager,
          props.getEndorsements,
          "Policy",
          true,
          mirroredIds,
          policyID,
          trailerList
        );
        const message = "You created an Add Trailer endorsement successfully!";
        controlToast(true, message, "success");
      }
    } catch (error) {
      throw error;
    }
  };

  const isMobileScreen = useMediaQuery("(max-width: 600px)");

  const [openTrailerMakeModal, setOpenTrailerMakeModal] =
    useState(false);

  const handleOpenTrailerMakeModal = () => {
    setOpenTrailerMakeModal(true);
  };

  const handleCloseTrailerMakeModal = () => {
    setOpenTrailerMakeModal(false);
  };

  const addTrailerMakeModal = [
    {
      label: "Trailer Make",
      type: "text",
      name: "trailerMake",
      required: true,
    }
  ];

  const handleSaveTraining = async (e, value) => {
    e.preventDefault();
    const trailerMakeRef = doc(db, "trailer-makes", value.trailerMake);
    await setDoc(trailerMakeRef, {}, { merge: true });
    handleCloseTrailerMakeModal();
  };

  const formSettingsTrailerMake = {
    onSubmit: handleSaveTraining,
    onDecline: handleCloseTrailerMakeModal,
    inputs: addTrailerMakeModal,
    buttonLabel: 'SUBMIT',
    buttonLabelDecline: 'CANCEL',
  };

  const [loading, setLoading] = useState(false);
  const [trailersMake, setTrailersMake] = useState([]);

  useEffect(() => {
    const fetchTrailerMakes = () => {
      setLoading(true);
      const trailerMakesRef = collection(db, "trailer-makes");
      const q = query(trailerMakesRef);
      const unsubscribe = onSnapshot(q, (querySnapshot) => {
        const makes = [];
        querySnapshot.forEach((doc) => {
          makes.push({ ...doc.data(), id: doc.id });
        });
        const ids = makes.map(make => make.id)
        setTrailersMake(ids);
        setLoading(false)
      });
      return unsubscribe;
    };

    const fetchTrailerMakesTemporal = async () => {
      try {
        setLoading(true);
        const apiService = new ApiService("southern");
        const makes = await apiService.getTrailersMake()
        const ids = makes.map(make => make.id)
        setTrailersMake(ids)
        setLoading(false)
        return () => {};
      } catch (error) {
        setTrailersMake([])
        setLoading(false)
        return () => {};
      }
    };

    const isTemporal = isTemporalUrl && !isTemporalUrlClient
    const unsubscribe = isTemporal ? fetchTrailerMakesTemporal() : fetchTrailerMakes();

    return () => unsubscribe();
  }, []);

  return (
    <>
      <FormPopup
        isOpen={openTrailerMakeModal}
        formSettings={formSettingsTrailerMake}
        onRequestClose={handleCloseTrailerMakeModal}
        title="Add Trailer Make"
      />
      {success ? (
        <main className="bg-green-100 p-6 rounded-md shadow-md text-green-700">
          <p className="text-2xl font-semibold mb-4">
            Your endorsement has been successfully submitted.
          </p>
          <p className="mb-4">
            An underwriter will review your endorsement, and you will receive a
            notification when the status is updated.
          </p>
          <button
            onClick={(e) => goBack(e)}
            className="bg-green-500 hover:bg-green-600 text-white font-semibold py-2 px-4 rounded"
          >
            OK
          </button>
        </main>
      ) : (
        <main>
          <header>
            {!isEndoManager ? <Breadcrumbs items={breadcrumbs} /> : null}
            <h1
              className={`${
                isEndoManager
                  ? "text-2xl mt-2.5 font-bold md:mb-0"
                  : "text-3xl mt-2 font-semibold md:mb-0"
              }`}
            >
              {!isEndoManager ? `${t("Add Trailer")}` : `${t("Add Trailer")}`}
            </h1>
            <p className="mt-2 mb-3">
              {(formData.documentType === "Application" ||
                formData.documentType === "Policy") &&
                t(
                  "Fill out the following form to Add Trailer. Click submit once the form is complete."
                )}
              {formData.documentType !== "Application" &&
                formData.documentType !== "Policy" && (
                  <>Trailers may not be edited during the quoting phase.</>
                )}
            </p>
          </header>
          {/* Form */}
          <section className="card mx-auto max-w-[750px] mb-4 shadow-lg">
            <div className="card-body">
              <form onSubmit={mirrorLogic}>
                {formData.documentType === "Policy" ||
                formData.status === "In-Renewal" ? (
                  <>
                    <label className="">{t("Effective Date")}:</label>
                    <DatePicker
                      wrapperClassName="datePicker"
                      required
                      selected={endoEffectiveDate}
                      onChange={(date) => {
                        setEndoEffectiveDate(date);
                      }}
                      className="standard-form-input"
                    />
                  </>
                ) : null}
                <label className="d-block mt-1">Trailer VIN:</label>
                {trailerData?.trailerVIN &&
                  trailerData?.trailerVIN.length !== 17 && (
                    <p className="text-[#8B0000]">VIN must be 17 characters.</p>
                  )}
                <input
                  required
                  onChange={(e) =>
                    setTrailerData({
                      ...trailerData,
                      trailerVIN: e.target.value,
                    })
                  }
                  value={trailerData.trailerVIN}
                  type="text"
                  id="trailerVIN"
                  className="standard-form-input"
                />
                <label className="mt-1">Trailer Year:</label>
                {trailerData?.trailerYear &&
                  trailerData.trailerYear.length !== 4 && (
                    <p>Year must be 4 numbers.</p>
                  )}
                <input
                  required
                  onChange={(e) =>
                    setTrailerData({
                      ...trailerData,
                      trailerYear: e.target.value,
                    })
                  }
                  value={trailerData.trailerYear}
                  type="text"
                  id="trailerYear"
                  className="standard-form-input"
                />
                {!loading ?
                  <>
                    <label htmlFor="trailerMake" className="mt-1">
                      Trailer Make:
                    </label>
                    <select
                      required
                      onChange={(e) =>
                        setTrailerData({
                          ...trailerData,
                          trailerMake: e.target.value,
                        })
                      }
                      value={trailerData.trailerMake}
                      id="trailerMake"
                      className="standard-form-input"
                    >
                      <option value=""></option>
                      {trailersMake.map((make) => (
                        <option key={make} value={make}>
                          {make}
                        </option>
                      ))}
                    </select>
                  </> : null
                }
                <div>
                  {REACT_APP_LICONA_INSURANCE_GROUP_ID === user?.agencyID ? (
                    <BlueButton
                      text={'ADD CUSTOM TRAILER MAKE'}
                      onClickFunction={handleOpenTrailerMakeModal}
                      hasContentToBottom={true}
                      hasContentToRight={!isMobileScreen}
                      className={isMobileScreen ? "w-[100%]" : ""}
                    />
                  ) : null}
                </div>
                <label htmlFor="trailerACV" className="mt-1">
                  Actual Cash Value:
                </label>
                <input
                  required
                  onChange={(e) =>
                    setTrailerData({
                      ...trailerData,
                      trailerACV: e.target.value,
                    })
                  }
                  value={trailerData.trailerACV}
                  type="number"
                  id="trailerACV"
                  className="standard-form-input"
                />
                <ApprovalButtons
                  onApprove={null}
                  handleAsSubmit={true}
                  onDecline={(e) => {
                    goBack();
                  }}
                  titleAccept="Submit"
                  titleDecline="Go Back"
                />
              </form>
            </div>
          </section>
        </main>
      )}
    </>
  );
}
