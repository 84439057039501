import React, { useEffect, useState } from "react";
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import ApiService from "../../../services/southern-api/apiService";
import { formatDate } from "../../Finances/Tables/utils";
import { collection, getDocs, query, where } from "firebase/firestore";
import { db } from "../../../firebase";

function LetterFlags({ redFlags, item, formData }) {

  const [flags, setFlags] = useState([]);

  const apiService = new ApiService();

  const handleParseFilings = async () => {
    try {
      const redFlags = []
      if (item?.url) {
        const parsedFiling = await apiService.parseFilings({
          url: item?.url,
        })
        const policyNo = parsedFiling?.data?.policy_number
        const effectiveDate = parsedFiling?.data?.effectiveDate
        const action = parsedFiling?.data?.action
        if (formData.alPolicyNo !== policyNo) {
          redFlags.push('AL Policy Number does not matched')
        }
        if (
          item?.filingType === 'NOC'
        ) {
          const nocRef = collection(db, "NOC")
          const q = query(nocRef, where("proofFiles", "==", item?.url));
          const queryData = await getDocs(q);
          const data = queryData.docs.map((doc) => ({
            ...doc.data(),
            id: doc.id,
          }));
          const date = data?.[0]?.effectiveDateOfReinstatement
          if (effectiveDate !== formatDate(date)) {
            redFlags.push('Effective Date does not matched')
          }
        }
        if (action !== 'ACCEPTED') {
          redFlags.push('Action was not accepted')
        }
        setFlags(redFlags)
      }
    } catch (error) {
      console.error('Error parsing filings', error)
    }
  }

  useEffect(() => {
    if (redFlags) {
      setFlags(redFlags);
    } else {
      handleParseFilings();
    }
  }, [formData, redFlags, item]);

  return (
    <p>
      <Tooltip
        title={
          <Box>
            {flags.map((flag, index) => (
              <Typography key={index} component="li">
                {flag}
              </Typography>
            ))}
          </Box>
        }
        placement="top"
        arrow
      >
        {flags.length > 0 ? (
          <IconButton sx={{ color: "#8B0000" }}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="currentColor"
              className="bi bi-flag-fill d-inline"
              viewBox="0 0 16 16"
            >
              <path d="M14.778.085A.5.5 0 0 1 15 .5V8a.5.5 0 0 1-.314.464L14.5 8l.186.464-.003.001-.006.003-.023.009a12.435 12.435 0 0 1-.397.15c-.264.095-.631.223-1.047.35-.816.252-1.879.523-2.71.523-.847 0-1.548-.28-2.158-.525l-.028-.01C7.68 8.71 7.14 8.5 6.5 8.5c-.7 0-1.638.23-2.437.477A19.626 19.626 0 0 0 3 9.342V15.5a.5.5 0 0 1-1 0V.5a.5.5 0 0 1 1 0v.282c.226-.079.496-.17.79-.26C4.606.272 5.67 0 6.5 0c.84 0 1.524.277 2.121.519l.043.018C9.286.788 9.828 1 10.5 1c.7 0 1.638-.23 2.437-.477a19.587 19.587 0 0 0 1.349-.476l.019-.007.004-.002h.001" />
            </svg>
          </IconButton>
        ) : null}
      </Tooltip>
    </p>
  );
}

export default LetterFlags;