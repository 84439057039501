// TODO: Add conditionals for new/renewal questions
import React, { useEffect, useMemo, useState } from "react";
import CardHeaderHighlight from "../../assets/cards/CardHeaderHighlight";
import { t } from "i18next";
import AddContacts from "./AddContacts";
import Breadcrumbs from "../../assets/Breadcrumbs";
import AddLocations from "./AddLocations";
import TextInput from "../../assets/forms/DynamicPropertiesInputs/TextInput";
import YesNo from "../../assets/forms/DynamicPropertiesInputs/YesNo";
import NumberInput from "../../assets/forms/DynamicPropertiesInputs/NumberInput";
import SelectInput from "../../assets/forms/DynamicPropertiesInputs/SelectInput";
import MultiUpload from "../../assets/forms/DynamicPropertiesInputs/MultiUpload";
import { collection, doc, onSnapshot, query, where } from "firebase/firestore";
import { auth, db } from "../../../firebase";
import readXlsxFile from "read-excel-file";
import importQuestions from "../../utils/importQuestions";
import Popup from "../../generals/Popup";
import LoadingScreen from "react-loading-screen";
import DragDropImportList from "../../assets/forms/DragDropFileUpload/DragDropImportList";
import BlueButton from "../../assets/forms/BlueButton";
import useGeneralState from "../../../Context/actions/useGeneralState";
import InsuredInfo from "../../insured/InsuredInfo";
import { useMediaQuery } from "@mui/material";
import { IosShare as IosShareIcon } from "@mui/icons-material";
import ShareModal from "../../ShareModal";
import { useLocation } from "react-router-dom";
import ApiService from "../../../services/southern-api/apiService";

export default function Questionnaire(props) {
  const formData = props.formData;
  const setFormData = props.setFormData;
  const saveForm = props.saveForm;
  const setFormSaved = props.setFormSaved;
  const formSaved = props.formSaved;
  const policyID = props.policyID;
  const insuredInfo = props.insuredInfo;
  const userInfo = props.userInfo;
  const emailValue = props.emailValue;
  const setDidSuccess = props.setDidSuccess;
  const [success, setSuccess] = useState(false);
  const [enableEditing, setEnableEditing] = useState(false);

  const location = useLocation();
  const isTemporalUrl = location?.pathname?.includes("temporarily");
  const isTemporalUrlClient = location?.pathname?.includes("temporarily-client");

  const apiService = new ApiService();
  const handleFinishOperation = async () => {
    try {
      await apiService.renewalProcessModuleUpdateConfirmation({
        policyData: formData,
        module: "questionnaire",
        emails: [emailValue],
      });
      setDidSuccess(true);
    } catch (error) {
      console.error(error);
    }
  };

  const { agencyID, insuredID } = formData;
  const [openShareModal, setOpenShareModal] = useState(false);

  const isMobileScreen = useMediaQuery("(max-width: 600px)");

  const [showGeneralInfo, setShowGeneralInfo] = useState(false);
  const [showContactInfo, setShowContactInfo] = useState(false);
  const [showCompanyInfo, setShowCompanyInfo] = useState(false);
  const [showRadiusOfOperations, setShowRadiusOfOperations] = useState(false);
  const [showSafetyProcedures, setShowSafetyProcedures] = useState(false);
  const [showHiringProcedures, setShowHiringProcedures] = useState(false);
  const [showMaintenance, setShowMaintenance] = useState(false);

  const { controlToast } = useGeneralState();

  const breadcrumbs = [
    { label: formData.documentType },
    { label: "Questionnaire" },
  ];

  const radiusTotal =
    parseInt(formData?.radius1to100) +
    parseInt(formData?.radius101to500) +
    parseInt(formData?.radius501to1000) +
    parseInt(formData?.radiusOver1000);

  const [firstContact, setFirstContact] = useState({
    contactName: "",
    contactTitle: "",
    contactEmail: "",
    contactCountryCode: "",
    contactPhone: "",
  });

  const getCompanyInfo = () => {
    if (!agencyID) {
      return () => {};
    }
    const docRef = doc(db, "agencies", agencyID, "insureds", insuredID);
    const unsubscribe = onSnapshot(docRef, (docSnap) => {
      if (docSnap.exists()) {
        console.log("Document data:", docSnap.data());
        const {
          primaryContact = "",
          contactEmail = "",
          contactCountryCode = "",
          contactPhone = "",
          title = "",
        } = docSnap.data();
        if (
          Boolean(primaryContact) ||
          Boolean(contactEmail) ||
          Boolean(contactCountryCode) ||
          Boolean(contactPhone) ||
          Boolean(title)
        ) {
          setFirstContact({
            contactName: primaryContact,
            contactTitle: title,
            contactEmail,
            contactCountryCode,
            contactPhone,
          });
        }
      } else {
        console.log("No such document!");
      }
    });
    return unsubscribe;
  };

  const getRadiusPercentageThroughFrequencyType = (truckList) => {
    const policiesDetails = truckList.map((truck) => truck.policiesDetails);
    const policyDetailsWithSamePolicyId = policiesDetails
      .flat()
      .filter((detail) => detail.id === policyID);
    const radiusList = policyDetailsWithSamePolicyId.map(
      (truck) => truck.truckRadius
    );
    const listfrom1To100Miles = radiusList?.filter((radius) =>
      Boolean(radius === "0-50" || radius === "0-100")
    );
    const listfrom101To500Miles = radiusList?.filter((radius) =>
      Boolean(radius === "0-250" || radius === "0-500")
    );
    const listfrom501To1000Miles = radiusList?.filter((radius) =>
      Boolean(radius === "0-800" || radius === "0-1000")
    );
    const listOver1000Miles = radiusList?.filter((radius) =>
      Boolean(radius === "0-1000" || radius === "0-1200" || radius === "0-1500")
    );
    const total1To100 = listfrom1To100Miles?.length || 0;
    const total101To500 = listfrom101To500Miles?.length || 0;
    const total501To1000 = listfrom501To1000Miles?.length || 0;
    const totalOver1000 = listOver1000Miles?.length || 0;
    const totalElements =
      total1To100 + total101To500 + total501To1000 + totalOver1000;
    const percentages = {
      from1To100: ((total1To100 / totalElements) * 100).toFixed(2),
      from101To500: ((total101To500 / totalElements) * 100).toFixed(2),
      from501To1000: ((total501To1000 / totalElements) * 100).toFixed(2),
      over1000: ((totalOver1000 / totalElements) * 100).toFixed(2),
    };
    return percentages;
  };

  const [excelFile, setExcelFile] = useState(null);
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);

  const handleFileUpload = async (file) => {
    if (file) {
      setLoading(true);
      try {
        const rows = await readXlsxFile(file);
        await importQuestions(policyID, rows);
        controlToast(
          true,
          "Questionnaire was imported successfully",
          "success"
        );
      } catch (error) {
        console.log("error", error);
        controlToast(true, "There was an error in service.", "error");
      } finally {
        setLoading(false);
      }
    } else {
      setLoading(false);
      controlToast(true, "Please select a file before submitting.", "error");
    }
  };

  useEffect(() => {
    if (!agencyID || !insuredID || !policyID || !showRadiusOfOperations) {
      return () => {};
    }
    const policyRef = doc(db, "policy-applications", policyID);
    const q = query(
      collection(
        db,
        "agencies",
        agencyID,
        "insureds",
        insuredID,
        "power-units"
      ),
      where("policies", "array-contains", policyRef.id)
    );
    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      const values = [];
      querySnapshot.forEach((doc) => {
        if (doc.data().status === "Deleted" || doc.data().status === "Declined")
          return;
        values.push({
          ...doc.data(),
          id: doc.id,
        });
      });
      const { from1To100, from101To500, from501To1000, over1000 } =
        getRadiusPercentageThroughFrequencyType(values);
      setFormData((prev) => ({
        ...prev,
        questionnaire: {
          ...prev.questionnaire,
          radius1to100: from1To100,
          radius101to500: from101To500,
          radius501to1000: from501To1000,
          radiusOver1000: over1000,
        },
      }));
    });
    return () => {
      unsubscribe();
    };
  }, [policyID, agencyID, insuredID, showRadiusOfOperations]);

  useEffect(() => {
    const unsubscribe = getCompanyInfo();
    return () => unsubscribe();
  }, [agencyID]);

  return (
    <main>
      <ShareModal
        formData={formData}
        insuredInfo={insuredInfo}
        openShareModal={openShareModal}
        setOpenShareModal={setOpenShareModal}
        module={"questionnaire"}
      />
      <Breadcrumbs items={breadcrumbs} />
      <h1 className="text-3xl mt-2 font-semibold md:mb-0">Questionnaire</h1>
      <Popup
        isOpen={open}
        onRequestClose={() => {
          if (!loading) {
            setOpen(false);
          }
        }}
        maxWidth="100%"
      >
        <div className="h-[300px] flex justify-center items-center">
          <LoadingScreen
            loading={loading}
            bgColor="#f1f1f1"
            spinnerColor="#9ee5f8"
            textColor="#676767"
            padding="0"
            logoSrc=""
            text={`Printing... Don't leave the screen`}
          >
            <p className="text-2xl mt-2 font-semibold mb-3">
              Import your xlsx with Questionnaire
            </p>
            <DragDropImportList
              file={excelFile}
              setFile={setExcelFile}
              handleUpload={async (fileObj) => {
                await handleFileUpload(fileObj);
                setOpen(false);
              }}
              fileTypes={["XLSX"]}
            />
          </LoadingScreen>
        </div>
      </Popup>

      {formData.documentType === "Application" && !isTemporalUrl ? (
        <BlueButton
          text="Import"
          onClickFunction={() => setOpen(true)}
          hasContentToBottom={true}
          hasContentToTop={true}
          hasContentToRight={!isMobileScreen}
        />
      ) : null}

      {!isTemporalUrl && (
        userInfo?.role === "Agent" ||
        userInfo?.role === "Admin" ||
        userInfo?.role === "Underwriter"
      ) ? (
        <BlueButton
          text="SHARE"
          hasContentToTop={true}
          hasContentToBottom={true}
          onClickFunction={() => setOpenShareModal(true)}
          icon={<IosShareIcon />}
          className={isMobileScreen ? "w-[100%]" : ""}
        />
      ) : null}

      <p className="my-3">
        {(formData.documentType === "Application" ||
          formData.documentType === "Policy") && (
          <>
            <span>
              The following questions are automatically adjusted depending on
              coverage and business type (new/renewal).
            </span>
            <span className="mt-2">
              Please complete as many fields as possible to ensure expedient
              underwriting.
            </span>
          </>
        )}
        {formData.documentType !== "Application" &&
          formData.documentType !== "Policy" && (
            <>The questionnaire may not be edited during the quoting phase.</>
          )}
      </p>
      {isTemporalUrl && !isTemporalUrlClient ? (
        <>
          {/* TODO: Translate this line */}
          <p className="mb-2">
            Once you have made all necessary changes, please click the 'Submit
            Changes' button below.
          </p>
          <BlueButton
            text="Submit Changes"
            onClickFunction={handleFinishOperation}
            hasContentToTop={true}
            hasContentToBottom={true}
            hasContentToRight={!isMobileScreen}
            className={isMobileScreen ? "w-[100%]" : ""}
            icon="no-icon"
          />
        </>
      ) : null}
      <fieldset disabled={props.formData.documentType !== "Application"}>
        <div className="card mt-3">
          <CardHeaderHighlight
            label={t("Company Information")}
            id={"company-info"}
            currentState={showCompanyInfo}
            setter={setShowCompanyInfo}
          />
          {showCompanyInfo && (
            <div className="card-body">
              <label className="d-block mb-2">
                {t(
                  "To modify this information, please click the edit button below. The company information in this panel will not autosave like the rest of the questionnaire. You must click the save button once finished."
                )}
              </label>
              <button
                className="text-white bg-[#072a48] px-3 py-1 mb-2 rounded-md"
                onClick={() => setEnableEditing(!enableEditing)}
              >
                {enableEditing ? "Cancel Editing" : "Edit Company Info"}
              </button>
              <InsuredInfo
                insuredsID={insuredInfo.id}
                agencyID={agencyID}
                success={success}
                setSuccess={setSuccess}
                enableEditing={enableEditing}
                setEnableEditing={setEnableEditing}
                userInfo={userInfo}
                policyID={policyID}
              />
            </div>
          )}
        </div>
        {/* Contact Information */}
        <div className="card mt-2">
          <CardHeaderHighlight
            label={t("Contact Information")}
            id={"contact-info"}
            currentState={showContactInfo}
            setter={setShowContactInfo}
          />
          {showContactInfo && (
            <div className="card-body">
              <label className="d-block mb-2">
                {t(
                  "Here you may add as many contacts as you would like. You must add at least one primary contact."
                )}
              </label>
              <AddContacts
                formData={formData}
                setFormData={setFormData}
                setFormSaved={setFormSaved}
                saveForm={saveForm}
                policyID={policyID}
                firstContact={firstContact}
                setFirstContact={setFirstContact}
              />
            </div>
          )}
        </div>
        {/* General Information */}
        <div className="card mt-2">
          <CardHeaderHighlight
            label={t("General Information")}
            id={"general-info"}
            currentState={showGeneralInfo}
            setter={setShowGeneralInfo}
          />
          {showGeneralInfo && (
            <div className="card-body">
              <label className="d-block mt-1">Garaging Locations:</label>{" "}
              <AddLocations
                formData={formData}
                setFormData={setFormData}
                setFormSaved={setFormSaved}
                saveForm={saveForm}
                policyID={policyID}
              />
              <div className="mt-2">
                <MultiUpload
                  label={t("Attach Current Financials")}
                  property={"questionnaire.currentFinancials"}
                  saveForm={saveForm}
                  setFormData={setFormData}
                  formData={formData}
                  setFormSaved={setFormSaved}
                  formSaved={formSaved}
                  policyID={policyID}
                  multiple={false}
                  storageFile={`files/policies/${policyID}`}
                  fileInButton={true}
                  dissapearWhenImgExists={true}
                  showInAPopup={true}
                />
              </div>
              <YesNo
                label={t("Have you ever filed for bankruptcy")}
                property={"questionnaire.everFiledForBankruptcy"}
                saveForm={saveForm}
                setFormData={setFormData}
                formData={formData}
                setFormSaved={setFormSaved}
                formSaved={formSaved}
                policyID={props.policyID}
              />
              <YesNo
                label={t("Have you ever operated under a different name")}
                property={"questionnaire.operatedUnderDiffName"}
                saveForm={saveForm}
                setFormData={setFormData}
                formData={formData}
                setFormSaved={setFormSaved}
                formSaved={formSaved}
                policyID={props.policyID}
              />
              {formData.questionnaire?.operatedUnderDiffName === "Yes" && (
                <TextInput
                  label={t("If yes, please describe")}
                  property={"questionnaire.describeOperatedDifferentName"}
                  saveForm={saveForm}
                  setFormData={setFormData}
                  formData={formData}
                  setFormSaved={setFormSaved}
                  formSaved={formSaved}
                  policyID={props.policyID}
                />
              )}
              <YesNo
                label={t("Do you have subsidiaries")}
                property={"questionnaire.haveSubsidiaries"}
                saveForm={saveForm}
                setFormData={setFormData}
                formData={formData}
                setFormSaved={setFormSaved}
                formSaved={formSaved}
                policyID={props.policyID}
              />
              {formData.questionnaire?.haveSubsidiaries === "Yes" && (
                <TextInput
                  label={t("If yes, please describe")}
                  property={"questionnaire.describeSubsidiaries"}
                  saveForm={saveForm}
                  setFormData={setFormData}
                  formData={formData}
                  setFormSaved={setFormSaved}
                  formSaved={formSaved}
                  policyID={props.policyID}
                />
              )}
              <TextInput
                label={t("Description of Operations")}
                property={"questionnaire.descriptionOfOps"}
                saveForm={saveForm}
                setFormData={setFormData}
                formData={formData}
                setFormSaved={setFormSaved}
                formSaved={formSaved}
                policyID={props.policyID}
              />
              <SelectInput
                label={t("Carrier Type")}
                property={"questionnaire.carrierType"}
                saveForm={saveForm}
                setFormData={setFormData}
                formData={formData}
                setFormSaved={setFormSaved}
                formSaved={formSaved}
                policyID={props.policyID}
                options={[
                  {
                    id: 0,
                    key: "",
                    label: "Select an option",
                  },
                  {
                    id: 1,
                    key: "Common",
                    label: "Common",
                  },
                  {
                    id: 2,
                    key: "Contract",
                    label: "Contract",
                  },
                  {
                    id: 3,
                    key: "Private",
                    label: "Private",
                  },
                  {
                    id: 4,
                    key: "Other",
                    label: "Other",
                  },
                ]}
              />
              {formData.questionnaire?.carrierType === "Other" && (
                <TextInput
                  label={t("If other, please explain")}
                  property={"questionnaire.describeCarrierType"}
                  saveForm={saveForm}
                  setFormData={setFormData}
                  formData={formData}
                  setFormSaved={setFormSaved}
                  formSaved={formSaved}
                  policyID={props.policyID}
                />
              )}
              {formData.questionnaire?.carrierType === "Contract" && (
                <TextInput
                  label={t("If contract, for whom")}
                  property={"questionnaire.contractForWhom"}
                  saveForm={saveForm}
                  setFormData={setFormData}
                  formData={formData}
                  setFormSaved={setFormSaved}
                  formSaved={formSaved}
                  policyID={props.policyID}
                />
              )}
              <TextInput
                label={t("MC Number")}
                property={"questionnaire.MCNumber"}
                saveForm={saveForm}
                setFormData={setFormData}
                formData={formData}
                setFormSaved={setFormSaved}
                formSaved={formSaved}
                policyID={props.policyID}
              />
              <NumberInput
                label={t("Latest DOT Rating")}
                property={"questionnaire.latestDOTRating"}
                saveForm={saveForm}
                setFormData={setFormData}
                formData={formData}
                setFormSaved={setFormSaved}
                formSaved={formSaved}
                policyID={props.policyID}
              />
              <NumberInput
                label={t("Year of Latest DOT Rating")}
                property={"questionnaire.yearOfLatestDOTRating"}
                saveForm={saveForm}
                setFormData={setFormData}
                formData={formData}
                setFormSaved={setFormSaved}
                formSaved={formSaved}
                policyID={props.policyID}
              />
              <YesNo
                label={t(
                  "Have you been cancelled & renewed by another carrier within the past 3 years"
                )}
                property={"questionnaire.cancelledPast3Years"}
                saveForm={saveForm}
                setFormData={setFormData}
                formData={formData}
                setFormSaved={setFormSaved}
                formSaved={formSaved}
                policyID={props.policyID}
              />
              {formData.questionnaire?.cancelledPast3Years === "Yes" && (
                <TextInput
                  label={t("If yes, please explain")}
                  property={"questionnaire.describeCancelledRenewed"}
                  saveForm={saveForm}
                  setFormData={setFormData}
                  formData={formData}
                  setFormSaved={setFormSaved}
                  formSaved={formSaved}
                  policyID={props.policyID}
                />
              )}
              <YesNo
                label={t("Is carrier involved in any non-trucking business")}
                property={"questionnaire.involvedNonTruckingBiz"}
                saveForm={saveForm}
                setFormData={setFormData}
                formData={formData}
                setFormSaved={setFormSaved}
                formSaved={formSaved}
                policyID={props.policyID}
              />
              {formData.questionnaire?.involvedNonTruckingBiz === "Yes" && (
                <TextInput
                  label={t("If yes, please describe")}
                  property={"questionnaire.describeNonTruckingBiz"}
                  saveForm={saveForm}
                  setFormData={setFormData}
                  formData={formData}
                  setFormSaved={setFormSaved}
                  formSaved={formSaved}
                  policyID={props.policyID}
                />
              )}
            </div>
          )}
        </div>
        {/* Radius of Operations */}
        <div className="card mt-2">
          <CardHeaderHighlight
            label={t("Radius of Operations")}
            id={"radius"}
            currentState={showRadiusOfOperations}
            setter={setShowRadiusOfOperations}
          />
          {showRadiusOfOperations && (
            <div className="card-body">
              <p>
                These are the percentage of your trips that fall within the
                following radiuses.
              </p>
              <p>Radius total currently equals 100%.</p>
              <NumberInput
                label={"1-100 miles"}
                property={"questionnaire.radius1to100"}
                saveForm={saveForm}
                setFormData={setFormData}
                formData={formData}
                setFormSaved={setFormSaved}
                formSaved={formSaved}
                policyID={props.policyID}
                disabled={true}
              />
              <NumberInput
                label={"101-500 miles"}
                property={"questionnaire.radius101to500"}
                saveForm={saveForm}
                setFormData={setFormData}
                formData={formData}
                setFormSaved={setFormSaved}
                formSaved={formSaved}
                policyID={props.policyID}
                disabled={true}
              />
              <NumberInput
                label={"501-1000 miles"}
                property={"questionnaire.radius501to1000"}
                saveForm={saveForm}
                setFormData={setFormData}
                formData={formData}
                setFormSaved={setFormSaved}
                formSaved={formSaved}
                policyID={props.policyID}
                disabled={true}
              />
              <NumberInput
                label={t("Over 1000 miles")}
                property={"questionnaire.radiusOver1000"}
                saveForm={saveForm}
                setFormData={setFormData}
                formData={formData}
                setFormSaved={setFormSaved}
                formSaved={formSaved}
                policyID={props.policyID}
                disabled={true}
              />
              <YesNo
                label={t("Do you travel through metro areas")}
                property={"questionnaire.travelThroughMetros"}
                saveForm={saveForm}
                setFormData={setFormData}
                formData={formData}
                setFormSaved={setFormSaved}
                formSaved={formSaved}
                policyID={props.policyID}
              />
              <TextInput
                label={t("Which states do you predominantly travel through")}
                property={"questionnaire.predominantStates"}
                saveForm={saveForm}
                setFormData={setFormData}
                formData={formData}
                setFormSaved={setFormSaved}
                formSaved={formSaved}
                policyID={props.policyID}
              />
            </div>
          )}
        </div>
        {/* Driver Hiring Procedures */}
        <div className="card mt-2">
          <CardHeaderHighlight
            label={t("Driver Hiring Procedures")}
            id={"hiring"}
            currentState={showHiringProcedures}
            setter={setShowHiringProcedures}
          />
          {showHiringProcedures && (
            <div className="card-body">
              <YesNo
                label={t(
                  "Do you hire drivers with less than 2 years of experience"
                )}
                property={"questionnaire.hireDriversLess2YOE"}
                saveForm={saveForm}
                setFormData={setFormData}
                formData={formData}
                setFormSaved={setFormSaved}
                formSaved={formSaved}
                policyID={props.policyID}
              />
              {formData?.questionnaire?.hireDriversLess2YOE === "No" && (
                <div>
                  <NumberInput
                    label={t("Minimum Experience")}
                    property={"questionnaire.driverMinExp"}
                    saveForm={saveForm}
                    setFormData={setFormData}
                    formData={formData}
                    setFormSaved={setFormSaved}
                    formSaved={formSaved}
                    policyID={props.policyID}
                  />
                </div>
              )}
              <YesNo
                label={t("Are drivers drug tested prior to hire")}
                property={"questionnaire.driversDrugTestedPriorToHire"}
                saveForm={saveForm}
                setFormData={setFormData}
                formData={formData}
                setFormSaved={setFormSaved}
                formSaved={formSaved}
                policyID={props.policyID}
              />
              <YesNo
                label={t("Are drivers drug tested randomly after hiring")}
                property={"questionnaire.driversDrugTestedAfterHiring"}
                saveForm={saveForm}
                setFormData={setFormData}
                formData={formData}
                setFormSaved={setFormSaved}
                formSaved={formSaved}
                policyID={props.policyID}
              />
              <YesNo
                label={t("Are driver MVRs reviewed prior to hiring")}
                property={"questionnaire.driverMVRsReviewed"}
                saveForm={saveForm}
                setFormData={setFormData}
                formData={formData}
                setFormSaved={setFormSaved}
                formSaved={formSaved}
                policyID={props.policyID}
              />
              <YesNo
                label={t("Are medical certificates reviewed prior to hiring")}
                property={"questionnaire.medicalCertificatesReviewed"}
                saveForm={saveForm}
                setFormData={setFormData}
                formData={formData}
                setFormSaved={setFormSaved}
                formSaved={formSaved}
                policyID={props.policyID}
              />
              <YesNo
                label={t("Is a road test given prior to hiring")}
                property={"questionnaire.roadTest"}
                saveForm={saveForm}
                setFormData={setFormData}
                formData={formData}
                setFormSaved={setFormSaved}
                formSaved={formSaved}
                policyID={props.policyID}
              />
              <YesNo
                label={t("Is a written test given prior to hiring")}
                property={"questionnaire.writtenTest"}
                saveForm={saveForm}
                setFormData={setFormData}
                formData={formData}
                setFormSaved={setFormSaved}
                formSaved={formSaved}
                policyID={props.policyID}
              />
              <YesNo
                label={t("Are drivers given a formal onboarding/orientation")}
                property={"questionnaire.formalOrientation"}
                saveForm={saveForm}
                setFormData={setFormData}
                formData={formData}
                setFormSaved={setFormSaved}
                formSaved={formSaved}
                policyID={props.policyID}
              />
              {formData?.questionnaire?.formalOrientation === "Yes" && (
                <TextInput
                  label={t("If yes, please describe")}
                  property={"questionnaire.describeOrientation"}
                  saveForm={saveForm}
                  setFormData={setFormData}
                  formData={formData}
                  setFormSaved={setFormSaved}
                  formSaved={formSaved}
                  policyID={props.policyID}
                />
              )}
              <YesNo
                label={t(
                  "Are drivers offered extra incentives outside of their normal wages"
                )}
                property={"questionnaire.driverIncentives"}
                saveForm={saveForm}
                setFormData={setFormData}
                formData={formData}
                setFormSaved={setFormSaved}
                formSaved={formSaved}
                policyID={props.policyID}
              />
              {formData?.questionnaire?.driverIncentives === "Yes" && (
                <TextInput
                  label={t("If yes, please describe")}
                  property={"questionnaire.describeDriverIncentives"}
                  saveForm={saveForm}
                  setFormData={setFormData}
                  formData={formData}
                  setFormSaved={setFormSaved}
                  formSaved={formSaved}
                  policyID={props.policyID}
                />
              )}
            </div>
          )}
        </div>
        {/* Safety Procedures */}
        <div className="card mt-2">
          <CardHeaderHighlight
            label={t("Safety Procedures")}
            id={"safety"}
            currentState={showSafetyProcedures}
            setter={setShowSafetyProcedures}
          />
          {showSafetyProcedures && (
            <div className="card-body">
              <YesNo
                label={t("Is there a written safety program")}
                property={"questionnaire.writtenSafetyPrograms"}
                saveForm={saveForm}
                setFormData={setFormData}
                formData={formData}
                setFormSaved={setFormSaved}
                formSaved={formSaved}
                policyID={props.policyID}
              />
              {formData?.questionnaire?.writtenSafetyPrograms === "Yes" && (
                <>
                  <TextInput
                    label={t("If yes, please describe")}
                    property={"questionnaire.describeSafetyProgram"}
                    saveForm={saveForm}
                    setFormData={setFormData}
                    formData={formData}
                    setFormSaved={setFormSaved}
                    formSaved={formSaved}
                    policyID={props.policyID}
                  />
                  <MultiUpload
                    label="Safety Handbook"
                    property={"questionnaire.safetyHandbook"}
                    saveForm={saveForm}
                    setFormData={setFormData}
                    formData={formData}
                    setFormSaved={setFormSaved}
                    formSaved={formSaved}
                    policyID={policyID}
                    multiple={false}
                    storageFile={`files/policies/${policyID}`}
                    fileInButton={true}
                    dissapearWhenImgExists={true}
                    showInAPopup={true}
                    showPastingOption={true}
                  />
                </>
              )}
              <YesNo
                label={t("Are safety meetings regularly held")}
                property={"questionnaire.regularSafetyMeetings"}
                saveForm={saveForm}
                setFormData={setFormData}
                formData={formData}
                setFormSaved={setFormSaved}
                formSaved={formSaved}
                policyID={props.policyID}
              />
              {formData?.questionnaire?.regularSafetyMeetings === "Yes" && (
                <TextInput
                  label={t("If yes, please describe the frequency")}
                  property={"questionnaire.safetyMeetingFrequency"}
                  saveForm={saveForm}
                  setFormData={setFormData}
                  formData={formData}
                  setFormSaved={setFormSaved}
                  formSaved={formSaved}
                  policyID={props.policyID}
                />
              )}
              <YesNo
                label={t("Do you have a full time safety director")}
                property={"questionnaire.fullTimeSafetyDirector"}
                saveForm={saveForm}
                setFormData={setFormData}
                formData={formData}
                setFormSaved={setFormSaved}
                formSaved={formSaved}
                policyID={props.policyID}
              />
              {formData?.questionnaire?.fullTimeSafetyDirector === "Yes" && (
                <>
                  <TextInput
                    label={t("Safety Director Name")}
                    property={"questionnaire.safetyDirectorName"}
                    saveForm={saveForm}
                    setFormData={setFormData}
                    formData={formData}
                    setFormSaved={setFormSaved}
                    formSaved={formSaved}
                    policyID={props.policyID}
                  />
                  <NumberInput
                    label={t("Years of Safety Experience")}
                    property={"questionnaire.safetyOfficerYOE"}
                    saveForm={saveForm}
                    setFormData={setFormData}
                    formData={formData}
                    setFormSaved={setFormSaved}
                    formSaved={formSaved}
                    policyID={props.policyID}
                  />
                </>
              )}
            </div>
          )}
        </div>
        {/* Maintenance Procedures */}
        <div className="card mt-2">
          <CardHeaderHighlight
            label={t("Maintenance Procedures")}
            id={"maintenance"}
            currentState={showMaintenance}
            setter={setShowMaintenance}
          />
          {showMaintenance && (
            <div className="card-body">
              <YesNo
                label={t("Does each vehicle maintain a logbook")}
                property={"questionnaire.maintenanceLogbooks"}
                saveForm={saveForm}
                setFormData={setFormData}
                formData={formData}
                setFormSaved={setFormSaved}
                formSaved={formSaved}
                policyID={props.policyID}
              />
              <YesNo
                label={t("Do you utilize an in-house repair shop")}
                property={"questionnaire.inHouseMaintenance"}
                saveForm={saveForm}
                setFormData={setFormData}
                formData={formData}
                setFormSaved={setFormSaved}
                formSaved={formSaved}
                policyID={props.policyID}
              />
              {formData?.questionnaire?.inHouseMaintenance === "Yes" && (
                <div>
                  <SelectInput
                    label={t("What types of repair does it handle")}
                    property={"questionnaire.typesOfRepairShopHandles"}
                    saveForm={saveForm}
                    setFormData={setFormData}
                    formData={formData}
                    setFormSaved={setFormSaved}
                    formSaved={formSaved}
                    policyID={props.policyID}
                    options={[
                      {
                        id: 0,
                        key: "",
                        label: "Select an option",
                      },
                      {
                        id: 1,
                        key: "Body Work Only",
                        label: "Body Work Only",
                      },
                      {
                        id: 2,
                        key: "Minor Repairs",
                        label: "Minor Repairs",
                      },
                      {
                        id: 3,
                        key: "Minor Repairs & Body Work",
                        label: "Minor Repairs & Body Work",
                      },
                      {
                        id: 4,
                        key: "Major Repairs",
                        label: "Major Repairs",
                      },
                      {
                        id: 5,
                        key: "Major Repairs & Body Work",
                        label: "Major Repairs & Body Work",
                      },
                    ]}
                  />
                </div>
              )}
              <YesNo
                label={t("Do you schedule regular inspections of equipment")}
                property={"questionnaire.regularInspectionsOfEquip"}
                saveForm={saveForm}
                setFormData={setFormData}
                formData={formData}
                setFormSaved={setFormSaved}
                formSaved={formSaved}
                policyID={props.policyID}
              />
            </div>
          )}
        </div>
      </fieldset>
    </main>
  );
}
