import React from "react";
import Router from "./Router";
import useInitialSetting from "./hooks/useInitialSetting";
import MainWrapper from "./layout/MainWrapper";
import Toast from "./Components/generals/Toast";
import useGeneralState from "./Context/actions/useGeneralState";
import useHistoryTrack from "./hooks/history/useHistoryTrack";
import { ConfirmDeleteProvider } from "./layout/ConfirmDeleteProvider";
import "react-big-calendar/lib/css/react-big-calendar.css"
import "./assets/styles/app.css";
import "./assets/styles/calendar.css";

function App() {
  const {
    userInfo,
    setLoading,
    noProfile,
    setNoProfile,
    waitRedirections,
    loading
  } = useInitialSetting();

  const { toast, controlToast } = useGeneralState()
  useHistoryTrack()

  return (
    <ConfirmDeleteProvider>
      <MainWrapper userInfo={userInfo}>
        {!waitRedirections &&
          <Router
            userInfo={userInfo}
            loading={loading}
            setLoading={setLoading}
            noProfile={noProfile}
            setNoProfile={setNoProfile}
          />
        }
        {toast && toast.open ? (
          <Toast
            message={toast.message}
            type={toast.type}
            duration={5000}
            setShowToast={() => controlToast(false, '', '')}
          />
        ) : null}      
      </MainWrapper>
    </ConfirmDeleteProvider>
  );
}

export default App;
