import LoadingScreen from "react-loading-screen";
import CoveragesSidePanel from "../../Components/PolicyLifecycleManager/CoveragesSidePanel";
import History from "../../Components/PolicyLifecycleManager/History";
import AppOverview from "../../Components/PolicyLifecycleManager/AppOverview";
import EndoManager from "../../Components/PolicyLifecycleManager/EndoManager";
import ActionButtons from "../../Components/PolicyLifecycleManager/ActionButtons";
import CustomTabs from "../../Components/PolicyLifecycleManager/CustomTabs";
import InsuredProfile from "../../Components/insured/InsuredProfile";
import useManagerTabs from "../../hooks/policy/useManagerTabs";
import { Row, Col, Container } from "../../Components/generals/GridSystem";
import UnderwriterPanel from "../../Components/PolicyLifecycleManager/UnderwriterPanel";
import BottomNavbar from "../../Components/PolicyLifecycleManager/BottomNavBar";
import ReturnToAgentMessage from "../../Components/PolicyLifecycleManager/ReturnToAgentModal/ReturnToAgentMessage";
import { useMediaQuery } from "@mui/material";
import MiniHistory from "../../Components/PolicyLifecycleManager/History/MiniHistory";
import DisplayCancellationReasons from "../../Components/CancelPolicyPanel/DisplayCancellationReasons";
import RenewalMessage from "../../Components/PolicyLifecycleManager/RenewPolicyModal/RenewalMessage";
import { useLocation } from "react-router-dom";
import BindPanel from "../../Components/bindPolicyDetails/BindPanel";

export default function ApplicationForm() {
  const {
    loading,
    loadingText,
    coverageProps,
    displayEndorsementManager,
    setDisplayEndorsementManager,
    setDisplayUnderwritingPanel,
    displayUnderwritingPanel,
    gotEndorsements,
    endorsements,
    displayCompanyDetails,
    actionButtonProps,
    appOverviewProps,
    showOverviewAndHistory,
    setShowOverviewAndHistory,
    policyID,
    value,
    handleChange,
    filteredTabs,
    userInfo,
    insuredInfo,
    setDisplayCompanyDetails,
    tabProps,
    getEndorsements,
    getUniqueIdentifierAutoLiabilityNAFTA,
    formData,
    skeletonEndoTableLoader,
    currentEndoFilters,
    setCurrentEndoFilters,
    displayBindDetailsInfo,
    setDisplayBindDetailsInfo,
    agencyInfo
  } = useManagerTabs();

  const isMobileScreen = useMediaQuery("(max-width: 600px)");

  return (
    <LoadingScreen
      loading={loading}
      bgColor="#f1f1f1"
      spinnerColor="#9ee5f8"
      textColor="#676767"
      padding="0"
      logoSrc=""
      text={loadingText}
    >
      {!loading && (
        <Container className="p-0 px-4 mt-1 mb-4 w-full" fluid>
          <BottomNavbar {...actionButtonProps} />
          <Row>
            <Col lg={3} className="mt-2">
              <CoveragesSidePanel {...coverageProps} />
            </Col>
            {!displayEndorsementManager &&
              !displayCompanyDetails &&
              !displayUnderwritingPanel &&
              !displayBindDetailsInfo && (
                <Col lg={9}>
                  <Row>
                    <Col spacing={12} className="mt-2">
                      <ActionButtons {...actionButtonProps} />
                      {formData?.status === "Returned" && (
                        <ReturnToAgentMessage
                          userRole={formData?.returnToAgentDetails.userRole}
                          user={formData?.returnToAgentDetails.user}
                          className={""}
                          reasons={formData?.returnToAgentReasons}
                          returnedDate={formData?.returnToAgentDetails.date}
                        />
                      )}
                      {formData?.nonRenewal && (
                        <RenewalMessage
                          className={""}
                          reasons={formData?.nonRenewalReasons}
                          returnedDate={formData?.nonRenewalDate}
                        />
                      )}
                      {formData.status === "In-Cancellation" && (
                        <DisplayCancellationReasons formData={formData} />
                      )}
                    </Col>
                    <Col
                      spacing={isMobileScreen ? 0 : 12}
                      lg={8}
                      className="mt-2"
                    >
                      <AppOverview {...appOverviewProps} />
                    </Col>
                    <Col
                      spacing={isMobileScreen ? 0 : 12}
                      lg={4}
                      className="mt-2"
                    >
                      <MiniHistory
                        formData={formData}
                        showOverviewAndHistory={showOverviewAndHistory}
                        setShowOverviewAndHistory={setShowOverviewAndHistory}
                        policyID={policyID}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col
                      spacing={isMobileScreen ? 0 : 12}
                      className={isMobileScreen ? "mt-2" : ""}
                    >
                      <CustomTabs
                        value={value}
                        handleChange={handleChange}
                        filteredTabs={filteredTabs}
                      />
                    </Col>
                  </Row>
                </Col>
              )}
            {displayEndorsementManager && (
              <Col lg={9}>
                <Row>
                  <EndoManager
                    setDisplayEndorsementManager={setDisplayEndorsementManager}
                    gotEndorsements={gotEndorsements}
                    endorsements={endorsements}
                    tabProps={tabProps}
                    getEndorsements={getEndorsements}
                    policyID={policyID}
                    skeletonEndoTableLoader={skeletonEndoTableLoader}
                    currentEndoFilters={currentEndoFilters}
                    setCurrentEndoFilters={setCurrentEndoFilters}
                    insuredInfo={insuredInfo}
                    agencyInfo={agencyInfo}
                  />
                </Row>
              </Col>
            )}
            {displayCompanyDetails && (
              <InsuredProfile
                agencyID={userInfo.agencyID}
                insuredsID={insuredInfo.id}
                userInfo={userInfo}
                setDisplayCompanyDetails={setDisplayCompanyDetails}
              />
            )}
            {displayBindDetailsInfo && (
              <BindPanel
                agencyID={userInfo.agencyID}
                insuredsID={insuredInfo.id}
                userInfo={userInfo}
                setDisplayCompanyDetails={setDisplayBindDetailsInfo}
                formData={formData}
              />
            )}
            {displayUnderwritingPanel && (
              <Col lg={9}>
                <Row>
                  <UnderwriterPanel
                    tabProps={tabProps}
                    getEndorsements={getEndorsements}
                    setDisplayUnderwritingPanel={setDisplayUnderwritingPanel}
                    policyID={policyID}
                  />
                </Row>
              </Col>
            )}
          </Row>
        </Container>
      )}
    </LoadingScreen>
  );
}
