import {
  addDoc,
  collection,
  doc,
  runTransaction,
  setDoc,
} from "firebase/firestore";
import { auth, db } from "../../../firebase";
import updateSystemLog from "../../utils/updateSystemLog";
import { validateEndorsementNumber } from "../../../utils/helpers";
import endoStatusChangeNotification from "../../utils/notifications/endoStatusChangeNotification";
import { setPolicyInfoForEndorsement } from "./setPolicyInfo";

export const multipleToEndorsements = async (
  policyID,
  endoEffectiveDate,
  type,
  dataArray,
  insuredInfo,
  formData,
  userInfo,
  getEndorsements,
  endorsements,
  deletionReason,
  proofOfPowerDeletion,
  blacklistExpirationDate
) => {
  try {
    const countersDocRef = doc(db, "unique-numbers", "endorsements");
    const transactionResult = await runTransaction(db, async (transaction) => {
      const countersDoc = await transaction.get(countersDocRef);
      const currentCounter = countersDoc.data().lastCount;
      const newCounter = currentCounter + 1;
      transaction.update(countersDocRef, {
        lastCount: newCounter,
      });

      const endosRef = collection(
        db,
        "policy-applications",
        policyID,
        "endorsements"
      );

      const idsFromSpecificCollection = dataArray.map(data => data?.id)
      
      const status = "Submitted";
      const payload = {
        endorsementNumber: String(newCounter),
        endoEffectiveDate,
        policyID,
        type,
        data: idsFromSpecificCollection,
        dateCreated: new Date(),
        status,
        author: auth.currentUser.displayName,
        authorID: auth.currentUser.uid,
        namedInsured: insuredInfo.company,
        policyEffectiveDate: formData.effectiveDate,
        alPolicyNo: formData.alPolicyNo,
        agencyID: formData.agencyID,
        insuredID: formData.insuredID,
        documentSource: 'Endorsement'
      };
      if (deletionReason) {
        payload['deletionReason'] = deletionReason
      }
      if (proofOfPowerDeletion) {
        payload['proofOfPowerDeletion'] = proofOfPowerDeletion
      }
      if (blacklistExpirationDate) {
        payload['blacklistExpirationDate'] = blacklistExpirationDate
      }
      const endorsementMatchFound = endorsements?.some(endorsement => {
        return Boolean(endorsement?.type === type)
      });
      let docRef;
      if (
        endorsementMatchFound &&
        endorsementMatchFound?.status === status 
      ) {
        return `Approve or decline your old endorsement "${type}"`
      } else {
        const data = setPolicyInfoForEndorsement(payload, policyID)
        docRef = await addDoc(endosRef, data);
      }
      endoStatusChangeNotification(
        type,
        String(newCounter),
        status,
        docRef.id,
        policyID,
        userInfo.agencyID
      );
      const VINs = dataArray.map(
        (data) => Object.keys(data).find((key) => key.includes("VIN")) || ""
      );
      await updateSystemLog(
        auth,
        policyID,
        `${type} Endorsements (${VINs.join(", ")}) Submitted For ${
          insuredInfo.company
        } by ${auth.currentUser.displayName}`,
        `${type} Endorsement`,
        dataArray
      );
      await getEndorsements();
      return ''
    });
    return transactionResult
  } catch (error) {
    throw error;
  }
};
