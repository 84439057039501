import { doc, getDocs, updateDoc } from "firebase/firestore";
import { db } from "../../firebase";
import rateAllUnits from "./rateAllUnits";
import getSurplusLinesRate from "./premiumCalculation/getSurplusLinesRate";
import getStampFee from "./premiumCalculation/getStampFee";
import getTruckList from "./premiumCalculation/getTruckList";
import getTaxSchedule from "./getTaxSchedule";
import calculateFeesAndTaxes from "./calculateFeesAndTaxes";

const updatePolicy = async (
  policyRef,
  selectedQuote,
  adjustmentPercentage,
  doNotRerate
) => {
  if (!doNotRerate) {
    await updateDoc(policyRef, {
      selectedQuote,
      alPremiums: {
        adjustment: adjustmentPercentage || 0,
      },
    });
  }
};

const calculatePremiums = async (
  policyID,
  formData,
  e,
  selectedQuote,
  adjustmentPercentage,
  doNotRerate,
  isEndo,
  endoID
) => {
  const policyRef = doc(db, "policy-applications", policyID);
  await updatePolicy(
    policyRef,
    selectedQuote,
    adjustmentPercentage,
    doNotRerate
  );

  const truckList = await getTruckList(policyID, formData);

  const [alAmount, , ] = await rateAllUnits(
    e,
    policyID,
    formData,
    truckList,
    adjustmentPercentage,
    false,
    doNotRerate,
    endoID,
    isEndo
  );

  const taxSchedule = await getTaxSchedule();
  const taxesAndFees = { ...taxSchedule };

  const debitOrCredit =
    formData?.alPremiums?.adjustmentDebitOrCredit || "Debit";
  const hasGLCoverage = formData?.hasGLCoverage;

  // TODO: Make this amount dynamic and able to be edited in the admin panel
  const glAmount = hasGLCoverage ? 2400 : 0;
const taxInfo = await calculateFeesAndTaxes(formData, alAmount, taxesAndFees, isEndo);
  const taxes = taxInfo.taxes;
  const alPremiums = {
    GWP: alAmount,
    otherFees: isEndo ? 0 : taxesAndFees.otherFees,
    processingFee: taxesAndFees.processingFee,
    stampFee: taxInfo.stampFee,
    surplusLinesTax: taxInfo.surplusLinesTax,
    adjustment: adjustmentPercentage || 0,
    debitOrCredit,
    ...taxes,
  };

  const glPremiums = {
    GWP: glAmount,
    processingFee: taxesAndFees.processingFee,
    stampFee: taxInfo.stampFee,
    surplusLinesTax: taxInfo.surplusLinesTax,
    ...calculateFeesAndTaxes(formData, glAmount, taxesAndFees, true, isEndo),
  };

  const totalALPremium =
    alAmount +
    (isEndo ? 0 : alPremiums.otherFees) +
    alPremiums.processingFeeAmount +
    alPremiums.stampTaxAmount +
    alPremiums.surplusLinesTaxAmount;
  const totalGLPremium =
    glAmount +
    glPremiums.processingFeeAmount +
    glPremiums.stampTaxAmount +
    glPremiums.surplusLinesTaxAmount;

  const updatedData = {
    rerateRequired: false,
    alPremiums: { ...alPremiums, totalPremium: totalALPremium },
    glPremiums:
      glAmount === 0 ? {} : { ...glPremiums, totalPremium: totalGLPremium },
    selectedQuote,
  };

  if (!doNotRerate) {
    await updateDoc(policyRef, updatedData);
  }

  const grandTotal = totalALPremium + totalGLPremium;

  const returnData = [
    { ...alPremiums, totalPremium: alAmount },
    { ...glPremiums, totalPremium: glAmount },
    grandTotal,
  ];
  return returnData;
};

export default calculatePremiums;
