import React, { useEffect, useState } from "react";
import {
  getDocs,
  collection,
  query,
  addDoc,
  orderBy,
  getDoc,
  doc,
  where,
  deleteDoc,
  Timestamp,
} from "firebase/firestore";
import { db, auth } from "../../../firebase";
import { useNavigate } from "react-router-dom";
import { UserAuth } from "../../../Context/AuthContent";
import useTranslations from "../../../Context/actions/useTranslations";
import parseAddress from "parse-address";
import ApiService from "../../../services/southern-api/apiService";
import BlueButton from "../../assets/forms/BlueButton";
import RedButton from "../../assets/forms/RedButton";
import {
  Edit as EditIcon,
  Close as CloseIcon,
  HourglassBottom as HourglassBottomIcon,
} from "@mui/icons-material";
import Popup from "../../generals/Popup";
import { APPLICATION_ROUTE, POLICY_ROUTE } from "../../../utils/routes";
import renewPolicy from "../../utils/renewPolicy";
import useUsersState from "../../../Context/actions/useUsersState";
import useGeneralState from "../../../Context/actions/useGeneralState";
import AddNewInsuredModal from "./AddNewInsuredModal";
import updateSystemLog from "../../utils/updateSystemLog";

function AddInsured(props) {
  const { user } = UserAuth();
  const { user: userState } = useUsersState();
  const userID = user.uid;
  const setShowCreatePolicyPanel = props.setShowCreatePolicyPanel;
  const customText = props.customText || null;
  const customFunction = props.customFunction;
  const agencyID = props.agencyID;
  const useIDAsValue = props.useIDAsValue || false;

  const { t } = useTranslations();
  const { controlToast } = useGeneralState();

  const [display, setDisplay] = useState(false);
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [formData, setFormData] = useState({});

  const handleChange = (event) => {
    const { name, value } = event.target;

    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const [dotNumberForAdd, setDotNumberForAdd] = useState("");
  const [selectedInfo, setSelectedInfo] = useState({});
  const [customerAdded, setCustomerAdded] = useState(false);

  const handleChangeDot = (event) => {
    const { value } = event.target;
    setDotNumberForAdd(value);
  };

  const AddCustomer = async (e) => {
    try {
      e.preventDefault();
      const insuredsRef = collection(
        db,
        "agencies",
        props.agencyID,
        "insureds"
      );
      await addDoc(insuredsRef, {
        ...formData,
        author: {
          id: auth.currentUser.uid,
          name: auth.currentUser.displayName,
        },
        dateCreated: Date.now()
      });
      setShow(false);
      setFormData({});
      setCustomerAdded(true);
      setGotCompanies(false);
      getCompanies();
    } catch (error) {
      console.log(error);
    }
  };

  let navigate = useNavigate();
  const [companyList, setCompanyList] = useState([]);

  const [gotCompanies, setGotCompanies] = useState(false);
  const getCompanies = async () => {
    try {
      setGotCompanies(false);
      const companiesCollectionRef = collection(
        db,
        "agencies",
        props.agencyID,
        "insureds"
      );
      const data = await getDocs(
        query(companiesCollectionRef, orderBy("company"))
      );
      setCompanyList(data.docs.map((doc) => ({ ...doc.data(), id: doc.id })));
      setGotCompanies(true);
    } catch (error) {
      console.error(error);
    }
  };

  const [companyInfoUpdated, setCompanyInfoUpdated] = useState(false);
  const [loading, setLoading] = useState(false);
  const fetchCompanyInfo = async (e, dot) => {
    e.preventDefault();
    setLoading(true);

    let q = query(
      collection(db, "agencies", agencyID, "insureds"),
      where("dot", "==", dotNumberForAdd)
    );

    const querySnapshot = await getDocs(q);
    const values = [];
    querySnapshot.forEach((doc) => {
      values.push(doc.data);
      alert("An insured already exists with that DOT number.");
      setDotNumberForAdd("");
      setLoading(false);
      return;
    });

    const apiService = new ApiService("southern");

    const myInfo = { dotNumber: dot };
    if (!values.length > 0) {
      try {
        const data = await apiService.addNewInsured(myInfo);
        const parsedMailingAddress = parseAddress.parseLocation(
          data.mailingAddress
        );
        const parsedPhysicalAddress = parseAddress.parseLocation(
          data.physicalAddress
        );
        setFormData({
          ...formData,
          company: data.legalName,
          dba: data.dbaName,
          dot: data.dotNumber,
          mc: data.mcNumber,
          phone: data.phone,
          mailingAddressOneLine: data.mailingAddress,
          physicalAddressOneLine: data.physicalAddress,
          mailingAddress: (
            (parsedMailingAddress?.number || "") +
            (parsedMailingAddress?.prefix
              ? " " + parsedMailingAddress.prefix
              : "") +
            " " +
            (parsedMailingAddress?.street || "") +
            " " +
            (parsedMailingAddress?.type || "")
          ).toUpperCase(),
          mailingCity: parsedMailingAddress?.city.toUpperCase(),
          mailingState: parsedMailingAddress?.state.toUpperCase(),
          mailingZip: parsedMailingAddress?.zip,
          physicalAddress: (
            (parsedPhysicalAddress?.number || "") +
            (parsedPhysicalAddress?.prefix
              ? " " + parsedPhysicalAddress.prefix
              : "") +
            " " +
            (parsedPhysicalAddress?.street || "") +
            " " +
            (parsedPhysicalAddress?.type || "")
          ).toUpperCase(),
          physicalCity: parsedPhysicalAddress?.city.toUpperCase(),
          physicalState: parsedPhysicalAddress?.state.toUpperCase(),
          physicalZip: parsedPhysicalAddress?.zip,
        });
        setCompanyInfoUpdated(true);
        handleShow();
        setLoading(false);
        setDotNumberForAdd("");
      } catch (error) {
        console.error("Error fetching company info:", error);
        alert("Failed to fetch company info. Please try again.");
        setLoading(false);
      }
    }
  };

  const [newApplicationID, setNewApplicationID] = useState("");
  const [newPolicyID, setNewPolicyID] = useState("");
  const [payload, setPayload] = useState(null);
  const [openModalApplication, setOpenModalApplication] = useState(false);
  const [openModalPolicy, setOpenModalPolicy] = useState(false);

  const generateNewApplication = async (insuredInfo) => {
    const info = insuredInfo ? insuredInfo : payload?.insuredInfo;
    const insuredID = info.id;
    setSelectedInfo(info);
    props.setShowVerification(true);
    const docRef = await addDoc(collection(db, "policy-applications"), {
      authorID: auth.currentUser.uid,
      author: auth.currentUser.displayName,
      createdOn: new Date(),
      documentType: "Application",
      status: "Unsubmitted",
      underwritten: false,
      insuredID,
      agencyID,
      hasGLCoverage: false,
      hasALCoverage: false,
      hasAPDCoverage: false,
      hasMTCCoverage: false,
    });
    setNewApplicationID(docRef.id);
    return docRef.id;
  };

  const lookForApplication = async (insuredInfo) => {
    try {
      const insuredCollection = collection(
        db,
        "agencies",
        agencyID,
        "insureds"
      );
      let q = query(insuredCollection, where("dot", "==", insuredInfo.dot));
      const querySnapshot = await getDocs(q);
      const date = new Date(
        new Date().setFullYear(new Date().getFullYear() - 1)
      );
      const oneYearAgo = Timestamp.fromDate(date);
      const applicationRef = collection(db, "policy-applications");
      let applicationQuery = query(
        applicationRef,
        where("documentType", "==", "Application")
      );
      applicationQuery = query(
        applicationQuery,
        where("agencyID", "==", agencyID)
      );
      applicationQuery = query(
        applicationQuery,
        where("insuredID", "==", insuredInfo.id)
      );
      applicationQuery = query(
        applicationQuery,
        where("createdOn", ">", oneYearAgo)
      );
      let thereIsAnApplication = false;
      for (const _ of querySnapshot.docs) {
        const applicationQuerySnapshot = await getDocs(applicationQuery);
        if (!applicationQuerySnapshot.empty) {
          applicationQuerySnapshot.forEach((doc) => {
            setNewApplicationID(doc.id);
            thereIsAnApplication = true;
          });
          break;
        }
      }
      setDotNumberForAdd("");
      setPayload({ insuredInfo });
      return thereIsAnApplication;
    } catch (error) {
      console.error("Error looking for application:", error);
    }
  };

  const lookForPolicy = async (insuredInfo) => {
    try {
      const insuredCollection = collection(
        db,
        "agencies",
        agencyID,
        "insureds"
      );
      let q = query(insuredCollection, where("dot", "==", insuredInfo.dot));
      const querySnapshot = await getDocs(q);

      const date = new Date();
      const sixtyDaysAhead = new Date(date.setDate(date.getDate() + 60));
      const oneYearAgo = Timestamp.fromDate(
        new Date(new Date().setFullYear(new Date().getFullYear() - 1))
      );
      const sixtyDaysBeforeExpiration = Timestamp.fromDate(sixtyDaysAhead);

      const policyRef = collection(db, "policy-applications");
      let policyQuery = query(policyRef, where("documentType", "==", "Policy"));
      policyQuery = query(policyQuery, where("agencyID", "==", agencyID));
      policyQuery = query(policyQuery, where("status", "==", "In-Force"));
      policyQuery = query(
        policyQuery,
        where("insuredID", "==", insuredInfo.id)
      );
      policyQuery = query(
        policyQuery,
        where("effectiveDate", "<", sixtyDaysBeforeExpiration)
      );
      policyQuery = query(policyQuery, where("effectiveDate", ">", oneYearAgo));
      let thereIsAnPolicy = false;
      for (const _ of querySnapshot.docs) {
        const policyQuerySnapshot = await getDocs(policyQuery);
        if (!policyQuerySnapshot.empty) {
          policyQuerySnapshot.forEach((doc) => {
            setNewPolicyID(doc.id);
            thereIsAnPolicy = true;
          });
          break;
        }
      }
      setDotNumberForAdd("");
      setPayload({ insuredInfo });
      return thereIsAnPolicy;
    } catch (error) {
      console.error("Error looking for policy:", error);
    }
  };

  const handleRenewPolicy = async (e) => {
    try {
      const docRef = doc(db, "policy-applications", newPolicyID);
      const docSnap = await getDoc(docRef);
      const formData = docSnap.data();
      setLoading(true);
      await renewPolicy(
        e,
        newPolicyID,
        () => {},
        formData,
        user,
        userState,
        payload.insuredInfo
      );
      const newUrl = `${window.location.origin}${POLICY_ROUTE}/${newPolicyID}`;
      window.open(newUrl, "_blank");
    } catch (error) {
      controlToast(true, "Error renewing policy", "error");
    }
  };

  const createNewApplication = async (e) => {
    if (!window.confirm("Are you sure you want to create a new application?")) {
      return;
    }
    const insuredInfo = { ...companyList[e.target.value] };
    if (!insuredInfo?.id) {
      setOpenModalApplication(false);
      setOpenModalPolicy(false);
      setShowCreatePolicyPanel(false);
      setSelectedInfo({});
      return;
    } else {
      const thereIsAnApplication = await lookForApplication(insuredInfo);
      if (thereIsAnApplication) {
        setLoading(false);
        setOpenModalApplication(true);
      } else {
        try {
          setLoading(true);
          const thereIsAnPolicy = await lookForPolicy(insuredInfo);
          if (thereIsAnPolicy) {
            setLoading(false);
            setOpenModalPolicy(true);
          } else {
            const newApplicationId = await generateNewApplication(insuredInfo);
            updateSystemLog(
              auth,
              newApplicationId,
              "New Application Created for " + insuredInfo.company + ".",
              "New Application Created",
              { agencyID, newApplicationId }
            );
            setLoading(false);
          }
        } catch (error) {
          console.error("Error looking for policy:", error);
        }
      }
    }
  };

  const resetPage = (e) => {
    setDotNumberForAdd("");
    setCustomerAdded(false);
    setSelectedInfo({});
  };

  const handleDeleteApplication = async (id) => {
    try {
      const documentRef = doc(db, "policy-applications", id);
      await deleteDoc(documentRef);
      console.log(`Document with ID ${id} deleted successfully.`);
    } catch (error) {
      return console.error("Error deleting document:", error);
    }
    alert("Blank Application Successfully Deleted");
    setShowCreatePolicyPanel(false);
  };

  useEffect(() => {
    if (userID) {
      !gotCompanies && getCompanies();
    }
  }, [db, customerAdded, companyInfoUpdated, loading, gotCompanies, userID]);

  return (
    <div>
      <Popup
        maxWidth="50%"
        isOpen={openModalApplication}
        onRequestClose={() => {
          setOpenModalApplication(false);
          setShowCreatePolicyPanel(false);
          setSelectedInfo({});
        }}
      >
        <h2 className="text-xl font-bold mt-2 mb-4">
          An insured already exists with that DOT number in this application.
        </h2>
        <div className="flex flex-wrap justify-between ">
          <BlueButton
            text="Navigate to application"
            onClickFunction={async (e) => {
              e?.preventDefault();
              navigate(`${APPLICATION_ROUTE}/${newApplicationID}`);
            }}
            icon={<EditIcon />}
            className="w-[100%] max-w-[285px] mb-1"
          />
          <RedButton
            text="Delete Application & Start Over"
            onClickFunction={async () => {
              await handleDeleteApplication(newApplicationID);
              setShowCreatePolicyPanel(false);
            }}
            className="w-[100%] max-w-[285px] mb-1"
          />
        </div>
      </Popup>
      <Popup
        maxWidth="50%"
        isOpen={openModalPolicy}
        onRequestClose={() => {
          setOpenModalPolicy(false);
          setShowCreatePolicyPanel(false);
          setSelectedInfo({});
        }}
      >
        <h2 className="text-xl font-bold mt-2 mb-4">
          An insured already exists with that DOT number in this policy.
        </h2>
        <div className="flex flex-wrap justify-between ">
          <BlueButton
            text={loading ? "Renewing Policy..." : "Renew Policy"}
            onClickFunction={handleRenewPolicy}
            icon={loading ? <HourglassBottomIcon /> : <EditIcon />}
            disabled={loading}
            className="w-[100%] max-w-[285px] mb-1"
          />
          <RedButton
            text="Close"
            onClickFunction={async () => {
              setOpenModalPolicy(false);
              setShowCreatePolicyPanel(false);
              setSelectedInfo({});
            }}
            icon={<CloseIcon />}
            className="w-[100%] max-w-[285px] mb-1"
          />
        </div>
      </Popup>
      {props.showVerification ? (
        <section>
          <p className="text-center">
            {t("manage-applications.successfully-created")}
          </p>
          <p className="text-center text-lg">{selectedInfo.company}</p>
          <p className="text-center text-lg">{selectedInfo.address}</p>
          <p className="text-center text-lg">
            {selectedInfo.city}, {selectedInfo.state} {selectedInfo.zip}
          </p>
          <p className="text-center">
            {t("manage-applications.continue-to-editing")}
          </p>

          <div className="flex flex-col space-y-4">
            <button
              className="submit-button"
              onClick={(e) => navigate("/application/" + newApplicationID)}
            >
              {t("manage-applications.continue")}
            </button>
            <button
              type="button"
              className="bg-[#8B0000] hover:bg-red-700 text-white py-2 px-4 rounded"
              onClick={() => handleDeleteApplication(newApplicationID)}
            >
              {t("manage-applications.delete-application")}
            </button>
          </div>
        </section>
      ) : (
        <section>
          <p className="mb-3">
            <p className="text-left">
              {customText || t("manage-applications.select-or-add")}
            </p>
          </p>
          <div className="mt-2">
            <h1 className="text-[#072a48] text-lg">
              {t("manage-applications.select-an-insured")}
            </h1>
            <select
              className="form-control rounded-md font-medium border-2 p-2 mt-2 sm:text-lg md:text-lg"
              onChange={customFunction || createNewApplication}
            >
              <option value="">{t("manage-applications.choose-one")}</option>
              {companyList.map((company, i) => {
                if (company.company) {
                  return (
                    <option key={"id" + company.id} value={useIDAsValue ? company.id + '-' + company.company : i}>
                      {company.company.substring(0, 25)}
                    </option>
                  );
                }
              })}
            </select>

            <>
              {customerAdded && (
                <div>
                  <p className="my-2 text-lg text-green-600 font-[600]">
                    {t("manage-applications.customer-successfully-added")}
                  </p>
                  <button
                    onClick={(e) => resetPage(e)}
                    className="text-[#072a48]"
                  >
                    {t("manage-applications.add-another-customer")}
                  </button>
                </div>
              )}
              {!customerAdded && (
                <form onSubmit={(e) => fetchCompanyInfo(e, dotNumberForAdd)}>
                  <h1 className="text-[#072a48] text-lg mt-3">
                    {t("manage-applications.or-add-an-insured")}
                  </h1>
                  <input
                    required
                    className="form-control rounded-md border-2 p-2 mt-2 sm:text-lg md:text-lg"
                    placeholder="Enter DOT Number"
                    type="text"
                    value={dotNumberForAdd}
                    onChange={handleChangeDot}
                  />
                  {!loading ? (
                    <div className="flex flex-col space-y-4">
                      <button className="submit-button" type="submit">
                        {t("manage-applications.add-a-customer")}
                      </button>
                      <button
                        type="button"
                        className="bg-[#8B0000] hover:bg-red-700 text-white py-2 px-4 rounded"
                        onClick={() => {
                          setShowCreatePolicyPanel(false);
                          setSelectedInfo({});
                        }}
                      >
                        {t("manage-applications.cancel")}
                      </button>
                    </div>
                  ) : (
                    <button
                      disabled
                      className="submit-button"
                      onClick={() => fetchCompanyInfo(dotNumberForAdd)}
                    >
                      {t("manage-applications.loading")}
                    </button>
                  )}
                </form>
              )}

              <AddNewInsuredModal
                show={show}
                handleClose={handleClose}
                handleChange={handleChange}
                formData={formData}
                AddCustomer={AddCustomer}
              />
            </>
          </div>
        </section>
      )}
    </div>
  );
}

export default AddInsured;
