import React, { useState } from "react";
import { Typography, Box, useMediaQuery } from "@mui/material";
import ApprovalButtons from "../../assets/forms/ApprovalButtons";
import Renew from "./Renew";
import NonRenew from "./NonRenew";
import Popup from "../../generals/Popup";

const RenewPolicyModal = ({ open, onClose, policyID, formData, insuredInfo }) => {
  const isMobileScreen = useMediaQuery("(max-width: 600px)");

  const [renew, setRenew] = useState(false);
  const [nonRenew, setNonRenew] = useState(false);

  return (
    <Popup
      isOpen={open}
      onRequestClose={onClose}
      maxWidth={isMobileScreen ? "100%" : "400px"}
      zIndex={1000}
      contentLabel="Renew Policy"
    >
      <Box
        sx={{
          maxHeight: "100vh",
          overflowY: "auto",
          width: "100%",
          borderRadius: isMobileScreen ? 0 : 4,
        }}
      >
        {!renew && !nonRenew && (
          <>
            <Typography className="mt-2" variant="h6" gutterBottom>
              Renewal Options
            </Typography>
            <p className="mb-2">
              Here you may mark a policy as non-renewal or begin the renewal process.
            </p>
            <p className="mb-3">
              Beginning the renewal process will mirror the current policy information into a new application.
            </p>
            {formData.status !== "Renewal" && (
              <ApprovalButtons
                onApprove={() => setRenew(true)}
                onDecline={onClose}
                titleAccept={"Renew Policy"}
                titleDecline={"Cancel"}
                disabledAccept={false}
                disabledDecline={false}
                handleAsSubmit={false}
                dissapearDecline={true}
              />
            )}
            {!formData?.nonRenewal && (
              <ApprovalButtons
                onApprove={() => {}}
                onDecline={() => setNonRenew(true)}
                titleAccept={"Doesn't matter"}
                titleDecline={"Mark As Non-Renewal"}
                disabledAccept={false}
                disabledDecline={false}
                handleAsSubmit={false}
                dissapearAccept={true}
              />
            )}
            <ApprovalButtons
              onApprove={() => {}}
              onDecline={onClose}
              titleAccept={"Doesn't matter"}
              titleDecline={"Cancel"}
              disabledAccept={false}
              disabledDecline={false}
              handleAsSubmit={false}
              dissapearAccept={true}
            />
          </>
        )}
        {renew && (
          <Renew
            onClose={onClose}
            policyID={policyID}
            setRenew={setRenew}
            insuredInfo={insuredInfo}
            formData={formData}
          />
        )}
        {nonRenew && (
          <NonRenew onClose={onClose} policyID={policyID} setNonRenew={setNonRenew} />
        )}
      </Box>
    </Popup>
  );
};

export default RenewPolicyModal;
