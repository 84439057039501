export const HOME_ROUTE = '/'
export const SIGN_IN_ROUTE = '/signin'

export const LOSS_RUNS_ROUTE = '/lossruns'
export const LOSS_RUNS_LIST_ROUTE = `${LOSS_RUNS_ROUTE}/lossruns-list`

export const SIGN_UP_ROUTE = '/signup'
export const PASSWORD_ROUTE = '/password'
export const NEW_APPLICATION_ROUTE = '/new-application'
export const NEW_APPLICATION_ID_ROUTE = `${NEW_APPLICATION_ROUTE}/:paramRefID`

export const AGENT_ROUTE = '/agent'
export const AGENT_COMPANY_PROFILE_ROUTE = `${AGENT_ROUTE}/agency-profile`
export const AGENT_MANAGE_POLICIES_ROUTE = `${AGENT_ROUTE}/manage-policies`
export const AGENT_MANAGE_APPLICATIONS_ROUTE = `${AGENT_ROUTE}/manage-applications`
export const AGENT_MANAGE_USERS_ROUTE = `${AGENT_ROUTE}/manage-users`
export const AGENT_MANAGE_CLIENTS_ROUTE = `${AGENT_ROUTE}/manage-clients`
export const AGENT_MANAGE_NOTIFICATIONS_ROUTE = `${AGENT_ROUTE}/manage-notifications`

export const ADMIN_ROUTE = '/admin'

export const FINANCES_ROUTE = '/finance'
export const CONVERTER_ROUTE = '/converter'
export const LOSS_CONTROL_ROUTE = '/loss-control'

export const BLACKLIST_ROUTE = '/blacklist'
export const DRIVERS_TRAININGS_ROUTE = '/drivers-trainings'

export const CLIENT_ROUTE = '/client'

export const UNDERWRITING_ROUTE = '/underwriting'
export const UNDERWRITING_ENDORSEMENT_ROUTE = `${UNDERWRITING_ROUTE}/endorsements`
export const UNDERWRITING_ENDORSEMENT_REF_ID_ROUTE = `${UNDERWRITING_ENDORSEMENT_ROUTE}/:paramRefID/:paramPolicyID`

export const Y_ROUTE = '/y'

export const APPOINTMENT_APPLICATION_ROUTE = '/appointment-application'
export const APPOINTMENT_APPLICATION_REF_ROUTE = `${APPOINTMENT_APPLICATION_ROUTE}/:paramRefID`


export const SUBMIT_BUG_ROUTE = '/submit-bug-report'
export const VIEW_BUGS_ROUTE = '/view-bug-reports'

export const APPLICATION_ROUTE = '/application'
export const APPLICATION_ID_ROUTE = `${APPLICATION_ROUTE}/:paramRefID`

export const POLICY_ROUTE = '/policy'
export const POLICY_REF_ROUTE = `${POLICY_ROUTE}/:paramRefID`

export const TEMPORARILY_ROUTE = `/temporarily`
export const TEMPORARILY_REF_ROUTE = `${TEMPORARILY_ROUTE}/:paramRefID`

export const TEMPORARILY_CLIENT_ROUTE = `/temporarily-client`
export const TEMPORARILY_CLIENT_REF_ROUTE = `${TEMPORARILY_CLIENT_ROUTE}/:paramRefID`

export const SUBMIT_BUSINESS_ROUTE = '/submit-business'
export const SUBMIT_BUSINESS_REF_SUBMISSION_ROUTE = `${SUBMIT_BUSINESS_ROUTE}/:paramRefID/:paramSubmissionID`

export const VIEW_APPLICATION_ROUTE = '/view-application'
export const VIEW_APPLICATION_REF_ROUTE = `${VIEW_APPLICATION_ROUTE}/:paramRefID`

export const SEND_LETTERS_ROUTE = '/send-letters'

export const CONTRACT_ROUTE = '/contract'
export const CONTRACT_REF_ROUTE = `${CONTRACT_ROUTE}/:paramRefID`

export const SCT_ROUTE = '/sct'

export const W9_ROUTE = '/w9'
export const W9_REF_ROUTE = `${W9_ROUTE}/:paramRefID`

export const AGENT_PROFILE_ROUTE = '/agent-profile'
export const AGENT_PROFILE_REF_ROUTE = `${AGENT_PROFILE_ROUTE}/:paramAgentID/:paramAgencyID`

export const ACCOUNT_ROUTE = '/account'
export const USER_DETAILS_ROUTE = '/user-details'

export const ABSENCE_ROUTE = '/absence'
export const LICONA_SIGN_IN_ROUTE = '/licona'

export const OLD_AGENT_DIRECTORY_ROUTE = '/old-agent-directory'
export const AGENT_DIRECTORY_ROUTE = '/agent-directory'
export const UNDERWRITER_DIRECTORY_ROUTE = '/underwriter-directory'
export const NEW_SUPPORT_TICKET_ROUTE = '/new-support-ticket'
export const SUPPORT_TICKETS_LIST_ROUTE = `${NEW_SUPPORT_TICKET_ROUTE}/support-tickets-list`
export const CHANGE_LOG_ROUTE = '/changelog'
export const LETTER_LOG_ROUTE = '/letterlog'
export const FILING_LOG_ROUTE = '/filinglog'
export const SYSTEM_LOG_ROUTE = '/systemlog'

export const PROOF_OF_FILINGS_ROUTE = '/proof-of-filings'

export const PROPOSAL_ROUTE = '/proposal'
export const PROPOSAL_REF_COMPANY_ROUTE = `${PROPOSAL_ROUTE}/:paramRefID/:paramCompanyID`

export const ABSOLUTE_HOME_ROUTE = "https://www.southernstarmga.com"

export const CALENDAR_ROUTE = "/calendar"
export const HELP_ROUTE = "/help"

export const MAINTENANCE_ROUTE = "/maintenance"