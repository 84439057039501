import { t } from "i18next";
import TruckModal from "./TruckModal";
import DynamicTable from "../../assets/lists/DynamicTable";
import FormPopup from "../../generals/Popup/FormPopup";
import { useState } from "react";
import { operationRadiusOptions } from "../../../utils/constants";
import { ratePowerUnitGeneral } from "../../utils/rateAllUnits";
import useGeneralState from "../../../Context/actions/useGeneralState";
import { useParams } from "react-router-dom";
import ApprovalButtons from "../../assets/forms/ApprovalButtons";
import { setPolicyInfo } from "../Drivers/setPolicyInfo";
import { doc, setDoc } from "firebase/firestore";
import { db } from "../../../firebase";

export default function TruckList(props) {
  const {
    formData,
    columns,
    tableData,
    showItem,
    truckList,
    selectedTruck,
    infoModalIsOpen,
    afterInfoModalOpen,
    closeInfoModal,
    filterKeys,
    handleSubmit,
    formSettings,
    openModal,
    handleCloseModal,
    deletionMode,
    modalSubmitting,
    tableDataInDeletionMode,
    setTableDataInDeletionMode,
    tableDataShowingDeletion,
    setTableDataShowingDeletion,
    isAtLeastOneSelectedInDeletedMode,
    setDeletionMode,
    insuredInfo,
    userInfo,
  } = props;

  const { paramRefID } = useParams();
  const [bodyEditMode, setBodyEditMode] = useState(null);
  const [isEditModeSubmitting, setIsEditModeSubmitting] = useState(false);
  const { controlToast } = useGeneralState();

  const handleOpenEditMode = (e, body) => {
    e.preventDefault();
    setBodyEditMode(body);
  };

  const handleCloseEditMode = () => {
    setBodyEditMode(null);
  };

  const handleSubmitEditMode = async (e, data) => {
    const bodyEditModeValues = JSON.parse(JSON.stringify(bodyEditMode));
    e.preventDefault();
    setIsEditModeSubmitting(true);
    try {
      delete bodyEditModeValues["iconLeft"];
      delete bodyEditModeValues["iconRight"];
      delete bodyEditModeValues["tableInfo"];
      delete bodyEditModeValues["dataIndex"];
      bodyEditModeValues["truckRadius"] = data["truckRadius"];
      if (data["effective-date"]) {
        bodyEditModeValues["effectiveDate"] = data["effective-date"];
      }
      const oldTruckMode = truckList.find(
        (elem) => elem.id === bodyEditModeValues.id
      );
      const policyDetailToShow = oldTruckMode?.policiesDetails?.find(
        (detail) => detail?.id === paramRefID
      );
      const oldRadius = policyDetailToShow?.truckRadius;
      bodyEditModeValues["oldRadius"] = oldRadius;

      const payload = setPolicyInfo(
        { ...bodyEditModeValues },
        {
          ...formData,
          policyEffectiveDate: formData?.effectiveDate,
          status: "Pending",
        },
        "endo-addition"
      );

      const collectionRef = doc(
        db,
        "agencies",
        formData?.agencyID,
        "insureds",
        formData?.insuredID,
        "power-units",
        bodyEditMode?.id
      );
      await setDoc(
        collectionRef,
        {
          payload,
        },
        { merge: true }
      );

      await ratePowerUnitGeneral(
        e,
        [{ ...payload }],
        paramRefID,
        null,
        null,
        null,
        insuredInfo,
        userInfo
      );
      controlToast(
        true,
        formData?.documentType === "Policy"
          ? "You create an endorsement successfully to alter the rate"
          : "You changed the operational radius of the truck successfully",
        "success"
      );
      setIsEditModeSubmitting(false);
      setBodyEditMode(null);
    } catch (error) {
      console.log("error", error);
      setIsEditModeSubmitting(false);
      throw error;
    }
  };

  const formSettingsEditMode = () => {
    let formEditModeInput = [
      {
        label: "Operational Radius",
        type: "select",
        name: "truckRadius",
        options: operationRadiusOptions,
        required: true,
      },
    ];
    if (formData?.documentType === "Policy") {
      formEditModeInput.unshift({
        label: "Effective Date",
        type: "date",
        name: "effective-date",
        minDate: new Date(),
        required: true,
      });
    }
    return {
      onSubmit: handleSubmitEditMode,
      inputs: formEditModeInput,
      buttonLabel:
        formData?.documentType === "Policy"
          ? "Create Endorsement"
          : "Submit Edition",
    };
  };

  const formSettingsEdit = formSettingsEditMode();

  const selectedElem = truckList.find((elem) => elem.id === selectedTruck);

  return (
    <div>
      <TruckModal
        truck={selectedElem}
        isOpen={infoModalIsOpen}
        onClose={closeInfoModal}
        contentLabel={t("Power Unit Information")}
        formData={formData}
        policyID={paramRefID}
      />
      <FormPopup
        isOpen={openModal}
        onRequestClose={handleCloseModal}
        title="Select the expiration date of the truck"
        formSettings={formSettings}
        loading={modalSubmitting}
      />
      <FormPopup
        isOpen={Boolean(bodyEditMode)}
        onRequestClose={handleCloseEditMode}
        title={`Adjust Operational Radius`}
        formSettings={formSettingsEdit}
        loading={isEditModeSubmitting}
      />
      <DynamicTable
        deletionMode={deletionMode}
        formData={formData}
        columns={columns}
        tableData={!deletionMode ? tableData : tableDataShowingDeletion}
        setTableData={!deletionMode ? () => {} : setTableDataShowingDeletion}
        alternativeTableData={!deletionMode ? [] : tableDataInDeletionMode}
        setAlternativeTableData={
          !deletionMode ? () => {} : setTableDataInDeletionMode
        }
        deleteThisItem={handleSubmit}
        onClickItem={(e, id, itemIndex) => {
          showItem(e, id, itemIndex);
        }}
        filterKeys={filterKeys}
        handleOpenEditMode={handleOpenEditMode}
      />
      {deletionMode ? (
        <ApprovalButtons
          onApprove={(e) => {
            handleSubmit(e, "multiple");
          }}
          onDecline={(e) => {
            e.preventDefault();
            setTableDataInDeletionMode([]);
            setTableDataShowingDeletion([]);
            setDeletionMode(!Boolean(deletionMode));
          }}
          titleAccept="Submit"
          titleDecline="Cancel"
          disabledAccept={!isAtLeastOneSelectedInDeletedMode}
        />
      ) : null}
      {deletionMode ? (
        <DynamicTable
          deletionMode={deletionMode}
          formData={formData}
          deleteMode={true}
          columns={columns}
          tableData={tableDataInDeletionMode}
          setTableData={setTableDataInDeletionMode}
          alternativeTableData={tableDataShowingDeletion}
          setAlternativeTableData={setTableDataShowingDeletion}
          deleteThisItem={handleSubmit}
          onClickItem={showItem}
          filterKeys={filterKeys}
        />
      ) : null}
    </div>
  );
}
