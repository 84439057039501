import React, { useMemo, useState } from "react";
import TrailerList from "./TrailerList";
import AddTrailer from "./AddTrailer";
import BlueButton from "../../assets/forms/BlueButton";
import RedButton from "../../assets/forms/RedButton";
import ImportTrailers from "./ImportTrailers";
import Breadcrumbs from "../../assets/Breadcrumbs";
import { isExpired } from "../../../utils/helpers";
import useTrailersLists from "./useTrailersList";
import { useMediaQuery } from "@mui/material";
import { generateXlsxFile } from "../../../utils/excelCreator";
import {
  Download as DownloadIcon,
  IosShare as IosShareIcon,
} from "@mui/icons-material";
import { filterRightPoliciesDetails } from "../Drivers/setPolicyInfo";
import DropdownMultiUpload from "../../assets/forms/DynamicPropertiesInputs/DropdownMultiUpload";
import { handleDropdown } from "../../assets/lists/DynamicTable/utils";
import ImportAllLists from "../Drivers/ImportAllLists";
import useTranslations from "../../../Context/actions/useTranslations";
import ImportInspas from "../Drivers/ImportInspas";
import ApiService from "../../../services/southern-api/apiService";
import ShareModal from "../../ShareModal";
import { useLocation } from "react-router-dom";

export default function PolicyTrailers(props) {
  const {
    formData,
    policyID,
    insuredInfo,
    userInfo,
    trailerList,
    isEndoManager,
    getTrailerList,
    rerateRequired,
    setRerateRequired,
    setDidSuccess,
    emailValue
  } = props;

  const [display, setDisplay] = useState(true);
  const [showAddTrailer, setShowAddTrailer] = useState(false);
  const [showTable, setShowTable] = useState(true);
  const [showImport, setShowImport] = useState(false);
  const trailerHook = useTrailersLists(props);
  const { setDeletionMode, handleSubmit, deletionMode } = trailerHook;

  const breadcrumbs = [{ label: formData.documentType }, { label: "Trailers" }];

  const count = useMemo(() => {
    const effectiveElementsList = trailerList.filter((item) => {
      return !isExpired(item?.expirationDate);
    });
    const counter = effectiveElementsList?.length
      ? effectiveElementsList.length
      : 0;
    return counter;
  }, [trailerList]);

  const isMobileScreen = useMediaQuery("(max-width: 600px)");

  const activeList = trailerList?.filter(elem => Boolean(
    filterRightPoliciesDetails(elem.policiesDetails, policyID)
      ?.status === 'Active'
  ))

  const handleImportFn = () => {
    setShowImport(true);
    setShowTable(false);
  }

  const [showImportAll, setShowImportAll] = useState(false)
  const [showImportInspas, setShowImportInspas] = useState(false)

  const handleImportAll = () => {
    setShowImportAll(true)
    setShowTable(false);
  }

  const { t } = useTranslations()

  const titleDocumentType = formData?.documentType || ''

  const location = useLocation()
  const isTemporalUrl = location?.pathname?.includes('temporarily')
  const isTemporalUrlClient = location?.pathname?.includes("temporarily-client");

  const apiService = new ApiService()
  const handleFinishOperation = async () => {
    try {
      await apiService.renewalProcessModuleUpdateConfirmation({
        policyData: formData,
        module: 'trailers',
        emails: [emailValue],
      })
      setDidSuccess(true)
    } catch (error) {
      console.error(error)
    }
  }

  const handleImportInspas = () => {
    setShowImportInspas(true)
    setShowTable(false);
  }

  const [openShareModal, setOpenShareModal] = useState(false)

  return (
    <>
      <ShareModal
        insuredInfo={insuredInfo}
        formData={formData}
        openShareModal={openShareModal}
        setOpenShareModal={setOpenShareModal}
        module={'trailers'}
      />
      {!showAddTrailer && !showImport && (
        <header>
          {!isEndoManager && !isTemporalUrl ? <Breadcrumbs items={breadcrumbs} /> : null}
          <h1
            className={`${
              isEndoManager
                ? "text-2xl mt-2.5 font-bold md:mb-0"
                : "text-3xl mt-2 font-semibold md:mb-0"
            }`}
          >
            {!isEndoManager
              ? `${
                  deletionMode ? "Delete" : `${titleDocumentType} Trailers`
                } ${deletionMode ? "" : `(${count})`}`
              : `${
                  deletionMode ? "Delete" : "Endorse Trailers  "
                }`}
          </h1>
          <p className="my-3">
            {(formData.documentType === "Application" ||
              formData.documentType === "Policy") &&
              !deletionMode && (
                <>Here you may view, add, and remove trailers.</>
              )}
            {deletionMode && (
              <p>
                Select trailers from the bottom list to add to the deletion list. Click submit once you have selected all necessary trailers.
              </p>
            )}
            {formData.documentType !== "Application" &&
              formData.documentType !== "Policy" && (
                <>Trailers may not be edited during the quoting phase.</>
              )}
          </p>
        </header>
      )}
      {display && (
        <div className={`card-body ${isEndoManager ? "p-0" : ""}`}>
          {!showAddTrailer && !showImport && !showImportAll && (
            <>
              {(props.formData.documentType === "Application" ||
                props.formData.documentType === "Policy") && (
                <div className="my-3 flex items-center flex-wrap">
                  <BlueButton
                    text="Add Trailer"
                    onClickFunction={(e) => {
                      setShowAddTrailer(true);
                      setShowTable(false);
                    }}
                    disabled={deletionMode}
                    hasContentToBottom={true}
                    hasContentToRight={!isMobileScreen}
                    className={isMobileScreen ? "w-[100%]" : ""}
                  />
                  {!isTemporalUrl ?
                  <>
                    <DropdownMultiUpload
                      containerStyle={`bg-transparent border-none p-0
                        ${isMobileScreen ? "w-[100%]" : ""}
                        ${isMobileScreen ? 'dropdown-multiupload' : ''}
                      `}
                      wrapperStyle={`
                        text-[#8B0000] hover:text-[#8B0000] d-inline
                        ${isMobileScreen ? "w-[100%]" : ""}
                      `}
                      handleDropdown={() => handleDropdown(
                        null,
                        "import",
                        "trailers",
                        handleImportFn,
                        handleImportAll,
                        handleImportInspas,
                        t
                      )}
                      formData={formData}
                      disabled={deletionMode}
                      item={null}
                      icon="import"
                      type="import"
                    />
                    {activeList?.length > 0 ?
                      <BlueButton
                        text={'Export'}
                        onClickFunction={(e) => {
                          e.preventDefault()
                          generateXlsxFile(
                            activeList,
                            'Active Trailers',
                            'Active Trailers.xlsx'
                          )
                        }}
                        disabled={deletionMode}
                        hasContentToBottom={true}
                        hasContentToRight={!isMobileScreen}
                        className={isMobileScreen ? "w-[100%]" : ""}
                        icon={<DownloadIcon />}
                      /> : null
                    }
                    {(
                      formData?.documentType === "Application" &&
                      formData.status !== "In-Renewal"
                    ) &&
                    Boolean(deletionMode) ? (
                      <RedButton
                        text="Delete All Trailers"
                        onClickFunction={(e) => {
                          handleSubmit(e, "multiple", "massive");
                        }}
                        hasContentToBottom={true}
                        hasContentToRight={!isMobileScreen}
                        className={isMobileScreen ? "w-[100%]" : ""}
                      />
                    ) : null}
                    {!Boolean(deletionMode) && count !== 0 ? (
                      <RedButton
                        text={"Delete"}
                        onClickFunction={(e) => {
                          setDeletionMode(true);
                        }}
                        hasContentToBottom={true}
                        hasContentToRight={!isMobileScreen}
                        className={isMobileScreen ? "w-[100%]" : ""}
                      />
                    ) : null}
                    {userInfo?.role === "Agent" ||
                    userInfo?.role === "Admin" ||
                    userInfo?.role === "Underwriter" ? (
                      <BlueButton
                        text="SHARE"
                        hasContentToBottom={true}
                        onClickFunction={() => setOpenShareModal(true)}
                        icon={<IosShareIcon />}
                        className={isMobileScreen ? "w-[100%]" : ""}
                      />
                    ) : null}
                  </> : null
                }
                </div>
              )}
            </>
          )}
          
          {isTemporalUrl && !isTemporalUrlClient ?
                  <>
                  {/* TODO: Translate this line */}
                  <p className="mb-2">
                    Once you have made all necessary changes, please click
                    the 'Submit Changes' button below.
                  </p>
                    <BlueButton
                      text='Submit Changes'
                      onClickFunction={handleFinishOperation}
                      hasContentToBottom={true}
                      hasContentToRight={!isMobileScreen}
                      className={isMobileScreen ? "w-[100%]" : ""}
                      icon='no-icon'
                    />
                    </> : null
                  }
          {showAddTrailer && (
            <>
              <AddTrailer
                isEndoManager={isEndoManager}
                getEndorsements={props.getEndorsements}
                userInfo={userInfo}
                trailerList={trailerList}
                rerateRequired={rerateRequired}
                setRerateRequired={setRerateRequired}
                setShowAddTrailer={setShowAddTrailer}
                setShowTable={setShowTable}
                insuredInfo={props.insuredInfo}
                formData={formData}
                policyID={props.policyID}
              />
            </>
          )}
          {showTable && (
            <>
              {formData.documentType === "Policy" && (
                <>
                  {deletionMode
                    ? "Deleting a trailer from the trailers list will submit a Remove Trailer Endorsement."
                    : ""}
                  <span className="d-block mt-2 mb-3">
                    {deletionMode && formData.documentType === "Policy" && (
                      <>
                        "The trailer"
                        <b> "will not be removed from the policy"</b>{" "}
                        until the endorsement is approved by an underwriter.
                      </>
                    )}
                  </span>
                </>
              )}
              <TrailerList
                insuredInfo={props.insuredInfo}
                getTrailerList={getTrailerList}
                policyID={props.policyID}
                setTrailerList={props.setTrailerList}
                userInfo={userInfo}
                formData={formData}
                trailerList={trailerList}
                {...trailerHook}
              />
            </>
          )}
          {showImport && (
            <ImportTrailers
              userInfo={userInfo}
              rerateRequired={rerateRequired}
              setRerateRequired={setRerateRequired}
              getTrailerList={getTrailerList}
              trailerList={trailerList}
              insuredInfo={props.insuredInfo}
              setShowAddTrailer={setShowAddTrailer}
              formData={formData}
              userID={props.userID}
              policyID={props.policyID}
              setShowTable={setShowTable}
              setShowImport={setShowImport}
            />
          )}
          {showImportAll && (
            <ImportAllLists
              insuredInfo={insuredInfo}
              formData={formData}
              userID={props.userID}
              userInfo={userInfo}
              policyID={policyID}
              setShowTable={setShowTable}
              setShowImport={setShowImportAll}
            />
          )}
          {showImportInspas && (
            <ImportInspas
              insuredInfo={insuredInfo}
              formData={formData}
              userID={props.userID}
              userInfo={userInfo}
              policyID={policyID}
              setShowTable={setShowTable}
              setShowImport={setShowImportInspas}
            />
          )}
        </div>
      )}
    </>
  );
}
