import React, { useEffect, useMemo, useState } from "react";
import {
  addDoc,
  collection,
  doc,
  getDoc,
  runTransaction,
  setDoc,
  updateDoc,
} from "firebase/firestore";
import { db } from "../../../firebase";
import DynamicTable from "../../assets/lists/DynamicTable";
import ApprovalButtons from "../../assets/forms/ApprovalButtons";
import getTaxSchedule from "../../utils/getTaxSchedule";
import calculatePremiums from "../../utils/calculatePremiums";
import endoStatusChangeNotification from "../../utils/notifications/endoStatusChangeNotification";
import Form from "../../generals/Forms/Form";
import ReasonsModal from "../../PolicyLifecycleManager/ReturnToAgentModal";
import { setPolicyInfo } from "../../PolicyLifecycleManager/Drivers/setPolicyInfo";
import useUsersState from "../../../Context/actions/useUsersState";
import DriverDetails from "../../PolicyLifecycleManager/Drivers/DriverDetails";
import Popup from "../../generals/Popup";
import RequestExceptions from "../../generals/Toast/RequestExceptions";
import getCurrentPolicyTotals from "../../utils/getCurrentPolicyTotals";
import numberWithCommas from "../../utils/numberWithCommas";

export default function RemoveMultiple({
  endorsement,
  paramPolicyID,
  paramRefID,
  setSuccess,
  subcollection,
  tableDataRequest,
  columns,
  filterKeysToSearch,
  requestExceptionInputs
}) {

  const { user } = useUsersState();
  const [requestingExceptionMode, setRequestingExceptionMode] = useState(false);

  const determineDeletionStatus = () => {
    return endorsement?.type?.includes("Exclude") ? "Excluded" : "Deleted";
  };

  const deletionStatus = determineDeletionStatus();

  const [details, setDetails] = useState([]);
  const [data, setData] = useState([]);
  const [taxSchedule, setTaxSchedule] = useState();
  const [gotTaxSchedule, setGotTaxSchedule] = useState();

  const isRequestException = endorsement?.documentType === 'Exception Request'
  const wasARequestException = endorsement?.wasARequestException === true

  useEffect(() => {
    tableDataRequest()
      .then((res) => {
        const { tableData, matchedSubcollections } = res;
        setDetails(tableData);
        setData(matchedSubcollections);
      })
      .catch((err) => {
        console.error(err);
      });
  }, []);

  const [ correspondingRequestExceptionInputs ] = requestExceptionInputs.filter(info => {
    return info.id === endorsement?.type
  }).map(info => info.inputs)

  const [openInCancellationModal, setOpenInCancellationModal] = useState(false)

  const approveAUEndorsement = async (e, requestExceptionData) => {
    e.preventDefault();
    const { agencyID, insuredID } = endorsement;
    try {
      const endorsementRef = doc(
        db,
        "policy-applications",
        paramPolicyID,
        "endorsements",
        paramRefID
      );
      await runTransaction(db, async (transaction) => {
        await transaction.get(endorsementRef);
        const payload = {
          documentType: 'Endorsement',
          status: 'Approved',
          wasARequestException: false,
          issuedDate: new Date(),
        }
        if (requestExceptionData) {
          payload['wasARequestException'] = true
          payload['requestException'] = requestExceptionData
        }
        await transaction.update(endorsementRef, payload);
        endoStatusChangeNotification(
          endorsement.type,
          endorsement.endorsementNumber,
          "Approved",
          paramRefID,
          paramPolicyID,
          endorsement.agencyID
        );
        const promises = details.map(async (item) => {
          const subCollectionRef = doc(
            db,
            "agencies",
            agencyID,
            "insureds",
            insuredID,
            subcollection,
            item.id
          );
          const dataToUpdate = {
            expirationDate: endorsement.endoEffectiveDate,
            status: deletionStatus,
            ...(subcollection === "power-units" && {
              adjustedRate: 0,
              fleetCredit: 0,
              rating: 0,
            }),
          };
          if (
            typeof endorsement?.deletionReason !== "undefined" &&
            typeof endorsement?.deletionReason === "string"
          ) {
            dataToUpdate["deletionReason"] = endorsement.deletionReason;
          }
          if (
            typeof endorsement?.proofOfPowerDeletion !== "undefined" &&
            Array.isArray(endorsement?.proofOfPowerDeletion)
          ) {
            dataToUpdate["proofOfPowerDeletion"] =
              endorsement.proofOfPowerDeletion;
          }

          const payloadResult = setPolicyInfo(
            dataToUpdate,
            {
              policyEffectiveDate:
                dataToUpdate.effectiveDate ||
                dataToUpdate.expirationDate,
              glPolicyNo: endorsement?.glPolicyNo,
              alPolicyNo: endorsement?.alPolicyNo,
              policyID: paramPolicyID,
              status: deletionStatus
            },
            'endo-deletion'
          );

          if (typeof endorsement?.blacklistExpirationDate !== "undefined") {
            payloadResult["blacklistExpirationDate"] = endorsement.blacklistExpirationDate;
          }

          await setDoc(
            subCollectionRef,
            payloadResult,
            { merge: true }
          );

          const detailItem = details.find((detail) => detail.id === item.id)
          if (detailItem?.otherSideOfMirrorId) {
            const otherSideOfMirrorRef = doc(
              db,
              "agencies",
              agencyID,
              "insureds",
              insuredID,
              subcollection,
              detailItem?.otherSideOfMirrorId
            );
            await setDoc(otherSideOfMirrorRef, payloadResult, { merge: true });
          }

          const dataItem = data.find((info) => info.id === item.id)
          if (deletionStatus === 'Excluded') {
            await addDoc(collection(db, "drivers-blacklist"), {
              ...dataItem,
              blacklistedDate: new Date(),
            });
          }
        });

        const policyRef = doc(db, "policy-applications", paramPolicyID);
        const policyDoc = await getDoc(policyRef);
        const policyData = policyDoc.data();
        const policyStatus = policyData.status
        if (policyStatus === "In-Cancellation") {
          setOpenInCancellationModal(true)
          return
        }

        await Promise.all(promises);

        const policySnapshot = await getDoc(
          doc(db, "policy-applications", paramPolicyID)
        );
        await calculatePremiums(
          policySnapshot.id,
          policySnapshot.data(),
          e,
          "#",
          policySnapshot.data().alPremiums.adjustment,
          true,
          true,
          paramRefID
        );
      });

      setSuccess(true);
    } catch (error) {
      console.error("Error approving endorsement:", error);
      throw new Error("Failed to approve endorsement");
    }
  };

  const [rejectionModal, setRejectionModal] = useState(false)

  const declineAUEndorsement = async (e, _, reasons) => {
    if (e) {
      e.preventDefault();
    }

    console.log('reasons', reasons)

    const endoRef = doc(
      db,
      "policy-applications",
      paramPolicyID,
      "endorsements",
      paramRefID
    );
    const payload = {
      documentType: 'Endorsement',
      status: 'Declined',
      wasARequestException: false
    }
    if (isRequestException) {
      payload['wasARequestException'] = true
    }
    if (reasons) {
      payload["rejectionReasons"] = reasons
    }

    const { agencyID, insuredID, data } = endorsement;
    const subCollectionRef = doc(
      db,
      "agencies",
      agencyID,
      "insureds",
      insuredID,
      subcollection,
      data.id
    )
    const payloadResult = setPolicyInfo(
      data,
      {
        policyEffectiveDate: data.effectiveDate,
        glPolicyNo: endorsement?.glPolicyNo,
        alPolicyNo: endorsement?.alPolicyNo,
        policyID: paramPolicyID,
        status: 'Active'
      },
      'endo-deletion'
    );
    await setDoc(
      subCollectionRef,
      payloadResult,
      { merge: true }
    );

    console.log('payload', payload)

    await updateDoc(endoRef, payload);
    endoStatusChangeNotification(
      endorsement.type,
      endorsement.endorsementNumber,
      "Declined",
      paramRefID,
      paramPolicyID,
      endorsement.agencyID
    );
    setSuccess(true);
  };

  const requestExceptionModeSetting = async (e, data) => {
    e.preventDefault();
    const endoRef = doc(
      db,
      "policy-applications",
      paramPolicyID,
      "endorsements",
      paramRefID
    );
    await updateDoc(endoRef, {
      documentType: "Exception Request",
      requestException: data
    });
    endoStatusChangeNotification(
      endorsement.type,
      endorsement.endorsementNumber,
      "Declined",
      paramRefID,
      paramPolicyID,
      endorsement.agencyID
    );
    setRequestingExceptionMode(false)
  }

  const formSettingsRequestException = useMemo(() => {
    const settings = {
      title: "Reason for exception",
      onSubmit: requestExceptionModeSetting,
      onDecline: () => setRequestingExceptionMode(false),
      inputs: correspondingRequestExceptionInputs || [],
      buttonLabel: "Submit",
      buttonLabelDecline: "Cancel",
      otherInput: { label: 'Other exception request' }
    };
    return settings
  }, [correspondingRequestExceptionInputs])

  const [openDetailsModal, setOpenDetailsModal] = useState(false)
  const [selectedDriver, setSelectedDriver] = useState('')

  const handleOpenDetailsModal = (id) => {
    setSelectedDriver(id)
    setOpenDetailsModal(!openDetailsModal)
  }

  const [oldGWP, setOldGWP] = useState();
  const [newGWP, setNewGWP] = useState();

  const policyID = paramPolicyID;
  const endoID = paramRefID;
  const { agencyID, insuredID } = endorsement;
  
  useEffect(() => {
    // Ensure that details are loaded before running the calculation
    if (!details || details.length === 0) {
      console.log("Details not loaded yet, waiting...");
      return;
    }
  
    const calculateGWP = async () => {
      try {
        // Fetch the current GWP
        const currentGWP = await getCurrentPolicyTotals(
          agencyID,
          insuredID,
          policyID,
          endoID
        );
  
        setOldGWP("$" + numberWithCommas(currentGWP.toFixed(2)));
  
        const endorsementsTotalGWP = details.reduce((acc, curr) => {
          console.log(curr.policiesDetails[0]?.adjustedRate);
          return acc + (curr.policiesDetails[0]?.adjustedRate || 0);
        }, 0);
  
        console.log("current gwp: ", currentGWP);
        console.log("new addition: ", endorsementsTotalGWP);
  
        const newTotal = currentGWP - endorsementsTotalGWP;
        console.log("new total: ", newTotal);
  
        setNewGWP("$" + numberWithCommas(newTotal.toFixed(2)));
      } catch (error) {
        console.error("Error calculating GWP:", error);
      }
    };
  
    calculateGWP();
  }, [agencyID, insuredID, policyID, endoID, details]);
  
  
  

  return (
    <div>
      <Popup
        isOpen={openInCancellationModal}
        onRequestClose={() => setOpenInCancellationModal(false)}
        maxWidth='50%'
      >
        <p className="text-2xl font-semibold mb-2 text-center text-red-700">
          This endorsement cannot be approved
          because the policy is In Cancellation
        </p>
      </Popup>
      <Popup
        isOpen={openDetailsModal}
        onRequestClose={() => setOpenDetailsModal(false)}
        showExpandButton={true}
        maxWidth='inherit'
      >
        <div>
          <DriverDetails
            formData={endorsement}
            userInfo={user}
            selectedDriver={selectedDriver}
            setShowDriverDetails={setOpenDetailsModal}
            policyID={paramPolicyID}
          />
        </div>
      </Popup>

      <ReasonsModal
        title='Rejection Reasons'
        text='Please enter the reason(s) why the endorsement is being rejected.'
        addButtonText='Add A Reason'
        onSubmit={(e, reasons) => declineAUEndorsement(e, null, reasons)}
        open={rejectionModal}
        onClose={() => setRejectionModal(false)}
      />

      <DynamicTable
        columns={columns}
        tableData={details}
        deleteThisItem={() => {}}
        onClickItem={(e, id) => {
          e.preventDefault()
          if (subcollection === 'drivers') {
            handleOpenDetailsModal(id)
          }
        }}
        filterKeys={filterKeysToSearch}
      />
      
      {requestingExceptionMode &&
      typeof correspondingRequestExceptionInputs !== 'undefined' ?
      <Form
        formSettings={formSettingsRequestException}
        isSmallVersion={true}
      /> : null}
      {(
        endorsement.status === "Submitted" ||
        isRequestException
      ) &&
      user.role === "Underwriter" ? (
        <>
          <article className="mt-3">
            <p className={isRequestException ? 'text-red-700 text-center !max-w-[345px] mx-auto' : ''}>
              {isRequestException ?
                `This endorsement has already been declined. 
                You are accepting/decling an exception request` :
                `Approving this endorsement will remove
                the listed ${subcollection} from the policy
                and notify the insured of the change.`
              }
            </p>
          </article>
          {isRequestException ?
            <RequestExceptions endorsement={endorsement} color='red' center={true} /> : null
          }
          <ApprovalButtons
            onApprove={approveAUEndorsement}
            onDecline={() => setRejectionModal(true)}
          />
        </>
      ) :
      endorsement.status === "Declined" &&
      !isRequestException &&
      !wasARequestException &&
      user.role === "Agent" &&
      !requestingExceptionMode ? (
        <>
          <ApprovalButtons
            dissapearAccept={true}
            titleDecline="REQUEST EXCEPTION"
            onDecline={() => setRequestingExceptionMode(true)}
          />
        </>
      ) : null}
    </div>
  );
}
